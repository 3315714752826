import create from "zustand";

const initialState = {
    products: []
};

const useSelectedProductStore = create((set, get) => ({
    ...initialState,
    addProduct: (sceneCategory, productGroupCategory, pathname) => {

        //region USP팝업창 닫지 않은 상태에서 제품리스트 팝업을 닫았다가 핫스팟 눌러서 재실행했을 때 USP팝업이 바로 뜨는 버그 수정
        const comp = pathname.split('/');
        let newPathName = pathname;
        if(comp.length > 5){
            newPathName = `${comp[0]}/${comp[1]}/${comp[2]}/${comp[3]}/${comp[4]}`;
        }
        //endregion

        set(s => ({
            products: s.products.filter(value =>
                !(value.sceneCategory === sceneCategory &&
                    value.productGroupCategory === productGroupCategory)
            ).concat({
                sceneCategory: sceneCategory,
                productGroupCategory: productGroupCategory,
                pathname: newPathName
            })
        }));
    },
    getProduct: (sceneCategory, productGroupCategory) => {
        const result = get().products.filter(value => value.sceneCategory === sceneCategory && value.productGroupCategory === productGroupCategory);
        return result.length > 0 ? result[0] : null;
    },
    clearProducts: () => {
        // console.log("CLEAR!!!");
        set({products: []});
    }
}));

export default useSelectedProductStore;
