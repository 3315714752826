import React, {useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import Marzipano from "marzipano";
import SCENES from "../../api/scenes";
import usePanoStore from "../../stores/panoStore";
import ReactDOM from "react-dom";
import ImageHotspot from "../v1/hotspots/ImageHotspot";
import ProductHaloHotspot from "../v1/hotspots/ProductHaloHotspot";
import {useGesture} from "react-use-gesture";
import useHistoryStore from "../../stores/historyStore";
import useProductsStore from "../../stores/productsStore";
import useSelectedProductStore from "../../stores/selectedProductStore";
import * as PRODUCTS_API from "../../api/products";
import useMenuStore from "../../stores/menuStore";
import {useSpring} from "react-spring";
import {easeCubicInOut} from "d3-ease";
import useUspStore from "../../stores/uspStore";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const MarzipanoView = () => {

    const panoRef = useRef(null);
    const viewer = useRef(null);
    const fovRef = useRef();
    const officeLayerRef = useRef(null);
    const showFOV = useRef(false);

    const isDragging = usePanoStore(state => state.isDragging);
    const pathname = useHistoryStore(state => state.pathname);
    const sceneCategory = useHistoryStore(state => state.sceneCategory);
    const productGroupCategory = useHistoryStore(state => state.productGroupCategory);
    const productCategory = useHistoryStore(state => state.productCategory);

    const isOfficeMonitor = useMemo(() => sceneCategory === 'office' && productGroupCategory === 'monitor' && productCategory, [sceneCategory, productGroupCategory, productCategory]);

    const spring = useSpring({
        from: {opacity: 0},
        opacity: isOfficeMonitor ? 0 : 1,
        onChange: result => {
            if (officeLayerRef.current) {
                officeLayerRef.current.setEffects({opacity: result.value.opacity});
            }
        },
        config: {easing: easeCubicInOut, duration: 1000}
    });

    const mouseDownHandler = e => {
        const screenXY = {x: e.clientX, y: e.clientY};
        if (viewer.current) {
            const view = viewer.current.view();
            if (view) {
                const coordinates = view.screenToCoordinates(screenXY);
                const coordinatesAngle = {
                    yaw: coordinates.yaw * 180 / Math.PI,
                    pitch: coordinates.pitch * 180 / Math.PI
                };

                console.log(coordinates, coordinatesAngle);
            }
        }
    };

    const keyDownHandler = e => {
        if (e.key.toLowerCase() === 'q') {
            console.log(fovRef.current.innerHTML.toString());
        }
        //if (e.code === 'Space') {
        if (e.key.toLowerCase() === 'z') {
            showFOV.current = !showFOV.current
        }
    }

    const bind = useGesture({
        onDragEnd: () => {
            usePanoStore.setState({isDragging: false});
        },
        onDrag: (e) => {
            const moveDistance = Math.sqrt(Math.pow(e.movement[0], 2) + Math.pow(e.movement[1], 2));
            if (!isDragging && moveDistance > 10) {
                usePanoStore.setState({isDragging: true});
            }
        },
        onDragStart: () => {
            useMenuStore.setState({hoveredMenuCategory: undefined});
        }
    });

    useEffect(() => {
        const viewerOps = {
            controls: {
                mouseViewMode: 'drag'
            },
            swfPath: 'vender/marzipano.swf'
        };

        viewer.current = new Marzipano.Viewer(panoRef.current, viewerOps);

        const scenes = SCENES.map(sceneData => {
            const urlPrefix = 'tiles';
            const source = Marzipano.ImageUrlSource.fromString(
                `${urlPrefix}/${sceneData.id}/{z}/{f}/{y}/{x}.jpg`,
                {
                    cubeMapPreviewUrl: `${urlPrefix}/${sceneData.id}/preview.jpg`
                });
            const geometry = new Marzipano.CubeGeometry(sceneData.levels);
            const limiter = Marzipano.RectilinearView.limit.traditional(sceneData.faceSize, 120 * Math.PI / 180);
            const view = new Marzipano.RectilinearView(sceneData.initialViewParameters, limiter);
            view.addEventListener('change', () => {
                const yawRad = view.yaw();
                usePanoStore.setState({yaw: yawRad});

                if (showFOV.current) {
                    const fovRad = view.fov();
                    const fovDeg = fovRad * 180 / Math.PI;
                    // const yawRad = view.yaw();
                    const yawDeg = yawRad * 180 / Math.PI;
                    const pitchRad = view.pitch();
                    const pitchDeg = pitchRad * 180 / Math.PI;

                    // console.log(fovDeg);
                    if (fovRef.current) {
                        fovRef.current.innerHTML = `FOV: ${fovDeg}deg (${fovRad}rad)<br>
                                                Yaw: ${yawDeg} (${yawRad}rad)<br>
                                                Pitch: ${pitchDeg} (${pitchRad}rad)`;
                    }
                } else {
                    if (fovRef.current) {
                        fovRef.current.innerHTML = '';
                    }
                }
            });

            const scene = viewer.current.createScene({
                source: source,
                geometry: geometry,
                view: view,
                pinFirstLevel: true
            });


            if (sceneData.layerId) {
                const layerSource = Marzipano.ImageUrlSource.fromString(
                    `${urlPrefix}/${sceneData.layerId}/{z}/{f}/{y}/{x}.jpg`,
                    {
                        cubeMapPreviewUrl: `${urlPrefix}/${sceneData.layerId}/preview.jpg`
                    });

                const layer = scene.createLayer({
                    source: layerSource,
                    geometry: geometry
                });
                layer.setEffects({opacity: 0});
                officeLayerRef.current = layer;
            }

            const isAppleMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);

            //인포 핫스팟
            sceneData.infoHotspots.forEach(hotspot => {
                const wrapper = document.createElement('div');

                ReactDOM.render(<ImageHotspot hotspot={hotspot} sceneCategory={sceneData.category}
                                              sceneId={sceneData.id}/>, wrapper);
                scene.hotspotContainer().createHotspot(wrapper, {
                    yaw: hotspot.yaw,
                    pitch: hotspot.pitch
                }, {
                    perspective: {
                        radius: isAppleMobile && hotspot.radiusApple ? hotspot.radiusApple : hotspot.radius,
                        extraTransforms: hotspot.extraTransforms
                    }
                });
            });

            //링크 핫스팟
            sceneData.linkHotspots.forEach(hotspot => {
                const wrapper = document.createElement('div');
                wrapper.style.zIndex = 9999;

                ReactDOM.render(<ProductHaloHotspot hotspot={hotspot} sceneCategory={sceneData.category}
                                                    sceneId={sceneData.id}/>, wrapper);
                scene.hotspotContainer().createHotspot(wrapper, {
                    yaw: hotspot.yaw,
                    pitch: hotspot.pitch,
                });
            });

            return {scene: scene, data: sceneData};
        });

        usePanoStore.setState({viewer: viewer.current, scenes: scenes});
        usePanoStore.getState().changeScene(scenes[0].data.id, scenes[0].data.category);

        if (panoRef.current) panoRef.current.addEventListener('mousedown', mouseDownHandler);
        window.addEventListener('keypress', keyDownHandler);
        const panoElement = panoRef.current;

        return () => {
            if (panoElement) panoElement.removeEventListener('mousedown', mouseDownHandler);
            window.removeEventListener('keypress', keyDownHandler);
        }

    }, []);

    useLayoutEffect(() => {

        if (!pathname) return;
        const {
            sceneCategory,
            productGroupCategory,
            prevProductGroupCategory,
            productCategory,
            prevProductCategory,
            modelName,
            sceneId,
            prevSceneId
        } = useHistoryStore.getState();

        const sceneData = SCENES.filter(value => value.id === sceneId);

        const hotspotData = sceneData[0].linkHotspots.filter(value => value.category === productGroupCategory);
        let targetCameraView;
        if (hotspotData.length > 0) {
            targetCameraView = hotspotData[0].targetCameraView;
            if (isMobile && hotspotData[0].targetCameraView2 && window.innerWidth < window.innerHeight) {
                targetCameraView = hotspotData[0].targetCameraView2;
            }
        } else {
            targetCameraView = sceneData[0].initialViewParameters;
        }

        if (sceneId !== prevSceneId) {
            // console.log("데이터 삭제3");
            useProductsStore.getState().closeData();
            useSelectedProductStore.getState().clearProducts();
        }

        if (modelName) {
            useSelectedProductStore.getState().addProduct(sceneCategory, productGroupCategory, pathname);
        } else {
            useUspStore.getState().hide();
        }

        //제품 카테고리가 존재하는 경우 targetCameraView로 카메라 회전기능 사용
        const useLook = !!productCategory;
        usePanoStore.getState().changeScene(sceneId, sceneCategory, targetCameraView, useLook).then(() => {

            //제품 그룹 카테고리가 바뀌었거나, 선택된 제품 데이터가 없을 경우 새로 로드한다.
            if ((prevProductGroupCategory !== productGroupCategory) || (useProductsStore.getState().products === null)) {

                //console.log("-->", (prevProductGroupCategory !== productGroupCategory), (useProductsStore.getState().products === null), "---", productGroupCategory, productCategory, modelName);

                if (productGroupCategory && productCategory && modelName) {
                    // console.log("데이터 로드");
                    useProductsStore.getState().loadProductsData(productGroupCategory, productCategory, modelName);
                } else {
                    // console.log("데이터 삭제2");
                    useProductsStore.getState().closeData();
                }
            } else {
                //제품 카테고리가 바뀌었을 경우
                if (productCategory !== prevProductCategory) {
                    //제품 카테고리가 존재하면, 카테고리 변경
                    if (productCategory) {
                        PRODUCTS_API.getCategories(productGroupCategory).then(categories => {
                            const category = categories.filter(value => value.name === productCategory);
                            // console.log("카테고리 변경", productCategory, category);
                            if (category.length > 0) {
                                useProductsStore.getState().selectCategory(category[0], modelName);
                            }
                        });
                    }
                    //제품 카테고리가 존재하지 않으면, 데이터 삭제
                    else {
                        // console.log("데이터 삭제");
                        useProductsStore.getState().closeData();
                    }
                }
                //제품 카테고리 변경이 없을 경우, 제품 선택으로 간주
                else {
                    // console.log("제품 선택");
                    useProductsStore.getState().searchAndSelectProduct(modelName);
                }
            }
        });
    }, [pathname]);

    return (
        <>
            <div id="pano" ref={panoRef} {...bind()}/>
            <div id="fov" ref={fovRef} style={{
                color: 'white',
                // background: '#ff33aa',
                zIndex: 999,
                position: 'absolute',
                left: '1rem',
                bottom: '10px',
                fontSize: '1.3rem'
            }}/>
        </>
    );
};

export default MarzipanoView;
