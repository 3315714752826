import create from "zustand";
import * as PRODUCTS_API from "../api/products";


const initialState = {
    categories: null,           //제품 카테고리 목록 [{id, type, name}]
    selectedCategory: null,     //선택된 카테고리 {id, type, name}
    products: null,             //제품 목록 [{modelName, category, title, thumbUrl, features, sizes, link, new, uspVideoUrl}]
    selectedProduct: null,      //선택된 제품 {modelName, category, title, thumbUrl, features, sizes, link, new, uspVideoUrl}
    error: null,
    currentDataType: null,      //현재 제품군 (tvs, av, ...)
    loading: false,
    isClosing: false,
    isTweening: false,
    isLoadingTvProductImg: false,
    productImages: undefined
}

let delayVal;

const useProductsStore = create((set, get) => ({
    ...initialState,

    setIsLoadingTvProductImg: (val) => set({isLoadingTvProductImg: val}),

    /**
     * 제품 데이터 로드 (핫스팟 버튼 눌렀을 때 호출)
     * @param productGroupCategory 제품 그룹 카테고리 (ex. tvs, av …)
     * @param productCategoryName
     * @param modelName
     */
    loadProductsData: (productGroupCategory, productCategoryName, modelName) => {
        //console.log("loadProductsData", productGroupCategory, productCategoryName, modelName);

        set({
            ...initialState,
            loading: true,
            error: null,
            currentDataType: productGroupCategory,
            productImages: undefined
        });

        PRODUCTS_API.getDataModule(productGroupCategory).then(productDataModule => {
            const {categories, productImages} = productDataModule;

            set({loading: false, error: null, categories, productImages});

            if (categories.length > 0) {
                const result = categories.filter(value => value.name === productCategoryName);
                get().selectCategory(result.length > 0 ? result[0] : categories[0], modelName);
            }
        }).catch(err => {
            set({loading: false, error: err});
            console.log(err);
        });

        // PRODUCTS_API.getCategories(productGroupCategory).then(categories => {
        //     set({loading: false, error: null, categories: categories});
        //     if (categories.length > 0) {
        //         const result = categories.filter(value => value.name === productCategoryName);
        //         get().selectCategory(result.length > 0 ? result[0] : categories[0], modelName);
        //     }
        // }).catch(err => {
        //     set({loading: false, error: err});
        //     console.log(err);
        // });
    },

    /**
     * 제품 데이터 닫기
     */
    closeData: () => {
        if (get().selectedProduct && !get().isClosing) {
            // set({...initialState});
            set({isClosing: true, isTweening: true});
            // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>close");

            if (delayVal) clearTimeout(delayVal);
            delayVal = setTimeout(() => {
                set({...initialState});
            }, 500);
        }
    },

    /**
     * 카테고리 선택
     * @param productCategory '{id, type, name}'
     * @param modelName
     */
    selectCategory: (productCategory, modelName) => {
        set({selectedCategory: productCategory, error: null});

        PRODUCTS_API.getProducts(get().currentDataType, productCategory).then(products => {
            set({products: products});

            PRODUCTS_API.getProduct(get().currentDataType, modelName).then(product => {
                get().selectProduct(product);
            }).catch(err => {
                set({error: err});
            });
        }).catch(err => {
            set({products: null, selectedProduct: null, error: err})
        });
    },

    /**
     * 제품 선택
     * @param product '{modelName, category, title, thumbUrl, features, sizes, link, new, uspVideoUrl}'
     */
    selectProduct: (product) => {
        if (delayVal) clearTimeout(delayVal);
        set({selectedProduct: product});
    },

    /**
     * 모델명으로 검색해서 제품 선택
     * @param modelName
     */
    searchAndSelectProduct: (modelName) => {
        PRODUCTS_API.getProduct(get().currentDataType, modelName).then(product => {
            get().selectProduct(product);
        }).catch(err => {
            set({error: err});
        });
    },

    /**
     * 특정 제품 카테고리의 첫번째 제품 모델명 리턴
     * (제품 카테고리 탭 버튼 눌렀을 때, 첫번째 아이템의 데이터를 자동 선택하는 용도로 사용)
     * @param productCategory '{id, type, name}'
     */
    getFirstModelName: async (productCategory) => {
        const products = await PRODUCTS_API.getProducts(get().currentDataType, productCategory);
        if(products && products.length > 0){
            return products[0].modelName;
        }
    },
}));

export default useProductsStore;
