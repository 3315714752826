import {DEFAULT_PATH} from "../../menu";

const OFFICE_MAIN_DATA = {
    "id": "office_main",
    "category":"office",
    "name": "office_main",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0.22247104875058277,
        "yaw": 1.5942791398756109,
        "fov": 1.403491299976566
    },
    "linkHotspots": [
        {
            "yaw": 1.4812755277081457,
            "pitch": 0.20366422366874382,
            "rotation": 0,
            "target": "office_monitor",
            "category": "laptop",
            "pathname": "/office/laptop/laptop/16Z90P-K.AA72A1",
            "targetCameraView": {
                "yaw": -0.3773571158212441,
                "pitch": 0.21475084933014976,
                "fov": 1.1
            }
        },
        {
            "yaw": 1.688972389646623,
            "pitch": 0.1193503895980399,
            "rotation": 0,
            "target": "office_monitor",
            "category": "monitor",
            "pathname": "/office/monitor/ultrawide/38WN95C-W",
            "targetCameraView": {
                "yaw": 0.3559575860957267,
                "pitch": 0.01352287731259949,
                "fov": 1.1
            }
        },
        {
            "yaw": -0.5319863695110136,
            "pitch": 0.1272272915238304,
            "rotation": 0,
            "target": "office_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.office.oled,
            "targetCameraView": {
                "yaw": 0.27684357558200645,
                "pitch": 0.06769971699925392,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0.27684357558200645,
                "pitch": 0.06769971699925392,
                "fov": 1.1
            }
        },
        {
            "yaw": -1.766537679009586,
            "pitch": 0.08789551167249599,
            "rotation": 0,
            "target": "office_tv",
            "category": "wine_celler",
            "pathname": "/office/wine_celler/wine_celler/LSR200W",
            "targetCameraView": {
                "yaw": -0.8866332945080764,
                "pitch": 0.056022109152904065,
                "fov": 1.1
            }
        },
        {
            "yaw": 1.8301054226508828,
            "pitch": 0.2670539647018746,
            "rotation": 0,
            "target": "office_monitor",
            "category": "portable_speakers",
            "pathname": "/office/portable_speakers/portable_speaker/PN5",
            "targetCameraView": {
                "yaw": 0.7156167317678879,
                "pitch": 0.19475854052866381,
                "fov": 1
            }
        },
        {
            "yaw": -1.0683372173506598,
            "pitch": -0.2543017818093709,
            "rotation": 0,
            "target": "office_air",
            "category": "air",
            "pathname": "/office/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": -0.1138640612947625,
                "pitch": 0.028953986207012505,
                "fov": 1.1
            },
        }
    ],
    "infoHotspots": [],
    // "defaultTV":{
    //     "sceneId": 'office_main',
    //     "yaw": -3.0357128767509227,
    //     "pitch": 0.31715455979956886,
    //     "width": 636,
    //     "height": 361,
    //     "radius": 1000,
    //     "extraTransforms": "rotateX(-18.17161772984478deg) rotateY(-353.93353565134572deg)",
    //     "url":"videos/office_tv.mp4"
    // }
};
export default OFFICE_MAIN_DATA;
