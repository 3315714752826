import React, {useEffect} from 'react';
import styled from "styled-components";
import Navigation from "./Navigation";
import Navigation_M from "./mobile/Navigation_M";
import useHistoryStore from "../../../stores/historyStore";
import useMenuStore from "../../../stores/menuStore";
import {animated, useSpring} from "react-spring";


const MenuUI = () => {

    const sceneCategory = useHistoryStore(state => state.sceneCategory);
    const isMobile = useMenuStore(state => state.isMobile);
    const isOpenMenu = useMenuStore(state => state.isOpenMenu);

    const spring = useSpring({
       bgColor: isOpenMenu ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0)'
    });

    useEffect(()=>{
        useMenuStore.getState().setSceneCategory(sceneCategory);
    }, [sceneCategory]);


    return (
        <Wrapper style={{
            pointerEvents: isMobile && isOpenMenu ? 'visible' : 'none',
            backgroundColor: spring.bgColor
        }} onClick={()=>{
            useMenuStore.setState({isOpenMenu: false});
        }}>
            {
                isMobile ?
                    <Navigation_M/> :
                    <Navigation/>
            }
        </Wrapper>
    );
};

const Wrapper = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999999;
  color: black;
  pointer-events: none;
  background: rgba(0, 0, 0, 0);
`;

export default MenuUI;
