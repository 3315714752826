import React, {useRef, useEffect, useState} from 'react';
import styled from "styled-components";
import ProductButton from "./ProductButton";
import {useSpring, animated} from "react-spring";
import {easeCubicOut} from "d3-ease";
import {ROOT_PATH_PREFIX} from "../../const";


const ProductListView = React.memo(({products, selectProduct, selectedProduct}) => {

    const listEl = useRef(null);
    const bannerEl = useRef(null);

    const [y, setY] = useSpring(() => ({
        immediate: false,
        y: 0,
        config: {duration: 300, easing: easeCubicOut},
        onChange: (result) => {
            if (listEl.current) listEl.current.scroll(0, result.value.y);
        },
    }));

    const [bannerLoaded, setBannerLoaded] = useState(false);
    const spring = useSpring({
        opacity: bannerLoaded ? 1 : 0,
        config: {duration:500},
        delay: 1000
    });

    useEffect(() => {
        if (!selectedProduct) return;

        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        let itemH = screenW < 1280 ? 80 : 100;
        if (screenW < 747 || screenH < 414) itemH = 72;
        let containerH = screenW < 1280 ? 413 : 516;
        if (screenW < 747 || screenH < 414) containerH = 200;

        let targetIndex = 0;
        for (let i = 0; i < products.length; i++) {
            if (products[i].modelName === selectedProduct.modelName) {
                targetIndex = i;
                break;
            }
        }
        const bannerHeight = bannerEl.current?.offsetHeight;
        const targetScrollTop = targetIndex * itemH + bannerHeight;
        const diff = targetScrollTop - listEl.current.scrollTop;

        if(diff < 0){
            setY({from: {y: listEl.current.scrollTop}, y: itemH * targetIndex + bannerHeight});
        }
        else if(diff > containerH - itemH)
        {
            setY({from: {y: listEl.current.scrollTop}, y: -(containerH - itemH) + itemH * targetIndex + bannerHeight});
        }

    }, [selectedProduct]);

    return (
        <StyledProductListView
            ref={listEl}
            productslength={products !== null ? products.length : 0}
        >
            <div id='banner' ref={bannerEl}>
                <img src={`${ROOT_PATH_PREFIX}/img/loading.gif`} />
                <animated.iframe src={'https://prod.d36f2w7sajb4z7.amplifyapp.com/'} onLoad={()=>{
                    setBannerLoaded(true);
                }} style={{
                    opacity : spring.opacity
                }} />
            </div>
            {products && products.map((product, index) => (
                <ProductButton
                    product={product}
                    selectProduct={selectProduct}
                    selectedProduct={selectedProduct}
                    key={index}/>
            ))}
        </StyledProductListView>
    );
});

const StyledProductListView = styled.div`
  width: 20rem;
  height: 100%;
  padding-right: 0.8rem;
  overflow-x: hidden;
  overflow-y: ${props => props.productslength > 5 ? 'scroll' : 'hidden'};
  overflow-scrolling: auto;
  pointer-events: auto;
  
  #banner{
    width: 100%;
    height: 9.3rem;
    background: black;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    iframe{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: none;
      z-index: 11;
    }
    
    img{
      position: absolute;
      z-index: 10;
      height: 20%;
    }
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {
    width: 14rem;
    overflow-y: ${props => props.productslength > 2 ? 'scroll' : 'hidden'};

    #banner{
      height: 6.6rem;

      iframe{
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
`;

export default ProductListView;
