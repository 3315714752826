import React from 'react';

const IconOfficeSVG = ({color, showBorder = false, ...rest}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.99 107.98" {...rest}>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path className="cls-1" fill={color}
                          d="M59.31,63.58H84.12V55.05a1.8,1.8,0,0,0-2-2H66.39V49.5H78.54a1.78,1.78,0,0,0,1.93-1.94V26.74a1.77,1.77,0,0,0-1.93-1.94c-1.79,0-3.57,0-5.35,0H50.78a1.82,1.82,0,0,0-2.07,2.09,16,16,0,0,1,0,1.7c0,.53.17.73.63.93,5.78,2.5,9.18,6.85,9.85,13.15.22,2,.09,4.11.12,6.17,0,.2,0,.41,0,.68h3.5V53h-7v-.68c0-2.7,0-5.41,0-8.11a12.58,12.58,0,0,0-.15-1.94A12.35,12.35,0,0,0,31.06,44c0,2.76,0,5.53,0,8.29V53H26c-1.5,0-2.15.64-2.15,2.14V82.86a4.64,4.64,0,0,0,0,.53,1.76,1.76,0,0,0,2.5,1.46,1.83,1.83,0,0,0,1-1.83q0-12.88,0-25.76v-.68h3.65v1.54a1.78,1.78,0,0,0,2,2c2.65,0,5.3,0,7.94,0h.64v3.53c-1.82,0-3.61,0-5.39,0a5.11,5.11,0,0,0-5,4.28,30.83,30.83,0,0,0-.11,4.81,1.68,1.68,0,0,0,1.76,1.49H54a1.75,1.75,0,0,0,1.79-1.78c0-1.24,0-2.47,0-3.71a5.13,5.13,0,0,0-4.29-5,45.23,45.23,0,0,0-4.81-.13H45.22V60.08h8.57a1.78,1.78,0,0,0,2-1.95c0-.52,0-1,0-1.54h3.55Z"/>
                    <path className="cls-1" fill={color}
                          d="M62.82,78H80.57c0,.17,0,.28,0,.4,0,1.61,0,3.21,0,4.82a1.7,1.7,0,0,0,1.08,1.67,1.76,1.76,0,0,0,2.44-1.74q0-7.77,0-15.53a3.27,3.27,0,0,0,0-.44H59.36a.39.39,0,0,0,0,.14q0,8,0,16A1.75,1.75,0,0,0,61,85a1.77,1.77,0,0,0,1.78-1.68c0-.56,0-1.13,0-1.7Z"/>
                    <path className="cls-1" fill={color}
                          d="M46.89,81.5H45.15V78H41.64v3.49h-1.7a1.77,1.77,0,1,0,0,3.53q3.5,0,7,0a1.76,1.76,0,1,0,0-3.52Z"/>


                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M30.18,103.3c-5.77,0-11.54,0-17.32,0-5,0-8.16-3.16-8.17-8.16,0-5.77,0-11.55,0-17.32,0-1.62-.91-2.66-2.29-2.7A2.35,2.35,0,0,0,0,77.71C0,83.87,0,90,.06,96.18a12,12,0,0,0,11.72,11.75c3,.07,6,0,9,0v0c3.17,0,6.33,0,9.5,0a2.33,2.33,0,0,0,2.38-1.37C33.41,104.91,32.21,103.3,30.18,103.3Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M1.26,32.58c1.67.89,3.42-.31,3.42-2.38,0-5.78,0-11.55,0-17.32,0-5,3.15-8.19,8.14-8.19H30.28a2.39,2.39,0,0,0,2.6-2.4A2.35,2.35,0,0,0,30.29,0c-6.2,0-12.4-.08-18.6,0A12,12,0,0,0,.06,11.63c-.08,3,0,6.07,0,9.11H0c0,3.16,0,6.33,0,9.49A2.41,2.41,0,0,0,1.26,32.58Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M77.88,4.68H95.06c5.1,0,8.23,3.13,8.24,8.23,0,5.77,0,11.54,0,17.31a2.4,2.4,0,0,0,2.36,2.64,2.35,2.35,0,0,0,2.3-2.55c0-6.2.07-12.4,0-18.6A12,12,0,0,0,96.26.05c-3-.08-6,0-9,0V0C84,0,80.78,0,77.53,0A2.3,2.3,0,0,0,75.15,2.7C75.39,4,76.31,4.68,77.88,4.68Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M108,77.53a2.29,2.29,0,0,0-2.67-2.38c-1.29.23-2,1.16-2,2.72,0,5.73,0,11.46,0,17.19,0,5.08-3.15,8.23-8.23,8.23H77.75a2.39,2.39,0,0,0-2.64,2.35,2.34,2.34,0,0,0,2.55,2.3c6.2,0,12.4.07,18.6,0a12,12,0,0,0,11.67-11.66c.08-3,0-6,0-9h0C108,84,108,80.78,108,77.53Z"/>
                </g>
            </g>
        </svg>
    );
};

export default IconOfficeSVG;
