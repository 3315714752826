import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import useHistoryStore from "../../../stores/historyStore";
import SubSubMenuButtonGroup from "./SubSubMenuButtonGroup";
import {Link} from "react-router-dom";

export const SubMenuButton = ({data, ...rest}) => {
    const [hovered, setHover] = useState(false);

    const sceneCategory = useHistoryStore(state => state.sceneCategory);
    const productGroupCategory = useHistoryStore(state => state.productGroupCategory);
    const productCategory = useHistoryStore(state => state.productCategory);

    const selected = useMemo(() => {
        const comp = data.pathname.substring(1).split('/');
        const _sceneCategory = comp.length > 0 ? comp[0] : undefined;
        const _productGroupCategory = comp.length > 1 ? comp[1] : undefined;

        return _sceneCategory === sceneCategory && _productGroupCategory === productGroupCategory && productCategory
    }, [productCategory, productGroupCategory, sceneCategory]);

    const [newPath, setNewPath] = useState(`?pathname=${data.pathname}`);
    useEffect(()=>{
        setNewPath(useHistoryStore.getState().getNewPath(`?pathname=${data.pathname}`));
    }, [productCategory]);

    return (
        <li
            className="subMenuBtn"
            {...rest}
            style={{
                color: hovered || selected ? '#a50034' : '#000',
            }}
            onMouseOver={() => setHover(true)}
            onMouseOut={(e) => {
                setHover(false);
            }}
        >
            <Link to={newPath}>
                <div>{data.name}</div>
            </Link>
            <SubSubMenuButtonGroup subMenu2Data={data.subMenu2} />
        </li>
    )
}

const SubMenuButtonGroup = ({subMenuData}) => {
    return (
        <>
            {subMenuData && (
                <Wrapper>
                    {subMenuData.map((value, index) => {
                        return <SubMenuButton data={value} key={index}/>
                    })}
                </Wrapper>
            )}
        </>
    );
};

const Wrapper = styled.ul`
  padding: 0;
  margin: 2rem 4rem 2rem 0;
  position: relative;
  
  .subMenuBtn{
    font-weight: 700;
    font-size: 1.4rem;
    list-style-type: none;
    position: relative;
    
    div{
      width: 100%;
      padding: 0.25rem 2rem;
    }
  }

  .subMenuBtn + .subMenuBtn{
    margin-top: 1.7rem;
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {
    margin: 0;
    //background: green;
    
    .subMenuBtn{
      font-size: 1.8rem;

      div{
        padding: 0.25rem 0;
      }
    }
  }
`;


export default SubMenuButtonGroup;
