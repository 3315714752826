import React, {useMemo, useState} from 'react';
import styled from "styled-components";
import {animated, useSpring} from "react-spring";
import {easeCubicOut} from "d3-ease";


const CategoryButton = React.memo(({category, selectedCategory, selectCategory, totalCategory}) => {
    const [hovered, setHover] = useState(false);
    const selected = selectedCategory && (category.id === selectedCategory.id);

    // console.log("--->", selectedCategory, category.id);

    const props = useSpring({
        background: hovered ? (selected ? '#fff' : '#c9c9c9') : (selected ? '#fff' : '#e0e0e0'),
        // color: hovered ? (selected ? '#000' : '#333') : (selected ? '#000' : '#fff'),
        cursor: selected ? 'auto' : 'pointer',
        fontSize: selected ? '2rem' : (hovered ? '2.2rem' : '2rem'),
        config: {easing: easeCubicOut, duration: 200}
    });


    const onClickHandler = () => {
        selectCategory(category);
    };

    const onPointerOverHandler = () => {
        setHover(true);
    };
    const onPointerOutHandler = () => {
        setHover(false);
    };

    return (
        <StyledCategoryButton
            onClick={onClickHandler}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
            style={{
                background: props.background,
                // color: props.color,
                cursor: props.cursor,
                fontSize: props.fontSize,
                width: totalCategory === 1 ? 'auto' : (category.width ?? '100%'),
                padding: totalCategory === 1 ? '0 3rem' : 'auto'
            }}
        >
            {category.type.split('\n').map((n, idx) => {
                return (
                    <div key={idx}>
                        {n}<br/>
                    </div>
                );
            })}
        </StyledCategoryButton>
    );
});

const StyledCategoryButton = styled(animated.button)`
  height: 3.6rem;
  border: none;
  outline: none;
  font-size: 1.5rem !important;
  font-weight: 700;
  line-height: 1.6rem;
  align-content: center;

  margin-right: 0;
  margin-left: 0;
  //padding: 0 1rem;
  color: #000;
  
  div{
    white-space: nowrap;
  }

  //&:nth-child(even) {
  //  margin-left: 0.4rem;
  //}
  //
  //&:nth-child(odd) {
  //  margin-left: 0;
  //}

  @media screen and (max-width: 736px), screen and (max-height: 414px) {
    //flex: 0 14.8rem;
    font-size: 2rem;

    //&:nth-child(even) {
    //  margin-left: 0.4rem;
    //}
  }
`;

export default CategoryButton;
