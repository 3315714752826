import STUDIO_MAIN_DATA from "./studio_main";
import STUDIO_TV_DATA from "./studio_tv";
import STUDIO_FR_DATA from "./studio_fr";
import STUDIO_PROJECTOR_DATA from "./studio_projector";
import STUDIO_TOILET_DATA from "./studio_toilet";
import STUDIO_AIR_DATA from "./studio_air";

const data = [
    STUDIO_MAIN_DATA,
    STUDIO_TV_DATA,
    STUDIO_FR_DATA,
    STUDIO_PROJECTOR_DATA,
    STUDIO_TOILET_DATA,
    STUDIO_AIR_DATA
];
export default data;
