/**
 * 제품군 데이터 모듈 리턴
 * @param productGroupCategory 제품 그룹 카테고리 (ex. tvs, av ...)
 */
export const getDataModule = async (productGroupCategory) => {
    // console.log("getDataModule, ", productGroupCategory);

    switch (productGroupCategory) {
        case "tvs":
            return await import('./tvs');
        case "av":
            return await import('./av');
        case "laptop":
            return await import('./laptop');
        case "mobile":
            return await import('./mobile');
        case "fridge":
            return await import('./fridge');
        case "portable_speakers":
            return await import('./portable_speakers');
        case "monitor":
            return await import('./monitor');
        case "wm":
            return await import('./wm');
        case "washer_dryers":
            return await import('./washer_dryers');
        case "styler":
            return await import('./styler');
        case "it":
            return await import('./it');
        case "tone":
            return await import('./tone');
        case "wine_celler":
            return await import('./wine_celler');
        case "air":
            return await import('./air');
        default:
            throw new Error('No matching data module!');
    }
}

/**
 * 제품군에서 카테고리 리턴
 * @param productGroupCategory 제품 그룹 카테고리 (ex. tvs, av ...)
 */
export const getCategories = async (productGroupCategory) => {
    try {
        const data = await getDataModule(productGroupCategory);
        return data.categories;
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * 동일한 제품군, 제품 카테고리로 필터링한 결과 리턴
 * @param productGroupCategory 제품 그룹 카테고리 (ex. tvs, av ...)
 * @param productCategory 제품 카테고리 '{id, type, name}'
 */
export const getProducts = async (productGroupCategory, productCategory) => {
    try {
        const data = await getDataModule(productGroupCategory);
        return data.products.filter(product => product.category === productCategory.type);
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * 단일 제품 데이터 리턴
 * @param productGroupCategory 제품 그룹 카테고리 (ex. tvs, av ...)
 * @param modelName 제품 모델명
 */
export const getProduct = async (productGroupCategory, modelName) => {
    try {
        const data = await getDataModule(productGroupCategory);
        return data.products.filter(product => product.modelName === modelName)[0];
    } catch (e) {
        throw new Error(e);
    }
}

/**
 * 제품군의 제품 이미지 목록 리턴 (공간 합성용 이미지)
 * @param productGroupCategory 제품 그룹 카테고리 (ex. tvs, av, ...)
 * @param sceneId 공간 데이터의 ID (ex. family_tv, family_fr, ...)
 */
export const getProductImages = async (productGroupCategory, sceneId) => {
    try{
        const data = await getDataModule(productGroupCategory);
        if(data.productImages){
            return data.productImages.filter(d => d.sceneId === sceneId)[0];
        }else{
            return undefined;
        }
    }catch(e){
        throw new Error(e);
    }
}
