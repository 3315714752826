import {MENU_DATA} from "../../api/menu";
import styled from "styled-components";
import React, {useState} from "react";
import useHistoryStore from "../../stores/historyStore";
import IconFamilySVG from "./svg/IconFamilySVG";
import IconStudioSVG from "./svg/IconStudioSVG";
import IconOfficeSVG from "./svg/IconOfficeSVG";
import {ROOT_PATH_PREFIX} from "../../const";

const IntroMenuButton = ({data, ...props}) => {
    const {id, name, category, pathname} = data;

    const [hovered, setHovered] = useState(false);
    const navigate = useHistoryStore(state => state.navigate);

    return (
        <button
            id={`intro-${pathname.substring(1)}-btn`}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            onTouchStart={() => setHovered(true)}
            onTouchEnd={() => setHovered(false)}
            onClick={() => {
                navigate(`${ROOT_PATH_PREFIX}/?pathname=${pathname}`);
            }}
            {...props}
        >
            {pathname === '/family' &&
                <IconFamilySVG className="icon" color={hovered ? '#a50034' : '#9c9b9b'} showBorder={true}/>}
            {pathname === '/studio' &&
                <IconStudioSVG className="icon" color={hovered ? '#a50034' : '#9c9b9b'} showBorder={true}/>}
            {pathname === '/office' &&
                <IconOfficeSVG className="icon" color={hovered ? '#a50034' : '#9c9b9b'} showBorder={true}/>}
            <span>{name.toUpperCase()}</span>
        </button>
    );
}

const IntroMenu = () => {
    return (
        <IntroMenuStyle>
            {MENU_DATA.map((value, index) => {
                return (<IntroMenuButton data={value} key={index}/>)
            })}
        </IntroMenuStyle>
    );
}

const IntroMenuStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position: relative;
  min-height: 30rem;
  padding-top: 6rem;
  
  button {
    background: rgba(255, 255, 255, 0);
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.8rem;
    cursor: pointer;
    padding: 0;
    width: 11rem;
    color: #000;

    svg {
      width: 100%;
      height: 100%;
    }

    span {
      margin-top: 2rem;
      white-space: nowrap;
    }
  }

  button + button {
    margin-left: 24rem;
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {
    padding-top: 8rem;

    button {
      width: 7rem;
      font-size: 1.4rem;

      span {
        margin-top: 1rem;
      }
    }

    button + button {
      margin-left: 8rem;
    }
  }
`;

export default IntroMenu;
