import React from 'react';

const IconFamilySVG = ({color, showBorder = false, ...rest}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.99 107.98" {...rest}>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path className="cls-1" fill={color}
                          d="M21.45,41.74a2,2,0,0,0,2.37,3.08c1.56-.73,3.1-1.5,4.64-2.24.16-.08.34-.14.56-.23v40c0,1.75.71,2.46,2.43,2.46H78.29c1.69,0,2.42-.73,2.42-2.41V42.31l.88.41c1.42.7,2.84,1.4,4.28,2.08a2,2,0,0,0,2.87-1.29,2,2,0,0,0-1.22-2.32L81,37.91Q68.6,31.76,56.24,25.59a2.74,2.74,0,0,0-2.69,0Q37.94,33.39,22.32,41.15A3,3,0,0,0,21.45,41.74ZM41.17,47a9.33,9.33,0,0,1,13.11,1.72l.56.71-.22-.29c.84-.7,1.67-1.43,2.54-2.1a9.42,9.42,0,0,1,14,2.81C73.49,53.87,73.3,58,71,62a28.8,28.8,0,0,1-6,6.79c-2.82,2.51-5.7,4.95-8.56,7.42a2,2,0,0,1-2.8.11c-3.72-3.26-7.47-6.48-11.1-9.83a17.08,17.08,0,0,1-4.87-7.07A11.21,11.21,0,0,1,41.17,47Z"/>
                    <path className="cls-1" fill={color}
                          d="M69.26,27.69c1.75.85,3.47,1.73,5.2,2.6l2.27,1.12c0-2.84,0-5.55,0-8.26a2,2,0,0,0-2-2c-1.31,0-2.61,0-3.91,0a2,2,0,0,0-2,2.06c0,1.21,0,2.42,0,3.64A.74.74,0,0,0,69.26,27.69Z"/>
                    <path className="cls-1" fill={color}
                          d="M44.83,63.27c3.25,3.06,6.6,6,9.91,9l.47-.33c2.12-1.83,4.26-3.65,6.37-5.49A33.4,33.4,0,0,0,67,60.83c1.81-2.55,2.41-5.27,1.14-8.24-1.66-3.87-6.81-5.08-9.66-1.26a15.23,15.23,0,0,0-1.59,3c-.45,1-1,1.5-2,1.51a1.9,1.9,0,0,1-2-1.49,9.9,9.9,0,0,0-2-3.42A5.39,5.39,0,0,0,43,50.63a7,7,0,0,0-1.81,6.85A11.54,11.54,0,0,0,44.83,63.27Z"/>

                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M30.18,103.3c-5.77,0-11.54,0-17.32,0-5,0-8.16-3.16-8.17-8.16,0-5.77,0-11.55,0-17.32,0-1.62-.91-2.66-2.29-2.7A2.35,2.35,0,0,0,0,77.71C0,83.87,0,90,.06,96.18a12,12,0,0,0,11.72,11.75c3,.07,6,0,9,0v0c3.17,0,6.33,0,9.5,0a2.33,2.33,0,0,0,2.38-1.37C33.41,104.91,32.21,103.3,30.18,103.3Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M1.26,32.58c1.67.89,3.42-.31,3.42-2.38,0-5.78,0-11.55,0-17.32,0-5,3.15-8.19,8.14-8.19H30.28a2.39,2.39,0,0,0,2.6-2.4A2.35,2.35,0,0,0,30.29,0c-6.2,0-12.4-.08-18.6,0A12,12,0,0,0,.06,11.63c-.08,3,0,6.07,0,9.11H0c0,3.16,0,6.33,0,9.49A2.41,2.41,0,0,0,1.26,32.58Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M77.88,4.68H95.06c5.1,0,8.23,3.13,8.24,8.23,0,5.77,0,11.54,0,17.31a2.4,2.4,0,0,0,2.36,2.64,2.35,2.35,0,0,0,2.3-2.55c0-6.2.07-12.4,0-18.6A12,12,0,0,0,96.26.05c-3-.08-6,0-9,0V0C84,0,80.78,0,77.53,0A2.3,2.3,0,0,0,75.15,2.7C75.39,4,76.31,4.68,77.88,4.68Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M108,77.53a2.29,2.29,0,0,0-2.67-2.38c-1.29.23-2,1.16-2,2.72,0,5.73,0,11.46,0,17.19,0,5.08-3.15,8.23-8.23,8.23H77.75a2.39,2.39,0,0,0-2.64,2.35,2.34,2.34,0,0,0,2.55,2.3c6.2,0,12.4.07,18.6,0a12,12,0,0,0,11.67-11.66c.08-3,0-6,0-9h0C108,84,108,80.78,108,77.53Z"/>
                </g>
            </g>
        </svg>

    );
};

export default IconFamilySVG;
