import create from "zustand";

const useUspStore = create((set, get) => ({
    uspVideoUrl: undefined,
    arUrl: undefined,
    vrUrl: undefined,

    show: (uspUrl) => {
        set({uspVideoUrl: uspUrl, arUrl: undefined, vrUrl: undefined});
    },
    showAR: (url) => {
        set({uspVideoUrl: undefined, arUrl: url, vrUrl: undefined});
    },
    showVR: (url) => {
        set({uspVideoUrl: undefined, arUrl: undefined, vrUrl: url});
    },
    hide: () => {
        set({uspVideoUrl: undefined, arUrl: undefined, vrUrl: undefined});
    },
}));

export default useUspStore;
