import {DEFAULT_PATH} from "../../menu";

const HOME_MAIN_DATA = {
    "id": "family_main",
    "category":"family",
    "name": "Home sweet home",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.297391935482622
    },
    "linkHotspots": [
        {
            "yaw": -0.18400946135910345,
            "pitch": 0.02466837373458297,
            "rotation": 0,
            "pathname": DEFAULT_PATH.tv.family.oled,
            "target": "family_tv",
            "category": "tvs",
            "targetCameraView": {
                "yaw": 0.21943838760407708,
                "pitch": 0.03399234334229462,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.06983403346835004,
                "pitch": 0.15003453702875873,
                "fov": 1
            }
        },
        {
            "yaw": 3.1371327141354115,
            "pitch": 0.0863130335137523,
            "rotation": 0,
            "pathname": "/family/fridge/instaview/GSXV91BSAE",
            "target": "family_fr",
            "category": "fridge",
            "targetCameraView": {
                "yaw": 0.2775142281923735,
                "pitch": 0.09072746761459882,
                "fov": 1.19
            },
            "targetCameraView2": {
                "yaw": -0.03908154865812463,
                "pitch": 0.23186754865708771,
                "fov": 1.19
            }
        }
    ],
    "infoHotspots": [],
    // "defaultTV":{
    //     "sceneId": 'home_main',
    //     "yaw": -0.30971794515821685,
    //     "pitch": 0.09517205460392586,
    //     "width": 262,
    //     "height": 148,
    //     "radius": 1000,
    //     "extraTransforms": "rotateX(-5.338365497367403deg) rotateY(-17.848663500153663deg)",
    //     "url":"videos/home_tv.mp4"
    // }
};

export default HOME_MAIN_DATA;
