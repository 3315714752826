const STUDIO_TOILET_DATA = {
    "id": "studio_toilet",
    "category": "studio",
    "name": "studio_toilet",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.6
    },
    "linkHotspots": [
        {
            "yaw": -1.4383438335487906,
            "pitch": -0.04327098611218183,
            "rotation": 0,
            "target": "studio_main",
            "pathname": "/studio",
            "arrow": true
        },
        {
            "yaw": -0.17868564519623398,
            "pitch": 0.1189978782757315,
            "rotation": 0,
            "target": "studio_toilet",
            "category": "styler",
            "pathname": "/studio/styler/styler/S3WF",
            "targetCameraView": {
                "yaw": 0.02704449235143791,
                "pitch": 0.06191195100668523,
                "fov": 1.45
            },
            "targetCameraView2": {
                "yaw": -0.20996710058706114,
                "pitch": 0.19901686805104646,
                "fov": 1.8
            }
        },
        {
            "yaw": -0.5416543061973687,
            "pitch": -0.13403734489092223,
            "rotation": 0,
            "target": "studio_toilet",
            "category": "washer_dryers",
            "pathname": "/studio/washer_dryers/washer_dryer/FWV917BTSE",
            "targetCameraView": {
                "yaw": 0.02704449235143791,
                "pitch": 0.06191195100668523,
                "fov": 1.45
            },
            "targetCameraView2": {
                "yaw": -0.5704377016918869,
                "pitch": 0.07715685342463097,
                "fov": 1.45
            }
        },
        {
            "yaw": -0.5416543061973687,
            "pitch": 0.40148313362303867,
            "rotation": 0,
            "target": "studio_toilet",
            "category": "wm",
            "pathname": "/studio/wm/turbowash360/F6V1010BTSE",
            "targetCameraView": {
                "yaw": 0.02704449235143791,
                "pitch": 0.06191195100668523,
                "fov": 1.45
            },
            "targetCameraView2": {
                "yaw": -0.5448200723351064,
                "pitch": 0.4816234939238875,
                "fov": 1.45
            }
        }
    ],
    "infoHotspots": [
        {
            "yaw": -0.7069122529941066,
            "pitch": 0.6011769453472873,
            "category": "wm",
            "size": {w: 383, h: 566},
            "defaultModelName":"F6V1010BTSE",
            "images": [
                {url: "products/update_20210427/studio_product/wash/wm/F6V1010BTSE.png", modelName: "F6V1010BTSE"},
            ],
            "radius": 1280,
            "extraTransforms": "rotateX(-34.44490170896651deg) rotateY(-40.457251959036135deg)"
        },
        {
            "yaw": -0.7068765958803951,
            "pitch": 0.15060599554021614,
            "category": "washer_dryers",
            "size": {w: 383, h: 517},
            "defaultModelName":"FWV917BTSE",
            "images": [
                {url: "products/update_20210427/studio_product/wash/dry/FWV917BTSE.png", modelName: "FWV917BTSE", offsetY:1},
            ],
            "radius": 1070,
            "extraTransforms": "rotateX(-8.51253975350243deg) rotateY(-40.501045580521314deg)"
        },
        {
            "yaw": -0.3485537896290888,
            "pitch": 0.7024576380429455,
            "category": "styler",
            "size": {w: 391, h: 1187},
            "images": [
                {url: "products/studio_product/wash/styler/s3wf.png", modelName: "wf", h:1187},
            ],
            "radius": 1122,
            "extraTransforms": "rotateX(-40.24785794658919deg) rotateY(-19.913365299524468deg)"
        }
    ]
};
export default STUDIO_TOILET_DATA;
