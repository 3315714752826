import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import IconFamilySVG from "../../svg/IconFamilySVG";
import IconStudioSVG from "../../svg/IconStudioSVG";
import IconOfficeSVG from "../../svg/IconOfficeSVG";
import SubMenuButtonGroup from "../SubMenuButtonGroup";
import menuStore from "../../../../stores/menuStore";
import useMenuStore from "../../../../stores/menuStore";
import {animated, useSpring} from "react-spring";
import {easeCubicOut} from "d3-ease";


const MenuButton_M = ({data, selected, ...props}) => {
    const {id, name, category, pathname} = data;

    const sceneCategory = useMenuStore(state => state.sceneCategory);
    const isOpenFirst = useMenuStore(state => state.isOpenFirst);

    const active = useMemo(() => sceneCategory === category, [sceneCategory]);
    const sData1 = useMemo(() => data.subMenu.filter(value => value.subMenu2 !== undefined), [data]);
    const sData2 = useMemo(() => data.subMenu.filter(value => value.subMenu2 === undefined), [data]);

    const spring = useSpring({
        height: (active ? 300 : 0),
        config: {duration: isOpenFirst ? 0 : 500, easing: easeCubicOut}
    });

    return (
        <MainButtonStyle {...props} selected={active}
                         className="mainBtn"
                         onMouseOver={(e) => {
                             e.stopPropagation();
                             menuStore.getState().setHover(category);
                         }}
                         onMouseOut={(e) => {
                             e.stopPropagation();   //중요!
                         }}
        >
            <button className="categoryBtn" onClick={(e) => {
                e.stopPropagation();
                menuStore.getState().setSceneCategory(category);
            }}>
                <div className="sceneName">
                    {pathname === '/family' &&
                        <IconFamilySVG className="icon" color={active ? '#a50034' : '#000'}/>}
                    {pathname === '/studio' &&
                        <IconStudioSVG className="icon" color={active ? '#a50034' : '#000'}/>}
                    {pathname === '/office' &&
                        <IconOfficeSVG className="icon" color={active ? '#a50034' : '#000'}/>}
                    <span className="txt">{name.toUpperCase()}</span>
                </div>

                <div className="plusIcon" style={{
                    backgroundColor: active ? '#a50034' : '#000'
                }}>
                    <span>+</span>
                </div>

                <div className="bottom-line" style={{
                    borderColor: active ? '#a50034' : '#000'
                }}/>
            </button>

            <animated.div style={{
                height: spring.height,
            }} className="subMenuMask">
                <SubMenuStyle
                    style={{
                        // height: active ? 'auto' : '0',
                        // height: spring.height,
                        // display: active ? 'inline-flex' : 'none'
                    }}
                >
                    {sData1 && sData1.length > 0 && (
                        <li className="vGroup">
                            <SubMenuButtonGroup subMenuData={sData1}/>
                        </li>
                    )}
                    {sData2 && sData2.length > 0 && (
                        <li className="vGroup">
                            <SubMenuButtonGroup subMenuData={sData2}/>
                        </li>
                    )}
                </SubMenuStyle>
            </animated.div>
        </MainButtonStyle>
    );
}


const MainButtonStyle = styled.li.attrs(props => ({
    color: props.selected ? '#a50034' : '#000'
}))`
  list-style-type: none;
  height: 100%;
  position: relative;
  cursor: pointer;
  padding: 0 !important;
  margin: 0 !important;

  .categoryBtn {
    width: 100%;
    border: none;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5rem;
    padding-left: 0;
    position: relative;
    pointer-events: visible;
    cursor: pointer;
    background: rgba(255, 255, 255, 0);

    .sceneName {
      //background: rebeccapurple;
      height: 100%;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      left: -0.7rem;
    }

    .plusIcon {
      background: #000;
      border: none;
      width: 3rem;
      height: 3rem;
      font-size: 4rem;
      color: white;
      cursor: pointer;
      border-radius: 50%;
      padding: 0 0 0.4rem 0 !important;

      span {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -1rem;
      }
    }

    .icon {
      height: 70%;
      transform: translateZ(0);
    }

    .txt {
      font-weight: 700;
      font-size: 2rem;
      color: ${props => props.color};
      white-space: nowrap;
    }

    .bottom-line {
      position: absolute;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid #000;
      left: 0;
    }
  }

  .subMenuMask {
    width: 100%;
    overflow: hidden;
  }
`;

const SubMenuStyle = styled(animated.ul)`
  position: relative;
  left: 0;
  top: 0;
  z-index: 99999;
  display: inline-flex;
  white-space: nowrap;
  //background: #61dafb;

  width: 100%;
  padding: 2rem 0 5rem 0;
  margin: 0;
  overflow: hidden;

  .vGroup {
    list-style-type: none;
    //background: #61fb6e;
    //overflow: hidden;
    position: relative;
    height: 100%;
  }

  .vGroup + .vGroup {
    margin-left: 8rem;
  }
`

export default MenuButton_M;
