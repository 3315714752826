import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {darken} from "polished";
import {useTransition, animated, useSpring} from "react-spring";
import {easeCubicInOut, easeCubicOut} from "d3-ease";
import useHistoryStore from "../../stores/historyStore";
import IconArSVG from "../v2/svg/IconArSVG";
import useUspStore from "../../stores/uspStore";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../const";
import IconVrSVG from "../v2/svg/IconVrSVG";
import IconUspSVG from "../v2/svg/IconUspSVG";
import USPButtons from "../v2/USPButtons";

//region Product데이터 샘플
/*
{
    "modelName": "OLED83G26LA",
    "category": "OLED",
    "title": "LG G2 83 inch evo Gallery Edition",
    "thumbUrl": "https://www.lg.com/uk/images/tvs/md07548378/gallery/D-1.jpg",
    "features": [],
    "sizes": [
    {
        "size": "83",
        "modelName": "OLED83G26LA"
    }],
    "link": "https://www.lg.com/uk/tvs/lg-oled83g26la",
    "new": true,
    "uspVideoUrl" : "/videos/usp/tv/oled_g26la.mp4"
}
*/
//endregion

/**
 * 사이즈 버튼
 * @param sizeData
 * @param selected
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SizeButton = ({sizeData, selected, ...props}) => {
    const [hovered, setHover] = useState(false);
    const spring = useSpring({
        border: selected ? '0.2rem solid #be0738' : (hovered ? '0.2rem solid #666' : '0.2rem solid #aeaeae'),
        config: {duration: 200, easing: easeCubicOut}
    })
    return (
        <animated.button
            className="sizeBtn" {...props}
            style={{
            border: spring.border,
            cursor: selected ? 'auto' : 'pointer'
        }}
            onClick={() => {
            if (selected) return;
            useHistoryStore.getState().selectProduct(sizeData.modelName);
        }}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
        >
            {sizeData.size}"
        </animated.button>
    );
}

/**
 * 단일 페이지 뷰
 * @param style
 * @param product 제품 데이터
 * @returns {JSX.Element}
 * @constructor
 */
const PageView = React.memo(({style, product}) => {
    const [state, setState] = useState({
        loading: false,
        loaded: false
    });

    const spring = useSpring({
        visibility: state.loaded ? 'visible' : 'hidden',
        opacity: state.loaded ? 1 : 0
    });

    const isShowTopUI = useMemo(() => {
        if (!product) return false;
        return product.new || product.arUrl || product.uspVideoUrl;
    }, []);

    const usp = useHistoryStore(state => state.usp);

    useLayoutEffect(() => {
        setState(s => ({...s, loading: true, loaded: false}));
        if (!product?.uspVideoUrl) {
            useUspStore.getState().hide();
        }
    }, []);

    useLayoutEffect(() => {
        if (usp && product) {
            if (usp === 'key_feature') {
                useUspStore.getState().show(product.uspVideoUrl);
            } else if (usp === 'vr') {
                if (IS_MOBILE) {
                    window.open(product.vrUrl, '_self');
                } else {
                    useUspStore.getState().showVR(product.vrUrl);
                }
            } else if (usp === 'ar') {
                if (IS_MOBILE) {
                    if (product.arUrl2) window.open(product.arUrl2, '_self');
                    else if (product.arUrl) window.open(product.arUrl, '_self');
                } else {
                    if (product.arUrl === 'https://lgvirtualstudio-he.com/ar/g1') {
                        window.open(product.arUrl, '_blank');
                    } else {
                        useUspStore.getState().showAR(product.arUrl);
                    }
                }
            }
        }
    }, [usp]);


    const newThumbUrl = useMemo(() => {
        if(product?.thumbUrl.startsWith('/', 0)){
            return `${ROOT_PATH_PREFIX}${product.thumbUrl}`;
        }else{
            return product?.thumbUrl;
        }
    }, [product]);


    return (
        <PageViewStyle style={style} className="detailInfo">
            {product &&
                <>
                    {isShowTopUI && <USPButtons product={product} />}

                    <p className="modelName">{product.modelName}</p>
                    <p className="title">{product.title}</p>

                    <div className="photoContainer">
                        <animated.img src={newThumbUrl} style={{
                            opacity: spring.opacity,
                            visibility: spring.visibility
                        }} onLoad={(e) => {
                            setState({...state, loading: false, loaded: true});
                            e.target.style.transform = `scale(${product.thumbScale ?? 1})`;
                            e.target.style.margin = `${product.thumbMargin ?? 0}`;
                        }} alt=''/>
                    </div>
                    <ul>
                        {product.features && product.features.map((feature, idx) => (<li key={idx}>{feature}</li>))}
                    </ul>
                </>
            }
        </PageViewStyle>
    );
});

const pages = [
    ({style, product}) => <PageView style={style} product={product}/>,
    ({style, product}) => <PageView style={style} product={product}/>
];

/**
 * 제품 상세 뷰
 * @type {React.NamedExoticComponent<{readonly selectedProduct?: *}>}
 */
const ProductDetailView = React.memo(({selectedProduct}) => {

    const [currentProduct, setCurrentProduct] = useState(null);
    const [prevProduct, setPrevProduct] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const transitions = useTransition(pageIndex, {
        from: {opacity: 0, transform: 'translate(100%, 0) scale(1)'},
        enter: {opacity: 1, transform: 'translate(0%, 0) scale(1)'},
        leave: {opacity: 0, transform: 'translate(-50%,0) scale(1)'},
        // trail: 500
        config: {duration: 500, easing: easeCubicInOut}
    });

    //사이즈 정보 (TV 전용)
    const newSizes = useMemo(() => {
        const sceneCategory = useHistoryStore.getState().sceneCategory;
        if (sceneCategory === 'family') return selectedProduct.sizes;
        else return selectedProduct.sizes.filter(value => value.modelName !== 'OLED88Z19LA');
    }, [selectedProduct]);

    //페이지 인덱스 관리
    useEffect(() => {
        /*
        ESLint 경고를 없에기 위해 currentProduct 종속성을 추가했더니 setCurrentProduct()호출 후 useEffect()가 2번 반복되는 문제가 생겨서
        currentProduct 가 이미 selectedProduct 로 바뀌었으면 무시하도록 함
        */
        if (currentProduct === selectedProduct) return;

        setPageIndex(p => (p + 1) % 2);
        setPrevProduct(currentProduct);

        setCurrentProduct(selectedProduct);

    }, [selectedProduct, currentProduct]);

    //More Information 버튼 클릭 시
    const onClickMoreInfo = () => {
        if (selectedProduct.link) {
            useHistoryStore.getState().clickInfoOrBuy('click_more_information');
            window.open(`${selectedProduct.link}`);
        }
    }

    //Where To Buy 버튼 클릭 시
    const onClickWhereToBuy = () => {
        if (selectedProduct.link) {
            useHistoryStore.getState().clickInfoOrBuy('click_where_to_buy');
            window.open(`${selectedProduct.link}#pdp_where`);
        }
    }

    return (
        <StyledProductDetailView>
            {selectedProduct &&
                <>
                    {newSizes && (
                        <SizeButtonGroup>
                            {newSizes.map((value, index) => {
                                return <SizeButton sizeData={value} key={index}
                                                   selected={value.modelName === selectedProduct.modelName}/>
                            })}
                        </SizeButtonGroup>
                    )}

                    <div className="pageContainer">
                        {transitions((props, item) => {
                            const Page = pages[item];
                            // console.log(">>", (item === index));

                            if (item === pageIndex) {
                                const targetTrans = (prevProduct === null) ? 'translate(0, 0) scale(1)' : props['transform'];
                                return <Page style={{...props, transform: targetTrans}}
                                             product={currentProduct}/>
                            } else {
                                return <Page style={props} product={prevProduct}/>
                            }
                        })}
                    </div>

                    <div className="hGroup">
                        <RoundButton onClick={onClickMoreInfo} id="more-info-button">More information</RoundButton>
                        <RoundButton2 onClick={onClickWhereToBuy} id="where-to-buy-button">Where to buy</RoundButton2>
                    </div>
                </>}
        </StyledProductDetailView>
    );
});


//region Styles
const StyledProductDetailView = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  padding: 2rem 1rem 2rem;
  box-sizing: border-box;
  color: black;
  display: flex;
  flex-direction: column;

  .pageContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .hGroup {
    display: inline-flex;
    justify-content: space-between;
    margin: 1rem 1rem 0;

    button {
      width: 100%;
      white-space: nowrap;
    }

    button + button {
      margin-left: 1rem;
    }
  }
`;

const PageViewStyle = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0);

  .topUIGroup {
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.2rem;
    padding-right: 1rem;

    .newBadge {
      background: #a50034;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem 0.8rem;
      font-size: 1.2rem;
      height: 2rem;
    }

    .btnGroup {
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      button + button {
        margin-left: 0;
      }

      .arBtn {
        cursor: pointer;
        background: rgba(255, 255, 255, 0);
        padding: 0;
        border: none;
        width: 5rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 100%;
          color: #333;
        }
      }

      .vrBtn {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 4rem;
        height: 2.3rem;

        svg {
          transform: scale(0.9);
        }
      }

      .uspBtn {
        margin-left: -1rem;
      }
    }
  }

  .modelName {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 600;
    user-select: text;
  }

  .title {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 2rem;
    user-select: text;
  }

  .photoContainer {
    background: #eeeeee;
    width: 25.8rem;
    height: 16.8rem;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  ul {
    padding-left: 1.8rem;
    padding-right: 1rem;
    padding-top: 1rem;
    user-select: text;

    li {
      font-weight: normal;
      font-size: 1.2rem;
      line-height: 1.3rem;
      user-select: text;
    }

    li + li {
      margin-top: 0.8rem;
    }
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {
    .photoContainer {
      //background: white;
      background-color: rgba(255, 255, 255, 0);
      width: 100%;
      height: 0;
      overflow: hidden;
      display: flex;
      justify-content: center;
      padding: 0.5rem;
      opacity: 0;
      visibility: hidden;

      img {
        width: auto;
        height: 100%;
      }
    }

    ul {
      padding-top: 0;
    }
  }


`;

const RoundButton = styled.button`
  //border-radius: 0.5rem;
  border: none;
  background: #a50034;
  color: white;
  outline: none;
  cursor: pointer;
  padding: 0.6rem 1rem;
  //width: 100%;
  //margin: 2rem 1rem 0;
  font-size: 1.1rem;
  font-weight: bold;
  height: 100%;

  &:hover {
    background: ${darken(0.1, '#a50034')};
  }

  @media screen and (max-width: 1280px), screen and (max-height: 768px) {
    font-size: 1.5rem;
    //padding-bottom: 2rem;
  }
`;

const RoundButton2 = styled.button`
  //border-radius: 0.5rem;
  //border: 1px solid #333;
  border: none;
  background: #e0e0e0;
  color: #333;
  outline: none;
  cursor: pointer;
  padding: 0.6rem 1rem;
  //width: 100%;
  //margin: 0.4rem 1rem 0;
  font-size: 1.1rem;
  font-weight: bold;
  height: 100%;

  &:hover {
    background: ${darken(0.2, 'white')};
  }

  @media screen and (max-width: 1280px), screen and (max-height: 768px) {
    font-size: 1.5rem;
    //padding-bottom: 2rem;
  }
`;

const SizeButtonGroup = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;

  margin: 0 0 1rem;
  //background: #61dafb;
  padding: 0 1rem 0.5rem;

  .sizeBtn {
    cursor: pointer;
    border-radius: 0.3rem;
    color: black;
    font-weight: 700;
    font-size: 1.2rem;
    padding: 0.25rem 0.5rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
`;
//endregion

export default ProductDetailView;
