import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import useProductsStore from "../../../stores/productsStore";
import usePanoStore from "../../../stores/panoStore";
import {animated, useSpring} from "react-spring";
import {easeCubicOut} from "d3-ease";
import useSelectedProductStore from "../../../stores/selectedProductStore";
import useHistoryStore from "../../../stores/historyStore";
import {getDataModule} from "../../../api/products";
import {ROOT_PATH_PREFIX} from "../../../const";

const isAppleMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);

const StyledImageHotspot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  //background: crimson;
  //width: 2px;
  //height: 2px;
  left: 0;
  top: 0;

  #imgContainer {
    //background: green;
    //opacity: 0.85;

    position: absolute;
    padding: 0;
    margin: 0;
  }

  .pImgGroup {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #61dafb;

    .videoContainer {
      width: 200px;
      height: 200px;
      //background: crimson;
      position: absolute;
      left: 0;
      bottom: 0;
      pointer-events: none;
      opacity: 0;
      overflow: hidden;

      video {
        object-fit: cover;
        position: absolute;
        height: 100%;
      }
    }
  }

  img {
    position: absolute;
    //background: coral;
    pointer-events: none;
  }
`;

const Wrapper = styled(animated.div)`
  //background: white;
  //border: 5px solid white;
  border-radius: 50%;
  //color: black;
  width: 40px;
  height: 40px;
  z-index: 999;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ProductImage = React.memo(({src, show, loading, videoData, ...rest}) => {

    const [active, setActive] = useState(false);
    const [videoReady, setVideoReady] = useState(false);
    const spring = useSpring({
        opacity: active ? 1 : 0,
        config: {duration: 500, easing: easeCubicOut}
    });
    const spring2 = useSpring({
        opacity: videoReady ? 0.99 : 0,
        config: {duration: 1000}
    });
    const videoContainer = useRef(null);
    const video = useRef(null);
    let delayed;


    useEffect(() => {
        setVideoReady(false);
        if (loading) return;
        if (show !== active) {
            setActive(show);
        }
        if (videoContainer.current) {
            if (delayed) clearTimeout(delayed);
            if (videoData && show) {
                videoContainer.current.style.left = `${videoData.offsetX}px`;
                videoContainer.current.style.bottom = `${videoData.offsetY}px`;
                videoContainer.current.style.width = `${videoData.width}px`;
                videoContainer.current.style.height = `${videoData.height}px`;

                if (video.current && video.current.paused) {
                    video.current.src = videoData.url;
                    const playPromise = video.current.play();
                    if (playPromise) {
                        playPromise.then(_ => {
                            if (isAppleMobile) {
                                video.current.muted = true;
                            } else {
                                video.current.muted = false;
                                video.current.volume = 0.1;
                            }
                        }).catch(err => {
                        });
                    }
                }

                delayed = setTimeout(() => {
                    setVideoReady(true);
                }, 1000);
            } else {
                if (video.current && !video.current.paused) {
                    video.current.pause();
                }
            }
        }
    }, [show, loading, videoData]);

    return (
        // <animated.img src={src} style={{opacity: spring.opacity}} {...rest} />
        <animated.div style={{opacity: spring.opacity}} className='pImgGroup'>
            <img src={src}/>
            {videoData &&
                <animated.div className='videoContainer'
                              ref={videoContainer}
                              style={{opacity: spring2.opacity}}>
                    <video ref={video} src='src/components/v1/hotspots/ImageHotspot' loop autoPlay={false}
                           crossOrigin='anonymous' muted={true} playsInline/>
                </animated.div>}
        </animated.div>
    );
});

export const LoadingView = React.memo(({show, style}) => {
    const [active, setActive] = useState(false);
    const spring = useSpring({
        opacity: active ? 1 : 0,
        config: {duration: 300}
    });
    useEffect(() => {
        setActive(show);
    }, [show]);

    return (
        <Wrapper style={{opacity: spring.opacity, ...style}}>
            <img src={`${ROOT_PATH_PREFIX}/img/loading.gif`}/>
        </Wrapper>
    );
});


/**
 * 공간에 배치되는 제품이미지
 * @param hotspot /api/scenes 하위의 공간데이터에서 infoHotspots 부분의 데이터
 * @param sceneId 공간데이터의 id값
 */
const ImageHotspot = React.memo(({hotspot, sceneId}) => {

        const {category: dataType, images: _images, size, defaultModelName, extraName} = hotspot;

        const containerEl = useRef(null);
        const imgContainerEl = useRef(null);
        const loaderEl = useRef(null);

        const selectedProduct = useProductsStore(state => state.selectedProduct);
        const isLoadingTvProductImg = useProductsStore(state => state.isLoadingTvProductImg);
        const setIsLoadingTvProductImg = useProductsStore(useCallback(state => state.setIsLoadingTvProductImg, []));

        const selectedScene = usePanoStore(state => state.selectedScene);
        const pathname = useHistoryStore(state => state.pathname);

        const [pageIndex, setPageIndex] = useState(-1);
        const imageNodes = useRef([]);
        const [active, setActive] = useState(true);
        const [state, setState] = useState({
            loading: false,
            imageData: null
        });

        const videoData = useMemo(() => {
            if (!selectedProduct) return null;

            if (selectedScene.data.id === sceneId) {
                if (hotspot.category.indexOf('tvs') > -1 &&
                    sceneId.indexOf('tv') > -1 &&
                    selectedProduct.modelName.toLowerCase().indexOf(selectedScene.data.defaultTV.modelName.toLowerCase()) > -1) {
                    return selectedScene.data.defaultTV;
                } else {
                    return null;
                }
            }
        }, [selectedScene, sceneId, selectedProduct]);

        let delayVal;
        const tempImageData = useRef(null);

        /*
        * 이미지 업데이트
        * */
        const updateProductImage = (imageData, first = false) => {

            if (imgContainerEl.current && imageData.length > 0) {

                const defaultData = _images.filter(d => d.modelName === defaultModelName);
                const _imageData = (first && defaultData.length > 0) ? defaultData : imageData;
                const dataUrl = _imageData[0].url;

                if (loaderEl.current.src.indexOf(dataUrl) === -1) {
                    setState({...state, loading: true, imageData: _imageData});
                    if (dataType.indexOf('tv') > -1) setIsLoadingTvProductImg(true);

                    if (delayVal) clearTimeout(delayVal);
                    loaderEl.current.src = dataUrl;
                    setPageIndex(p => (p + 1) % 2);
                    // console.log("새로 로딩");
                } else {
                    // console.log("같은 이미지");
                }
            }
        };

        useLayoutEffect(() => {
            setState({...state, loading: false, imageData: _images});
            imageNodes.current = imgContainerEl.current.childNodes;
            // console.log(imageNodes.current[pageIndex], pageIndex);

            if (imageNodes.current) {
                if (imageNodes.current[pageIndex]) {
                    imageNodes.current[pageIndex].style.opacity = 0;
                }
                if (imageNodes.current[pageIndex + 1]) {
                    imageNodes.current[pageIndex + 1].style.opacity = 0;
                }
            }

        }, []);

        const newImages = useRef(null);

        //pathname 경로가 바뀌면 초기화
        useEffect(() => {

            async function action() {
                const {
                    sceneCategory,
                    productGroupCategory,
                    productCategory,
                    modelName,
                    sceneId: selectedSceneId,
                    prevSceneId,
                    prevPathname
                } = useHistoryStore.getState();
                const isChangeScene = selectedSceneId !== prevSceneId;
                const isUpdate = useHistoryStore.getState().check(dataType) && (sceneId === selectedSceneId);
                let imageData;
                let images = newImages.current ?? _images;

                // setActive(isUpdate);

                //현재 공간 안에 들어있는 제품인지 체크
                if (isUpdate) {

                    //현재 URL과 일치하는 제품인지
                    if ((dataType === productGroupCategory) && modelName && productCategory) {
                        // console.log("A: ", dataType, state.loading, prevModelName, prevProductGroupCategory);

                        //제품 데이터에 productImages가 있으면 images 대신 그것을 사용 Start
                        if (!newImages.current) {
                            const dataModule = await getDataModule(productGroupCategory);
                            let search = dataModule?.productImages?.filter(d => d.sceneId === sceneId);
                            if (search && search.length > 0) {
                                if (extraName) search = search.filter(d => d.extraName === extraName);
                                newImages.current = images = search[0].images;
                                // console.log("newImages 설정: ", newImages.current.length, ", 기존 이미지: ", _images.length);
                            }
                        }
                        //End

                        imageData = images.filter(data => modelName.toUpperCase().indexOf(data.modelName.toUpperCase()) > -1);
                        if (state.loading) {
                            // console.log("아직 이전 로딩이 끝나지 않았다. tempImageData에 저장 후 대기");
                            tempImageData.current = imageData;
                        } else {
                            updateProductImage(imageData);
                        }

                        //냉장고의 경우 동시에 2개가 노출되기 때문에 업데이트 타이밍 보정
                        //선택된 제품카테고리가 Combi이면 Combi가 아닌 냉장고 이미지를 추가로 업데이트
                        if (sceneCategory === 'family' && productGroupCategory === 'fridge' && (!prevPathname || isChangeScene)) {
                            if (productCategory) {
                                if (productCategory === 'combi') {
                                    imageData = images.filter(data => "LSR100".indexOf(data.modelName.toUpperCase()) > -1);
                                } else {
                                    imageData = images.filter(data => "GBB92MCBAP".indexOf(data.modelName.toUpperCase()) > -1);
                                }
                                updateProductImage(imageData, true);
                            }
                        }

                        if (productGroupCategory === 'monitor') {
                            setActive(true);
                        }
                    } else {
                        if (!prevPathname || isChangeScene) {
                            // console.log("B: ", dataType);
                            updateProductImage(images, true);
                        }

                        if (dataType === 'monitor') {
                            setActive(false);
                        }
                    }
                }
            }

            action();

        }, [pathname]);


        const STAND_TYPE_PRODUCTS = [
            'OLED88Z19LA',
            '65ART90E6QA',
            '55LX1Q6LA',
            '48LX1Q6LA',
            '42LX1Q6LA',
        ];

        useEffect(() => {

            const {sceneId: selectedSceneId} = useHistoryStore.getState();
            const isUpdate = useHistoryStore.getState().check(dataType);

            if (isUpdate) {
                if (dataType === 'av' && selectedProduct) {
                    if (isLoadingTvProductImg) return;
                    let active = true;
                    if (selectedSceneId === 'family_tv') {
                        const selectedInfo = useSelectedProductStore.getState().getProduct("family", "tvs");
                        if (selectedInfo) {
                            const search = STAND_TYPE_PRODUCTS.filter(d => selectedInfo.pathname.toUpperCase().indexOf(d) > -1);
                            if (search.length > 0) {
                                active = false;
                            }
                        }
                    }
                    setActive(active);
                }
            }
        }, [selectedProduct, isLoadingTvProductImg]);

        //스탠드형 TV가 사운드바 위로 올라오도록 zIndex 컨트롤
        useLayoutEffect(() => {
            const {sceneId: selectedSceneId, modelName} = useHistoryStore.getState();
            let targetZIndex = 0;
            if (dataType === 'tvs' && selectedSceneId === 'family_tv') {
                const search = STAND_TYPE_PRODUCTS.find(d => d.toUpperCase() === modelName?.toUpperCase());
                if (search) targetZIndex = 1;
            }
            containerEl.current.parentElement.style.zIndex = targetZIndex.toString();
        }, [pathname]);


        return (
            <StyledImageHotspot ref={containerEl}
                                id='imgHotspot'
                                style={{
                                    top: `${_images[0].offsetY ? _images[0].offsetY : 0}px`,
                                    left: `${_images[0].offsetX ? _images[0].offsetX : 0}px`
                                }}>

                {_images && _images.length >= 0 &&
                    <div ref={imgContainerEl}
                         id='imgContainer'
                         style={{
                             width: `${size.w}px`,
                             height: `${size.h}px`
                         }}>
                        <ProductImage src={_images[0].url} show={pageIndex === 0 && active} loading={state.loading}
                                      videoData={videoData}/>
                        <ProductImage show={pageIndex === 1 && active} loading={state.loading} videoData={videoData}/>
                        <LoadingView show={state.loading} style={{
                            left: `${(size.w - 30) / 2}px`,
                            top: `${(size.h - 30) / 2}px`
                        }}/>
                    </div>}

                <img ref={loaderEl} style={{visibility: 'hidden'}} onLoad={(e) => {

                    const url = e.target.src;
                    const currentNode = imageNodes.current[pageIndex];
                    const imageData = state.imageData;

                    currentNode.childNodes[0].src = url;

                    currentNode.style.top = `${imageData[0].offsetY ? imageData[0].offsetY : 0}px`;
                    currentNode.style.left = `${imageData[0].offsetX ? imageData[0].offsetX : 0}px`;

                    if (dataType.indexOf('tv') > -1) setIsLoadingTvProductImg(false);

                    setState({...state, loading: false});

                    // console.log("tempImageData: ", tempImageData.current, state.loading);

                    //예약된 로딩이 있으면 다시 로딩처리
                    delayVal = setTimeout(() => {
                        if (tempImageData.current) {
                            updateProductImage(tempImageData.current);
                            tempImageData.current = null;
                        }
                    }, 500);
                }}/>
            </StyledImageHotspot>
        );
    }
);

export default ImageHotspot;
