import React from 'react';
import styled from "styled-components";


const IconSkipSVG = ({color}) => {
    return (
        <Wrapper id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.6 122.7">
            <g id="Layer_1-2">
                <polygon className="cls-2" points="0 0 74.26 61.35 0 122.7 0 0" fill={color}/>
                <line className="cls-1" x1="82.6" y1="122.7" x2="82.6" y2="0" stroke={color}/>
            </g>
        </Wrapper>
    );
};

const Wrapper = styled.svg`
  height: 40%;
  margin-left: 0.4rem;
  
  .cls-1 {
    stroke-miterlimit: 10;
    stroke-width: 2.4rem;
  }
`;

export default IconSkipSVG;
