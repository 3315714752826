function sendData(data) {
    console.log(data);

    if (window.parent) {
        window.parent.postMessage(data, "*");
    }

    const dataLayer = window.dataLayer;
    if (dataLayer) {
        dataLayer.push(data);
    }
}

export function GA_Event(
    location,
    sceneCategory,
    productGroupCategory,
    productCategory,
    modelName,
    usp) {

    const data = {
        event: 'ga_event',
        location,
        sceneCategory: sceneCategory ?? '',
        productGroupCategory: productGroupCategory ?? '',
        productCategory: productCategory ?? '',
        modelName: modelName ?? '',
        usp: usp ?? ''
    }
    sendData(data);
}


export function GA_Event_Click(
    eventName,
    location,
    sceneCategory,
    productGroupCategory,
    productCategory,
    modelName) {

    if(!eventName)
    {
        console.log(`[GA_Event_Click] eventName === undefined`);
        return;
    }

    const data = {
        event: eventName,
        location,
        sceneCategory: sceneCategory ?? '',
        productGroupCategory: productGroupCategory ?? '',
        productCategory: productCategory ?? '',
        modelName: modelName ?? ''
    }
    sendData(data);
}
