const OFFICE_LAPTOP_DATA = {
    "id": "office_laptop",
    "category": "office",
    "name": "office_laptop",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.5707963267948966
    },
    "linkHotspots": [
        {
            "yaw": -0.5624237659893243,
            "pitch": 0.18421720727434376,
            "rotation": 0,
            "target": "office_monitor",
            "category": "laptop",
            "targetCameraView": {
                "yaw": -0.3773571158212441,
                "pitch": 0.21475084933014976,
                "fov": 1.1
            }
        },
        {
            "yaw": 0.009693047885319217,
            "pitch": -0.0419593630986288,
            "rotation": 0,
            "target": "office_monitor",
            "category": "monitor",
            "pCategoryIndex":1,
            "pIndex":1,
            "targetCameraView": {
                "yaw": 0.3559575860957267,
                "pitch": 0.01352287731259949,
                "fov": 1.1
            }
        },
        {
            "yaw": 3.035373303884949,
            "pitch": 0.035054887700272985,
            "rotation": 0,
            "target": "office_tv",
            "category": "tvs",
            "pCategoryIndex":0,
            "pIndex":10,
            "targetCameraView": {
                "yaw": 0.27684357558200645,
                "pitch": 0.06769971699925392,
                "fov": 1.1
            }
        },
        {
            "yaw": 0.6005218904760596,
            "pitch": 0.28198167035528243,
            "rotation": 0,
            "target": "office_laptop",
            "category": "portable_speakers",
            "targetCameraView": {
                "yaw": 0.7156167317678879,
                "pitch": 0.19475854052866381,
                "fov": 1
            }
        },
        {
            "yaw": -0.40040068251654404,
            "pitch": 0.39179445550629133,
            "rotation": 0,
            "target": "office_laptop",
            "category": "tone",
            "targetCameraView": {
                "yaw": -0.2874583874405445,
                "pitch": 0.2671446105552544,
                "fov": 1
            }
        },
        {
            "yaw": 2.323634088561594,
            "pitch": 0.12084775023850369,
            "rotation": 0,
            "target": "office_tv",
            "category": "wine_celler",
            "targetCameraView": {
                "yaw": -0.8866332945080764,
                "pitch": 0.056022109152904065,
                "fov": 1.1
            }
        },

    ],
    "infoHotspots": []
};
export default OFFICE_LAPTOP_DATA;
