import {DEFAULT_PATH} from "../../menu";

const HOME_FR_DATA = {
    "id": "family_fr",
    "category":"family",
    "name": "family_fr",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0.06,
        "yaw": 0,
        "fov": 1.2007963267948966
    },
    "linkHotspots": [
        {
            "yaw": 3.003838664624201,
            "pitch": 0.006113420361096189,
            "rotation": 0,
            "target": "family_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.family.oled,
            "targetCameraView": {
                "yaw": 0.21943838760407708,
                "pitch": 0.03399234334229462,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.06983403346835004,
                "pitch": 0.15003453702875873,
                "fov": 1
            }
        },
        {
            "yaw": 0.089659159634504,
            "pitch": 0.13303056823063386,
            "rotation": 0,
            "target": "family_fr",
            "category": "fridge",
            "pathname": "/family/fridge/instaview/GSXV91BSAE",
            "targetCameraView": {
                "yaw": 0.2775142281923735,
                "pitch": 0.09072746761459882,
                "fov": 1.19
            },
            "targetCameraView2": {
                "yaw": -0.03908154865812463,
                "pitch": 0.23186754865708771,
                "fov": 1.19
            }
        },
        {
            "yaw": 1.3272111956852086,
            "pitch": 0.35004481677344046,
            "rotation": 0,
            "target": "family_fr",
            "category": "portable_speakers",
            "pathname": "/family/portable_speakers/portable_speaker/PL2",
            "targetCameraView": {
                "yaw": 1.5304542370463459,
                "pitch": 0.319398422071254,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 1.3495343183527826,
                "pitch": 0.2815625977974108,
                "fov": 1
            }
        },
        {
            "yaw": -0.5387332506390354,
            "pitch": 0.35004481677344046,
            "rotation": 0,
            "arrow":true,
            "target": "family_wash",
            "category": "wm",
            "pathname": "/family/wm/turbowash360/F4V910BTSE",
            "targetCameraView": {
                "yaw": -0.03368443785206132,
                "pitch": 0.19537867711309964,
                "fov": 1.18
            },
            "targetCameraView2": {
                "yaw": -0.3643937903471137,
                "pitch": 0.33765161859865458,
                "fov": 1.18
            }
        }
    ],
    "infoHotspots": [
        {
            "yaw": -0.38627705634229703,
            "pitch": 0.513704584812281,
            "category": "fridge",
            "size": {w: 357, h: 1018},
            "defaultModelName":"GBB92MCBAP",
            "images": [
                {url: "products/update_20210427/home_product/freezer/combi/GBB92MCBAP.png", modelName: "GBB92MCBAP"},
            ],
            "radius": 1430,
            "extraTransforms": "rotateX(-29.43310462626395deg) rotateY(-22.017453492124567deg)",
            "extraName": "combi"
        },
        {
            "yaw": -0.12786009200096515,
            "pitch": 0.5439559668846421,
            "category": "fridge",
            "size": {w: 480, h: 1017},
            "defaultModelName": "LSR100",
            "images": [
                {url: "products/update_20210427/home_product/freezer/insta/LSR100.png", modelName: "LSR100" },
            ],
            "radius": 1355,
            "extraTransforms": "rotateX(-31.166381143447964deg) rotateY(-7.325843639809721deg)",
            "extraName": "instaview"
        },
        {
            "yaw": 1.191779188806592,
            "pitch": 0.424589045514832,
            "category": "portable_speakers",
            "size": {w: 339, h: 257},
            "images": [
                {url: "products/home_product/speaker/pl2.png", modelName: "pl2"},
            ],
            "radius": 1255,
            "extraTransforms": "rotateX(-24.32716033548789deg) rotateY(-20deg) rotateZ(1deg)"
        }
    ]
};

export default HOME_FR_DATA;
