import FAMILY_DATA from "./family";
import OFFICE_DATA from "./office";
import STUDIO_DATA from "./studio";
import IDLE_DATA from "./idle";

const SCENES = [
    IDLE_DATA,
    ...FAMILY_DATA,
    ...OFFICE_DATA,
    ...STUDIO_DATA
];

export default SCENES;

