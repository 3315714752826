import {DEFAULT_PATH} from "../../menu";

const HOME_TV_DATA = {
    "id": "family_tv",
    "category":"family",
    "name": "family_tv",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.2007963267948966
    },
    "linkHotspots": [
        {
            "yaw": 3.0429370223396166,
            "pitch": 0.008281602961575985,
            "rotation": 0,
            "target": "family_fr",
            "category": "fridge",
            "pathname": "/family/fridge/instaview/GSXV91BSAE",
            "targetCameraView": {
                "yaw": 0.2775142281923735,
                "pitch": 0.09072746761459882,
                "fov": 1.19
            },
            "targetCameraView2": {
                "yaw": -0.03908154865812463,
                "pitch": 0.23186754865708771,
                "fov": 1.19
            }
        },
        {
            "yaw": -0.0972898110656395,
            "pitch": -0.0730783582310206,
            "rotation": 0,
            "target": "family_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.family.oled,
            "targetCameraView": {
                "yaw": 0.10170163433558876,
                "pitch": -0.012730057228903746,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.06983403346835004,
                "pitch": 0.15003453702875873,
                "fov": 1
            }
        },
        {
            "yaw": -0.10113189977849046,
            "pitch": 0.09996591396404853,
            "rotation": 0,
            "target": "family_tv",
            "category": "av",
            "pathname": "/family/av/soundbar/SP11RA",
            "targetCameraView": {
                "yaw": 0.10170163433558876,
                "pitch": -0.012730057228903746,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.06983403346835004,
                "pitch": 0.15003453702875873,
                "fov": 1
            }
        },
        {
            "yaw": -0.44556180275365875,
            "pitch": 0.5500713212814361,
            "rotation": 0,
            "target": "family_tv",
            "category": "laptop",
            "pathname": "/family/laptop/laptop/17Z90Q-K.AA78A1",
            "targetCameraView": {
                "yaw": -0.06898087622193572,
                "pitch": 0.5375625648598241,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.33753595976116735,
                "pitch": 0.5796172306151,
                "fov": 1
            }
        },
    ],
    "infoHotspots": [
        {
            "yaw": -0.3960511451342515,
            "pitch": 0.1081467759109167,
            "category": "tvs",
            "size": {w: 738, h: 441},
            "defaultModelName": "OLED83C14LA",
            "images": [
                {url: "products/update_20230406/home_product/TV/OLED/OLED77Z39LA.png", modelName: "OLED77Z39LA"},
            ],
            "radius": 1253,
            "extraTransforms": "rotateX(-6.0817622686164414deg) rotateY(-22.772273178834155deg)"
        },
        {
            "yaw": -0.3708609447432032,
            "pitch": 0.24148304738623594,
            "category": "av",
            "size": {w: 828, h: 238},
            "defaultModelName":"SP11RA",
            "images": [
                {url: "products/update_20210427/home_product/soundbar/sp11ra.png", modelName: "SP11RA"},
            ],
            "radius": 1270,
            "radiusApple":1252,
            "extraTransforms": "rotateX(-13.835959439188985deg) rotateY(-21.24876692001998deg)"
        },
        {
            "yaw": -0.691726437442675,
            "pitch": 0.6660437099557204,
            "category": "laptop",
            "size": {w: 733, h: 464},
            "defaultModelName":"17Z90Q-K.AA78A1",
            "images": [
                {url: "products/update_20220531/home_product/Laptop/17Z90Q-K.AA78A1.png", modelName: "17Z90Q-K.AA78A1"},
            ],
            "radius": 1467,
            "extraTransforms": "rotateX(-4deg) rotateY(-34deg) rotateZ(21.3deg)"
        },
        // {
        //     "yaw": -0.691726437442675,
        //     "pitch": 0.6660437099557204,
        //     "category": "mobile",
        //     "size": {w: 733, h: 464},
        //     "images": [
        //         {url: "products/home_product/mobile/k61.png", modelName: "LMQ630EAW", h:464},
        //         {url: "products/home_product/mobile/k41.png", modelName: "LMK410EMW", h:464},
        //         {url: "products/home_product/mobile/k51.png", modelName: "LMK510EMW", h:464},
        //         {url: "products/home_product/mobile/v50.png", modelName: "LMV500EM", h:464},
        //     ],
        //     "radius": 1467,
        //     "extraTransforms": "rotateX(-4deg) rotateY(-34deg) rotateZ(21.3deg)"
        // }
    ],
    "defaultTV":{
        "modelName": "none",
        "width": 560,
        "height": 316,
        "offsetX": 98,
        "offsetY": 64,
        "url":"videos/home_tv.mp4"
    }
};

export default HOME_TV_DATA;
