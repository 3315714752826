import React from 'react';
import styled from "styled-components";
import {ROOT_PATH_PREFIX} from "../../../const";

const Title = (props) => {
    return (
        <Wrapper {...props}>
            <img className="icon" src={`${ROOT_PATH_PREFIX}/icons/icon_cube.svg`} alt=""/>
            <span className="txt-0">LG</span>
            <span className="txt-1">VIRTUAL SHOWROOM</span>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  height: 100%;
  display: inline-flex;
  align-items: center;
  font-size: 2.1rem;
  position: relative;

  .icon {
    height: 50%;
    margin-right: 2rem;
  }

  .txt-1 {
    font-weight: 600;
    letter-spacing: -0.02rem;
    white-space: nowrap;
  }

  .txt-0 {
    margin-right: 1rem;
    font-weight: 700;
    letter-spacing: 0.22rem;
    white-space: nowrap;
  }

  //@media screen and (max-width: 747px), screen and (max-height: 414px) {
  //  height: 80%;
  //  top: 10%;
  //}
`

export default Title;
