import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useUspStore from "../../stores/uspStore";
import styled from "styled-components";
import useHistoryStore from "../../stores/historyStore";
import {animated, useSpring} from "react-spring";
import {easeCubicOut} from "d3-ease";
import {BiZoomIn, BiZoomOut} from "react-icons/bi";
import * as PRODUCTS_API from "../../api/products";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../const";


// const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

//팝업창이 스크린을 벗어나지 않게 위치 수정
function modifyPositionToInsideScreen(el) {
    let targetTop = el.offsetTop;
    let targetLeft = el.offsetLeft;

    if (targetLeft < 5) targetLeft = 5;
    if (targetTop < 65) targetTop = 65;
    if (targetLeft > window.innerWidth - el.offsetWidth - 5)
        targetLeft = window.innerWidth - el.offsetWidth - 5;
    if (targetTop > window.innerHeight - el.offsetHeight - 5)
        targetTop = window.innerHeight - el.offsetHeight - 5;

    el.style.top = targetTop + "px";
    el.style.left = targetLeft + "px";
}

function tryPlay(el) {
    if(!el) return;
    const playPromise = el.play();
    if (playPromise !== undefined) {
        playPromise.then(_ => {
            //재생성공
        }).catch(err => {
            // showUI();
        })
    }
}

const UspVideoView = () => {

    const target = useRef();
    const videoRef = useRef();
    const loadingRef = useRef();

    const [isDragging, setIsDragging] = useState(false);    //상단바 드래그하는 동안 iframe 포커스 막기 위함 (드래그가 iframe 위에서 막히는 현상 수정용)
    const [zoom, setZoom] = useState(0);    //팝업창 줌 레벨

    const vrUrl = useUspStore(state => state.vrUrl);
    const arUrl = useUspStore(state => state.arUrl);
    const usp = useHistoryStore(state => state.usp);
    const newVrUrl = useMemo(()=>{
        if(vrUrl?.startsWith('/', 0)){
            return `${ROOT_PATH_PREFIX}${vrUrl}`;
        }else{
            return vrUrl;
        }
    }, [vrUrl]);

    const newArUrl = useMemo(()=>{
        if(arUrl?.startsWith('/', 0)){
            return `${ROOT_PATH_PREFIX}${arUrl}`;
        }else{
            return arUrl;
        }
    }, [arUrl]);


    const [spring, animate] = useSpring(() => ({
        from: {opacity: 0, left: 0, top: 0},
        delay: 500
    }));

    const [hoveredTitle, setHoveredTitle] = useState(false);
    const spring2 = useSpring({
        background: hoveredTitle ? '#eee' : 'white',
        config: {duration: 250, easing: easeCubicOut}
    });
    const [title, setTitle] = useState('KEY FEATURE');

    const resizeHandler = useCallback(() => {
        const screenW = window.innerWidth;
        let screenH = window.innerHeight;
        if (!target.current) return;

        const containerW = target.current.offsetWidth;
        const containerH = target.current.offsetHeight;

        let left = 80;
        let top = (screenH - containerH) * 0.5;

        if (screenW <= 747 || screenH <= 414) {
            //가로모드
            if (screenW > screenH) {
                left = 15;
                top = (screenH - containerH) * 0.5 + 15;
            } else {
                // screenH = Math.min(600, window.innerHeight);
                left = (screenW - containerW) * 0.5 - 9;
                top = (screenH - containerH - 300);
            }
        } else if (screenW < 1280) {
            left = 64;
        }

        target.current.style.left = `${left}px`;
        target.current.style.top = `${top}px`;

    }, []);

    const dragElement = useCallback((el) => {
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(el.id + "Header")) {
            /* if present, the header is where you move the DIV from:*/
            document.getElementById(el.id + "Header").onmousedown = dragMouseDown;
        } else {
            /* otherwise, move the DIV from anywhere inside the DIV:*/
            el.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
            setIsDragging(true);
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            let targetTop = (el.offsetTop - pos2);
            let targetLeft = (el.offsetLeft - pos1);
            el.style.top = targetTop + "px";
            el.style.left = targetLeft + "px";

            modifyPositionToInsideScreen(el);
        }

        function closeDragElement(e) {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
            setIsDragging(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        dragElement(target.current);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        }
    }, []);

    useEffect( ()=>{
        async function action() {
            if(usp){
                if(!IS_MOBILE){
                    setZoom(0);
                    target.current.style.width = '50rem';
                    target.current.style.height = '31rem';
                    modifyPositionToInsideScreen(target.current);
                }
                if(usp === 'key_feature'){
                    let isMute = false;
                    let uspVideoUrl = useUspStore.getState().uspVideoUrl;
                    if(!uspVideoUrl){
                        const productGroupCategory = useHistoryStore.getState().productGroupCategory;
                        const modelName = useHistoryStore.getState().modelName;
                        const product = await PRODUCTS_API.getProduct(productGroupCategory, modelName);
                        if(product){
                            uspVideoUrl = product.uspVideoUrl;
                        }
                        isMute = true;
                    }
                    setTitle('KEY FEATURE');
                    loadingRef.current.style.display = 'block';

                    if(videoRef.current){
                        videoRef.current.style.display = 'block';

                        if(uspVideoUrl.startsWith('/', 0)){
                            videoRef.current.src = ROOT_PATH_PREFIX + uspVideoUrl;
                        }else{
                            videoRef.current.src = uspVideoUrl;
                        }
                        videoRef.current.volume = 0.5;
                        videoRef.current.muted = isMute;
                        tryPlay(videoRef.current);
                    }
                }else{
                    videoRef.current.pause();
                    videoRef.current.style.display = 'none';
                    loadingRef.current.style.display = 'none';

                    if(usp === 'vr'){
                        setTitle('VR');
                    }else if(usp === 'ar'){
                        setTitle('AR');
                    }
                }

                let isShow = true;
                if((usp === 'vr' || usp === 'ar') && IS_MOBILE) isShow = false;

                if(isShow){
                    animate({cancel:true});
                    target.current.style.display = 'block';
                    animate({cancel:false, opacity:1, delay:100});
                    resizeHandler();
                }
            }else{
                videoRef.current.pause();
                target.current.style.display = 'none';
                animate({cancel: true});
                animate({cancel:false, opacity:0, delay:0, onRest: result => {
                        target.current.style.display = 'none';
                    }});
            }
        }
        action();

    }, [usp]);

    useEffect(() => {
        if(!IS_MOBILE){
            target.current.style.width = `${50 * (1 + zoom * 0.3)}rem`;
            target.current.style.height = `${31 * (1 + zoom * 0.3)}rem`;
            modifyPositionToInsideScreen(target.current);
            // resizeHandler();
        }
    }, [zoom]);


    return (
        <>
            <UspPopupStyle ref={target} style={{opacity: spring.opacity}} id="uspPopup">
                <>
                    <animated.div className="title" style={{background: spring2.background}}>
                        {title}
                    </animated.div>

                    <div id="uspPopupHeader" onMouseOver={() => setHoveredTitle(true)}
                         onMouseOut={() => setHoveredTitle(false)}>
                        <div className="btnGroup">
                            {!IS_MOBILE && (
                                <>
                                    <button
                                        className={`btn zoomInBtn ${zoom >= 2 ? 'btn_disabled' : 'btn_enabled'}`}
                                        onMouseOver={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onClick={() => {
                                            if (zoom < 2) setZoom(prevState => prevState + 1);
                                        }}
                                    >
                                        <BiZoomIn/>
                                    </button>
                                    <button
                                        className={`btn zoomOutBtn ${zoom <= 0 ? 'btn_disabled' : 'btn_enabled'}`}
                                        onMouseOver={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onClick={() => {
                                            if (zoom > 0) setZoom(prevState => prevState - 1);
                                        }}
                                    >
                                        <BiZoomOut/>
                                    </button>
                                </>
                            )}
                            <button
                                className="btn closeBtn"
                                id="usp-panel-close-button"
                                onMouseOver={(e) => {
                                    e.stopPropagation();
                                }}
                                onClick={() => {
                                    useHistoryStore.getState().hideUSP();
                                }}
                            >
                                <span>+</span>
                            </button>
                        </div>
                    </div>

                    <div className="container" style={{pointerEvents: isDragging ? 'none' : 'visible'}}>
                        <img src={`${ROOT_PATH_PREFIX}/img/loading.gif`} alt='' className="loading" ref={loadingRef}/>
                        <video
                            className="introVideo"
                            crossOrigin="anonymous"
                            playsInline
                            controls
                            ref={videoRef}
                        />

                        {(newVrUrl || newArUrl) && (
                            <iframe src={newVrUrl ?? newArUrl}></iframe>
                        )}
                    </div>
                </>
            </UspPopupStyle>
        </>
    );
};

//region Styles
const UspPopupStyle = styled(animated.div)`

  width: 50rem;
  height: 31rem;
  position: absolute;
  //left: 8rem;
  //top: 25rem;
  z-index: 999999;
  filter: drop-shadow(0 0 0.75rem rgba(100, 100, 100, 0.7));
  opacity: 0;
  display: flex;
  flex-direction: column;
  

  #uspPopupHeader {
    position: relative;
    left: 0;
    top: 0;
    min-width: 100%;
    height: 3rem;
    //background: rgba(200, 0, 0, 0.5);
    cursor: move;
  }

  .title {
    position: absolute;
    white-space: nowrap;
    color: black;
    background: white;
    padding: 0.8rem 1.2rem 0;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    //font-size: 1.1rem;
    float: left;
    height: 6rem;
    border-radius: 0.5rem;
    width: 100%;
    text-align: left;
  }

  .container {
    position: absolute;
    left: 0;
    top: 3rem;
    bottom: 0;
    right: 0;
    //width: 100%;
    //height: 100%;
    //height: 28rem;
    border: 2px solid white;
    border-radius: 1rem;
    overflow: hidden;
    isolation: isolate;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: none;
    }

    video {
      width: 100%;
      height: 100%;
      //transform: scale(1.08);
      object-fit: cover;
      z-index: 1;
    }

    .loading {
      position: absolute;
      width: 4rem;
      height: 4rem;
      z-index: 0;
    }
  }

  .btnGroup {
    z-index: 99999;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    //background: #61dafb;
    display: inline-flex;
  }

  .btn {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none !important;
    border: none;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .zoomInBtn {
    background: rgba(255, 255, 255, 0);
    padding: 0 0.8rem;
    color: #999;
    transition: color 100ms ease-out;

    :hover {
      color: #a50034;
    }
  }

  .zoomOutBtn {
    background: rgba(255, 255, 255, 0);
    padding: 0 0.8rem;
    margin-right: 1rem;
    color: #999;
    transition: color 100ms ease-out;

    :hover {
      color: #a50034;
    }
  }
  
  .btn_disabled{
    opacity: 0.25;
    pointer-events: none;
    cursor: auto;
  }
  
  .btn_enabled{
    cursor: pointer;
    opacity: 1;
    pointer-events: visible;
  }

  .closeBtn {
    width: 2.2rem;
    height: 2.2rem;
    padding: 0;
    border-radius: 50%;
    filter: drop-shadow(0 0 0.35rem rgba(100, 100, 100, 0.7));
    color: black;

    span {
      transform: rotateZ(45deg);
      position: relative;
      left: 1px;
      top: -1px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 747px) and (orientation: portrait), screen and (max-height: 600px) and (orientation: portrait) {
    width: 43rem;
    height: 24rem;
  }

  @media screen and (max-width: 747px) and (orientation: landscape), screen and (max-height: 600px) and (orientation: landscape) {
    width: 43rem;
    height: 24rem;
  }
`;
//endregion

export default UspVideoView;
