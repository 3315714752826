import {DEFAULT_PATH} from "../../menu";

const STUDIO_PROJECTOR_DATA = {
    "id": "studio_projector",
    "category": "studio",
    "name": "studio_projector",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.2
    },
    "linkHotspots": [
        {
            "yaw": 2.981685436765935,
            "pitch": -0.10907825380998837,
            "rotation": 0,
            "target": "studio_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.studio.oled,
            "targetCameraView": {
                "yaw": 0.3562050104448204,
                "pitch": 0.01538524537931089,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.21538524537931089,
                "fov": 1
            }
        },
        {
            "yaw": -2.185841095936736,
            "pitch": -0.14859220010145435,
            "rotation": 0,
            "target": "studio_fr",
            "category": "fridge",
            "pathname": "/studio/fridge/instaview/GMX945MC9F",
            "targetCameraView": {
                "yaw": 0.24285583787532872,
                "pitch": 0.05620696066291231,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.25620696066291231,
                "fov": 1
            }
        },
        {
            "yaw": -0.10314208669785252,
            "pitch": 0.143947331159886,
            "rotation": 0,
            "target": "studio_projector",
            "category": "portable_speakers",
            "pathname": "/studio/portable_speakers/portable_speaker/PL7",
            "targetCameraView": {
                "yaw": -0.0322143176234313,
                "pitch": 0.07620233814263244,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.30276122450648373,
                "pitch": 0.07620233814263244,
                "fov": 1
            }
        },
        {
            "yaw": -0.3154186369245551,
            "pitch": 0.16954999279478322,
            "rotation": 0,
            "target": "studio_projector",
            "category": "it",
            "pathname": "/studio/it/beam_projector/PH30N",
            "targetCameraView": {
                "yaw": -0.10276122450648373,
                "pitch": 0.07620233814263244,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.30276122450648373,
                "pitch": 0.07620233814263244,
                "fov": 1
            }
        },
        {
            "yaw": 2.1906328862855666,
            "pitch": 0.18275790592297092,
            "rotation": 0,
            "arrow":true,
            "target": "studio_toilet",
            "category": "wm",
            "pathname": "/studio/wm/turbowash360/F6V1010BTSE",
            "targetCameraView": {
                "yaw": 0.02704449235143791,
                "pitch": 0.06191195100668523,
                "fov": 1.45
            },
            "targetCameraView2": {
                "yaw": -0.5448200723351064,
                "pitch": 0.4816234939238875,
                "fov": 1.45
            }
        },
        {
            "yaw": 1.583891513602996,
            "pitch": -0.4815704952369835,
            "rotation": 0,
            "target": "studio_air",
            "category": "air",
            "pathname": "/studio/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": 0.07912567634454959,
                "pitch": -0.0027260613376149934,
                "fov": 1.2
            },
            "targetCameraView2": {
                "yaw": -0.21,
                "pitch": -0.11,
                "fov": 1
            },
        },
    ],
    "infoHotspots": [
        {
            "yaw": -0.23532696126755626,
            "pitch": 0.19197790207332922,
            "category": "portable_speakers",
            "size": {w: 408, h: 277},
            "images": [
                {url: "products/studio_product/speaker/pl7.png", modelName: "PL7", h:277},
            ],
            "radius": 1630,
            "extraTransforms": "rotateX(-11.415926768552108deg) rotateY(-13.648891226491353deg)"
        },
        {
            "yaw":   -0.5956805522921655,
            "pitch":   0.17173850240452992,
            "category": "it",
            "size": {w: 840, h: 456},
            "images": [
                {url: "products/studio_product/projector/PH30n.png", modelName: "PH30N", h:456},
            ],
            "radius": 2000,
            "extraTransforms": "rotateX(-11deg) rotateY(-28deg)"
        }
    ]
};
export default STUDIO_PROJECTOR_DATA;
