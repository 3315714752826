export const DEFAULT_PATH = {
    tv:{
        family: {
            oled: "/family/tvs/oled/OLED77Z39LA",
            qned: "/family/tvs/qned/86QNED816RE",
            nanocell: "/family/tvs/nanocell/86NANO766QA",
            uhd: "/family/tvs/uhd_tv/75UR91006LA"
        },
        studio: {
            oled: "/studio/tvs/oled/OLED77Z39LA",
            qned: "/studio/tvs/qned/86QNED816RE",
            nanocell: "/studio/tvs/nanocell/86NANO766QA",
            uhd: "/studio/tvs/uhd_tv/75UR91006LA"
        },
        office: {
            oled: "/office/tvs/oled/OLED77Z39LA",
            qned: "/office/tvs/qned/86QNED816RE",
            nanocell: "/office/tvs/nanocell/86NANO766QA",
            uhd: "/office/tvs/uhd_tv/75UR91006LA"
        },
    },
    soundbar: {
        family: "/family/av/soundbar/SP11RA",
        studio: "/studio/av/soundbar/SP11RA",
        office: "/office/av/soundbar/SP11RA"
    }
}


export const MENU_DATA = [
    {
        id: "home_main",
        name: "Family",
        category: "family",
        pathname: "/family",
        subMenu: [
            {
                name: "TV >", category: "tvs", pathname: DEFAULT_PATH.tv.family.oled,
                subMenu2: [
                    {name: "OLED", category: "tvs", pathname: DEFAULT_PATH.tv.family.oled},
                    {name: "QNED", category: "tvs", pathname: DEFAULT_PATH.tv.family.qned},
                    {name: "NanoCell", category: "tvs", pathname: DEFAULT_PATH.tv.family.nanocell},
                    {name: "UHD", category: "tvs", pathname: DEFAULT_PATH.tv.family.uhd},
                ]
            },
            {
                name: "SOUNDBAR >", category: "av", pathname: DEFAULT_PATH.soundbar.family,
                subMenu2: [
                    {name: "SP11RA", category: "av", pathname: "/family/av/soundbar/SP11RA"},
                    {name: "SP9YA", category: "av", pathname: "/family/av/soundbar/SP9YA"},
                    {name: "SP8YA", category: "av", pathname: "/family/av/soundbar/SP8YA"},
                ]
            },
            {
                name: "LAPTOP >", category: "laptop", pathname: "/family/laptop/laptop/17Z90Q-K.AA78A1",
                subMenu2: [
                    {name: "LG gram", category: "laptop", pathname: "/family/laptop/laptop/17Z90Q-K.AA78A1"},
                ]
            },
            {name: "REFRIGERATOR >", category: "fridge", pathname: "/family/fridge/instaview/GSXV91BSAE"},
            {
                name: "PORTABLE SPEAKER >",
                category: "portable_speakers",
                pathname: "/family/portable_speakers/portable_speaker/PL2"
            },
            {name: "WASHER & DRYER >", category: "wm", pathname: "/family/wm/turbowash360/F4V910BTSE"},
            {name: "STYLER >", category: "styler", pathname: "/family/styler/styler/S3WF"}
        ]
    },
    {
        id: "studio_main",
        name: "Studio",
        category: "studio",
        pathname: "/studio",
        subMenu: [
            {
                name: "TV >", category: "tvs", pathname: DEFAULT_PATH.tv.studio.oled,
                subMenu2: [
                    {name: "OLED", category: "tvs", pathname: DEFAULT_PATH.tv.studio.oled},
                    {name: "QNED", category: "tvs", pathname: DEFAULT_PATH.tv.studio.qned},
                    {name: "NanoCell", category: "tvs", pathname: DEFAULT_PATH.tv.studio.nanocell},
                    {name: "UHD", category: "tvs", pathname: DEFAULT_PATH.tv.studio.uhd},
                ]
            },
            {
                name: "SOUNDBAR >", category: "av", pathname: DEFAULT_PATH.soundbar.studio,
                subMenu2: [
                    {name: "SP11RA", category: "av", pathname: "/studio/av/soundbar/SP11RA"},
                    {name: "SP9YA", category: "av", pathname: "/studio/av/soundbar/SP9YA"},
                    {name: "SP8YA", category: "av", pathname: "/studio/av/soundbar/SP8YA"},
                ]
            },
            {name: "REFRIGERATOR >", category: "fridge", pathname: "/studio/fridge/instaview/GMX945MC9F"},
            {
                name: "PORTABLE SPEAKER >",
                category: "portable_speakers",
                pathname: "/studio/portable_speakers/portable_speaker/PL7"
            },
            {name: "PROJECTOR >", category: "it", pathname: "/studio/it/beam_projector/PH30N"},
            {name: "AIR CONDITIONER >", category: "air", pathname: "/studio/air/air_conditioner/DC12RH"},
            {name: "WASHER & DRYER >", category: "wm", pathname: "/studio/wm/turbowash360/F6V1010BTSE"},
            {name: "STYLER >", category: "styler", pathname: "/studio/styler/styler/S3WF"}
        ]
    },
    {
        id: "office_main",
        name: "Home office",
        category: "office",
        pathname: "/office",
        subMenu: [
            {
                name: "TV >", category: "tvs", "pathname": DEFAULT_PATH.tv.office.oled,
                subMenu2: [
                    {name: "OLED", category: "tvs", pathname: DEFAULT_PATH.tv.office.oled},
                    {name: "QNED", category: "tvs", pathname: DEFAULT_PATH.tv.office.qned},
                    {name: "NanoCell", category: "tvs", pathname: DEFAULT_PATH.tv.office.nanocell},
                    {name: "UHD", category: "tvs", pathname: DEFAULT_PATH.tv.office.uhd},
                ]
            },
            {
                name: "SOUNDBAR >", category: "av", "pathname": DEFAULT_PATH.soundbar.office,
                subMenu2: [
                    {name: "SP11RA", category: "av", pathname: "/office/av/soundbar/SP11RA"},
                    {name: "SP9YA", category: "av", pathname: "/office/av/soundbar/SP9YA"},
                    {name: "SP8YA", category: "av", pathname: "/office/av/soundbar/SP8YA"},
                ]
            },
            {name: "MONITOR >", category: "monitor", "pathname": "/office/monitor/ultrawide/38WN95C-W"},
            {name: "LAPTOP >", category: "laptop", "pathname": "/office/laptop/laptop/16Z90P-K.AA72A1"},
            {name: "EARBUDS >", category: "tone", "pathname": "/office/tone/earbuds/TONE-UFP9"},
            {
                name: "PORTABLE SPEAKER >",
                category: "portable_speakers",
                "pathname": "/office/portable_speakers/portable_speaker/PN5"
            },
            {name: "WINE CELLER >", category: "wine_celler", "pathname": "/office/wine_celler/wine_celler/LSR200W"},
            {name: "AIR CONDITIONER >", category: "air", "pathname": "/office/air/air_conditioner/DC12RH"},
        ]
    }
];


export const MAP_DATA = [
    {
        "category": "family",
        "bgUrl": "/img/minimap/minimap_home.png",
        "spots": [
            {
                "x": 0.4,
                "y": 5.5,
                "target": "home_tv",
                "categories": ["tvs", "av"],
                "pathname": DEFAULT_PATH.tv.family.oled,
                "label": 'TV'
            },
            {
                "x": 4.7,
                "y": 3.7,
                "target": "home_tv",
                "categories": ["laptop", "mobile"],
                "pathname": '/family/laptop/laptop/14Z90P-K.AA53A1',
                "label": 'LAPTOP'
            },
            {
                "x": 16.4,
                "y": 4.16,
                "target": "home_fr",
                "categories": ["portable_speakers"],
                "pathname": '/family/portable_speakers/portable_speaker/PL2',
                "label": 'PORTABLE SPEAKER'
            },
            {
                "x": 18.8,
                "y": 3.2,
                "target": "home_fr",
                "categories": ["fridge", "combi"],
                "pathname": '/family/fridge/instaview/GSXV91BSAE',
                "label": 'REFRIGERATOR'
            },
            {
                "x": 21.2,
                "y": 6,
                "target": "home_wash",
                "categories": ["wm", "washer_dryers", "styler"],
                "pathname": '/family/wm/turbowash360/F4V910BTSE',
                "label": 'WASHER & DRYER'
            },
        ]
    },
    {
        "category": "studio",
        "bgUrl": "/img/minimap/minimap_studio.png",
        "spots": [
            {
                "x": 1.1,
                "y": 12.7,
                "target": "studio_projector",
                "categories": ["it", "portable_speakers"],
                "pathname": "/studio/it/beam_projector/PH30N",
                "label": 'PROJECTOR'
            },
            {
                "x": 1.1,
                "y": 7.2,
                "target": "studio_projector",
                "categories": ["air"],
                "pathname": "/studio/air/air_conditioner/DC12RH",
                "label": 'AIR CONDITIONER'
            },
            {
                "x": 7.8,
                "y": 0.5,
                "target": "studio_toilet",
                "categories": ["wm", "styler", "washer_dryers"],
                "pathname": "/studio/wm/turbowash360/F6V1010BTSE",
                "label": 'WASHER & DRYER'
            },
            {
                "x": 8.8,
                "y": 7,
                "target": "studio_tv",
                "categories": ["tvs", "av"],
                "pathname": DEFAULT_PATH.tv.studio.oled,
                "label": 'TV'
            },
            {
                "x": 13.4,
                "y": 13.4,
                "target": "studio_fr",
                "categories": ["fridge"],
                "pathname": "/studio/fridge/instaview/GMX945MC9F",
                "label": 'REFRIGERATOR'
            },
        ]
    },
    {
        "category": "office",
        "bgUrl": "/img/minimap/minimap_office.png",
        "spots": [
            {
                "x": 4.8,
                "y": 0.4,
                "target": "office_monitor",
                "categories": ["monitor", "laptop", "portable_speakers", "tone"],
                "pathname": "/office/monitor/ultrawide/38WN95C-W",
                "label": "MONITOR"
            },
            {
                "x": 4.1, "y": 11.8, "target": "office_tv", "categories": ["tvs", "av", "air"],
                "pathname": DEFAULT_PATH.tv.office.oled,
                "label": "TV"
            },
            {
                "x": 13, "y": 11.5, "target": "office_tv", "categories": ["wine_celler"],
                "pathname": "/office/wine_celler/wine_celler/LSR200W",
                "label": "WINE CELLER"
            },
        ]
    }
];

export function getMapData(sceneCategory) {
    if (sceneCategory === null) return null;
    let result = null;
    for (let i = 0; i < MAP_DATA.length; i++) {
        if (MAP_DATA[i].category === sceneCategory) {
            result = MAP_DATA[i];
            break;
        }
    }
    return result;
}
