import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";
import IconFamilySVG from "../svg/IconFamilySVG";
import IconStudioSVG from "../svg/IconStudioSVG";
import IconOfficeSVG from "../svg/IconOfficeSVG";
import useHistoryStore from "../../../stores/historyStore";
import SubMenuButtonGroup from "./SubMenuButtonGroup";
import menuStore from "../../../stores/menuStore";
import useMenuStore from "../../../stores/menuStore";

const MenuButton = ({data, selected, ...props}) => {
    const {id, name, category, pathname} = data;

    const subMenuRef = useRef();
    const hoveredMenuCategory = useMenuStore(state => state.hoveredMenuCategory);
    const prevHoveredMenuCategory = useMenuStore(state => state.prevHoveredMenuCategory);
    const isTweening = useMenuStore(state => state.isTweening);

    const hovered = useMemo(() => hoveredMenuCategory === category, [hoveredMenuCategory]);
    const prevHovered = useMemo(() => prevHoveredMenuCategory === category, [prevHoveredMenuCategory]);

    const active = useMemo(() => {
        if (hoveredMenuCategory) return hovered;
        else return selected;
    }, [hoveredMenuCategory, hovered, selected]);

    const sData1 = useMemo(() => data.subMenu.filter(value => value.subMenu2 !== undefined), [data]);
    const sData2 = useMemo(() => data.subMenu.filter(value => value.subMenu2 === undefined), [data]);
    const pathname2 = useHistoryStore(state => state.pathname);
    const [newPath, setNewPath] = useState(`?pathname=${pathname}`);

    useEffect(() => {
        subMenuRef.current.style.visibility = hovered || (prevHovered && isTweening) ? 'visible' : 'hidden';
        setNewPath(useHistoryStore.getState().getNewPath(`?pathname=${pathname}`));
    }, [pathname2]);



    return (
        <MainButtonStyle {...props}
                         selected={active}
                         onMouseOver={(e) => {
                             e.stopPropagation();
                             menuStore.getState().setHover(category);
                         }}
                         onMouseOut={(e) => {
                             e.stopPropagation();   //중요!
                         }}
        >
            <Link to={newPath}>
                <div className="container">
                    {pathname === '/family' &&
                        <IconFamilySVG className="icon" color={active ? '#a50034' : '#000'}/>}
                    {pathname === '/studio' &&
                        <IconStudioSVG className="icon" color={active ? '#a50034' : '#000'}/>}
                    {pathname === '/office' &&
                        <IconOfficeSVG className="icon" color={active ? '#a50034' : '#000'}/>}
                    <span className="txt">{name.toUpperCase()}</span>
                    <div className="bottom-line" style={{visibility: active ? 'visible' : 'hidden'}}/>
                </div>
            </Link>

            <SubMenuStyle style={{
                visibility: hovered || (prevHovered && isTweening) ? 'visible' : 'hidden',
                left: category === 'office' ? '-14.5rem' : '0'
            }} ref={subMenuRef}>
                {sData1 && sData1.length > 0 && (
                    <li>
                        <SubMenuButtonGroup subMenuData={sData1}/>
                    </li>
                )}
                {sData2 && sData2.length > 0 && (
                    <li>
                        <SubMenuButtonGroup subMenuData={sData2}/>
                    </li>
                )}
            </SubMenuStyle>
        </MainButtonStyle>
    );
}

const SubMenuStyle = styled.ul`
  margin-top: 6rem;
  margin-left: 0;
  padding: 0;
  //background: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  display: inline-flex;
  white-space: nowrap;

  li {
    list-style-type: none;
    padding: 0;
  }

  ul {
    padding: 0;
  }
`

const MainButtonStyle = styled.li.attrs(props => ({
    color: props.selected ? '#a50034' : '#000'
}))`
  list-style-type: none;
  height: 100%;
  //cursor: pointer;
  position: relative;
  padding: 0 2rem;
  pointer-events: visible;

  .container {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: 0.5rem;
    position: relative;
  }

  .icon {
    height: 50%;
  }

  .txt {
    font-weight: 700;
    font-size: 1.4rem;
    color: ${props => props.color};
    white-space: nowrap;
  }

  .bottom-line {
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #a50034;
    left: 0;
  }

  //:hover {
  //  .txt {
  //    color: #dc0247;
  //  }
  //}
`;

export default MenuButton;
