import create from "zustand";
import {GA_Event, GA_Event_Click} from "../api/ga";


function getSceneId(sceneCategory, productGroupCategory) {
    let sceneId;
    if (sceneCategory === 'family') {
        if (!productGroupCategory) {
            sceneId = 'family_main';
        } else {
            if (productGroupCategory === 'tvs' || productGroupCategory === 'av' || productGroupCategory === 'laptop' || productGroupCategory === 'mobile') {
                sceneId = 'family_tv';
            } else if (productGroupCategory === 'fridge' || productGroupCategory === 'portable_speakers') {
                sceneId = 'family_fr';
            } else {
                sceneId = 'family_wash';
            }
        }
    } else if (sceneCategory === 'studio') {
        if (!productGroupCategory) {
            sceneId = 'studio_main';
        } else {
            if (productGroupCategory === 'tvs' || productGroupCategory === 'av') {
                sceneId = 'studio_tv';
            } else if (productGroupCategory === 'fridge') {
                sceneId = 'studio_fr';
            } else if (productGroupCategory === 'it' || productGroupCategory === 'portable_speakers') {
                sceneId = 'studio_projector';
            } else if (productGroupCategory === 'wm' || productGroupCategory === 'washer_dryers' || productGroupCategory === 'styler') {
                sceneId = 'studio_toilet';
            } else if (productGroupCategory === 'air') {
                sceneId = 'studio_air';
            }
        }
    } else if (sceneCategory === 'office') {
        if (!productGroupCategory) {
            sceneId = 'office_main';
        } else {
            if (productGroupCategory === 'tvs' || productGroupCategory === 'av' || productGroupCategory === 'wine_celler') {
                sceneId = 'office_tv';
            } else if (productGroupCategory === 'air') {
                sceneId = 'office_air';
            } else if (productGroupCategory === 'monitor' || productGroupCategory === 'laptop' || productGroupCategory === 'portable_speakers' || productGroupCategory === 'tone') {
                sceneId = 'office_monitor';
            }
        }
    } else {
        sceneId = 'idle';
    }
    return sceneId;
}

const useHistoryStore = create((set, get) => ({
    pathname: undefined,
    prevPathname: undefined,
    isPressBack: false,

    sceneId: undefined,
    sceneCategory: undefined,
    productGroupCategory: undefined,
    productCategory: undefined,
    modelName: undefined,
    usp: undefined,  //key_feature, vr, ar

    prevSceneId: undefined,
    prevSceneCategory: undefined,
    prevProductGroupCategory: undefined,
    prevProductCategory: undefined,
    prevModelName: undefined,

    query: undefined,

    _navigate: undefined,    //react-router-dom의 useNavigate

    navigate: (path) => {
        const nav = get()._navigate;
        const newPath = get().getNewPath(path);
        if (nav) nav(newPath);
    },
    getNewPath: (path) => {
        const q = get().query;
        let newPath =
            (path === '/' || path === '') ? '?' : path;

        let count = 0;
        for (const qKey in q) {
            if (qKey !== 'pathname') {
                if (count === 0) {
                    if (!(path === '/' || path === '')) newPath += '&';
                } else {
                    newPath += '&';
                }
                newPath += `${qKey}=${q[qKey]}`;
                count++;
            }
        }
        return newPath;
    },
    pushHistory: (path) => {
        const temp = get().pathname;
        if (temp !== path) {
            const comp = path.substring(1).split('/');
            const sceneCategory = comp.length > 0 ? comp[0] : undefined;
            const productGroupCategory = comp.length > 1 ? comp[1] : undefined;
            const productCategory = comp.length > 2 ? comp[2] : undefined;
            const modelName = comp.length > 3 ? comp[3] : undefined;
            const usp = comp.length > 4 ? comp[4] : undefined;

            const {
                sceneId: tempSceneId,
                sceneCategory: tempSceneCategory,
                productGroupCategory: tempProductGroupCategory,
                productCategory: tempProductCategory,
                modelName: tempModelName
            } = get();

            GA_Event(path, sceneCategory, productGroupCategory, productCategory, modelName, usp);

            set({
                prevSceneId: tempSceneId,
                sceneId: getSceneId(sceneCategory, productGroupCategory),
                prevSceneCategory: tempSceneCategory,
                sceneCategory : sceneCategory,
                prevProductGroupCategory: tempProductGroupCategory,
                productGroupCategory : productGroupCategory,
                prevProductCategory: tempProductCategory,
                productCategory : productCategory,
                prevModelName: tempModelName,
                modelName: modelName,
                usp: usp,
                pathname: path,
                prevPathname: temp,
                isPressBack: false
            });
        }
    },
    showUSP: (usp) => {
        const {sceneCategory, productGroupCategory, productCategory, modelName, navigate} = get();
        const arr = [];
        if (sceneCategory) arr.push(sceneCategory);
        if (productGroupCategory) arr.push(productGroupCategory);
        if (productCategory) arr.push(productCategory);
        if (modelName) arr.push(modelName);

        if (navigate) {
            navigate(`?pathname=/${arr.join('/')}/${usp}`);
        }
    },
    hideUSP: () => {
        const {sceneCategory, productGroupCategory, productCategory, modelName, navigate} = get();
        const arr = [];
        if (sceneCategory) arr.push(sceneCategory);
        if (productGroupCategory) arr.push(productGroupCategory);
        if (productCategory) arr.push(productCategory);
        if (modelName) arr.push(modelName);

        if (navigate) {
            navigate(`?pathname=/${arr.join('/')}`);
        }
    },
    selectProduct: (modelName) => {
        const {sceneCategory, productGroupCategory, productCategory, navigate} = get();
        const arr = [];
        if (sceneCategory) arr.push(sceneCategory);
        if (productGroupCategory) arr.push(productGroupCategory);
        if (productCategory) arr.push(productCategory);
        if (navigate) {
            navigate(`?pathname=/${arr.join('/')}/${modelName}`);
        }
    },
    clickInfoOrBuy: (eventName) => {
        const {sceneCategory, productGroupCategory, productCategory, modelName, pathname} = get();
        GA_Event_Click(eventName, pathname, sceneCategory, productGroupCategory, productCategory, modelName);
    },
    check: (productGroupCategory) => {
        const {sceneId} = get();
        if (sceneId === 'family_tv') {
            return productGroupCategory === 'tvs' || productGroupCategory === 'av' || productGroupCategory === 'laptop' || productGroupCategory === 'mobile';
        } else if (sceneId === 'family_fr') {
            return productGroupCategory === 'fridge' || productGroupCategory === 'portable_speakers';
        } else if (sceneId === 'family_wash') {
            return productGroupCategory === 'wm' || productGroupCategory === 'washer_dryers' || productGroupCategory === 'styler';
        } else if (sceneId === 'office_air') {
            return productGroupCategory === 'air';
        } else if (sceneId === 'office_monitor') {
            return productGroupCategory === 'laptop' || productGroupCategory === 'monitor' || productGroupCategory === 'portable_speakers';
        } else if (sceneId === 'office_tv') {
            return productGroupCategory === 'tvs' || productGroupCategory === 'av';
        } else if (sceneId === 'studio_air') {
            return productGroupCategory === 'air';
        } else if (sceneId === 'studio_fr') {
            return productGroupCategory === 'fridge';
        } else if (sceneId === 'studio_projector') {
            return productGroupCategory === 'portable_speakers' || productGroupCategory === 'it';
        } else if (sceneId === 'studio_toilet') {
            return productGroupCategory === 'wm' || productGroupCategory === 'washer_dryers' || productGroupCategory === 'styler';
        } else if (sceneId === 'studio_tv') {
            return productGroupCategory === 'tvs' || productGroupCategory === 'av';
        }
    }
}));

export default useHistoryStore;
