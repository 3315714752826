import React, {useState} from 'react';
import styled from "styled-components";
import useHistoryStore from "../../stores/historyStore";
import useUspStore from "../../stores/uspStore";


const CloseButton = React.memo((props) => {
    const [active, setActive] = useState(true);
    const navigate = useHistoryStore(state => state.navigate);

    const onClickHandler = () => {
        setActive(false);
        const {sceneCategory, productGroupCategory} = useHistoryStore.getState();
        useUspStore.getState().hide();
        navigate(`?pathname=/${sceneCategory}/${productGroupCategory}`);
    };

    return (
        <StyledCloseButton
            {...props}
            active={active}
            onClick={onClickHandler}/>
    );
});

const StyledCloseButton = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1.2rem;
  width: 6rem;
  height: 6rem;
  opacity: 0.8;
  cursor: ${props => props.active ? 'pointer' : 'auto'};
  //background: #61dafb;

  :hover {
    opacity: 1;
  }

  :before,
  :after {
    position: absolute;
    right: 1.5rem;
    bottom: 0;
    content: ' ';
    height: 3rem;
    width: 3px;
    background-color: white;
  }

  :before {
    transform: rotate(45deg);
  }

  :after {
    transform: rotate(-45deg);
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {
    left: 11.2rem;
    //bottom: 2rem;
    width: 3rem;
  }
`;

export default CloseButton;
