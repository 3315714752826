import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import useHistoryStore from "../../../stores/historyStore";
import {Link} from "react-router-dom";

export const SubSubMenuButton = ({data, ...rest}) => {

    const [hovered, setHover] = useState(false);
    const sceneCategory = useHistoryStore(state => state.sceneCategory);
    const productGroupCategory = useHistoryStore(state => state.productGroupCategory);
    const productCategory = useHistoryStore(state => state.productCategory);
    const modelName = useHistoryStore(state => state.modelName);

    const selected = useMemo(() => {
        const comp = data.pathname.substring(1).split('/');
        const _sceneCategory = comp.length > 0 ? comp[0] : undefined;
        const _productGroupCategory = comp.length > 1 ? comp[1] : undefined;
        const _productCategory = comp.length > 2 ? comp[2] : undefined;
        const _modelName = comp.length > 3 ? comp[3] : undefined;

        if(data.category === 'av'){
            return _sceneCategory === sceneCategory && _productGroupCategory === productGroupCategory && _productCategory === productCategory && _modelName === modelName;
        }else{
            return _sceneCategory === sceneCategory && _productGroupCategory === productGroupCategory && _productCategory === productCategory;
        }

    }, [productCategory, productGroupCategory, sceneCategory, modelName]);


    const [newPath, setNewPath] = useState(`?pathname=${data.pathname}`);
    useEffect(()=>{
        setNewPath(useHistoryStore.getState().getNewPath(`?pathname=${data.pathname}`));
    }, [productCategory]);

    return (
        <li
            className="subSubMenuBtn"
            {...rest}
            style={{
                color: hovered || selected ? '#a50034' : '#000',
            }}
            onMouseOver={() => setHover(true)}
            onMouseOut={(e) => {
                setHover(false);
            }}
        >
            <Link to={newPath}>
                <div>{data.name}</div>
            </Link>
        </li>
    );
};

const SubSubMenuButtonGroup = ({subMenu2Data}) => {
    return (
        <>
            {subMenu2Data && (
                <Wrapper>
                    {subMenu2Data.map((value, index) => {
                        return <SubSubMenuButton data={value} key={index}/>
                    })}
                </Wrapper>
            )}
        </>
    );
}

const Wrapper = styled.ul`
  padding: 0;
  position: relative;
  
  .subSubMenuBtn {
    width: 100%;
    font-size: 1.3rem;
    font-weight: 600;
    padding: 0;
    list-style-type: none;
    position: relative;
    
    a{
      position: relative;
      
      div{
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0.18rem 0 0.18rem 2rem !important;
      }
    }
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {
    .subSubMenuBtn{
      font-size: 1.7rem;

      a{
        div{
          padding: 0.18rem 0 0.18rem 0 !important;
        }
      }
    }
  }
`;


export default SubSubMenuButtonGroup;
