import {DEFAULT_PATH} from "../../menu";

const OFFICE_MONITOR_DATA = {
    "id": "office_monitor",
    "category": "office",
    "layerId": "office_laptop",
    "name": "office_monitor",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.2
    },
    "linkHotspots": [
        {
            "yaw": 3.0300891931854306,
            "pitch": -0.003109031802239315,
            "rotation": 0,
            "target": "office_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.office.oled,
            "targetCameraView": {
                "yaw": 0.27684357558200645,
                "pitch": 0.06769971699925392,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.16769971699925392,
                "fov": 1.45
            }
        },
        {
            "yaw": -0.5593401059000254,
            "pitch": 0.18733590757939744,
            "rotation": 0,
            "target": "office_monitor",
            "category": "laptop",
            "pathname": "/office/laptop/laptop/16Z90P-K.AA72A1",
            "targetCameraView": {
                "yaw": -0.3773571158212441,
                "pitch": 0.21475084933014976,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": -0.5773571158212441,
                "pitch": 0.21475084933014976,
                "fov": 1.45
            }
        },
        {
            "yaw": 0.009986122287848787,
            "pitch": -0.043312809923303774,
            "rotation": 0,
            "target": "office_monitor",
            "category": "monitor",
            "pathname": "/office/monitor/ultrawide/38WN95C-W",
            "targetCameraView": {
                "yaw": 0.3559575860957267,
                "pitch": 0.01352287731259949,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.11352287731259949,
                "fov": 1.45
            }
        },
        {
            "yaw": 0.6005218904760596,
            "pitch": 0.28198167035528243,
            "rotation": 0,
            "target": "office_monitor",
            "category": "portable_speakers",
            "pathname": "/office/portable_speakers/portable_speaker/PN5",
            "targetCameraView": {
                "yaw": 0.7156167317678879,
                "pitch": 0.19475854052866381,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0.5656167317678879,
                "pitch": 0.23475854052866381,
                "fov": 1
            }
        },
        {
            "yaw": -0.29463653386293487,
            "pitch": 0.34518082165307476,
            "rotation": 0,
            "target": "office_monitor",
            "category": "tone",
            "pathname": "/office/tone/earbuds/TONE-UFP9",
            "targetCameraView": {
                "yaw": -0.2874583874405445,
                "pitch": 0.2671446105552544,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.3374583874405445,
                "pitch": 0.2671446105552544,
                "fov": 1
            }
        },
        {
            "yaw": 2.3328069065823858,
            "pitch": 0.020223950206531782,
            "rotation": 0,
            "target": "office_tv",
            "category": "wine_celler",
            "pathname": "/office/wine_celler/wine_celler/LSR200W",
            "targetCameraView": {
                "yaw": -0.8866332945080764,
                "pitch": 0.056022109152904065,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": -0.9966332945080764,
                "pitch": 0.056022109152904065,
                "fov": 1.1
            }
        },
        {
            "yaw": 2.7168518829234296,
            "pitch": -0.23485749838994074,
            "rotation": 0,
            "target": "office_air",
            "category": "air",
            "pathname": "/office/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": -0.1138640612947625,
                "pitch": 0.028953986207012505,
                "fov": 1.1
            },
        }
    ],
    "infoHotspots": [
        {
            "yaw": -0.767827706687332,
            "pitch": 0.31652148035901395,
            "category": "laptop",
            "size": {w: 733, h: 484},
            "defaultModelName":"16Z90P-K.AA72A1",
            "images": [
                {url: "products/update_20210427/office_product/laptop/16_dark.png", modelName: "16Z90P-K.AA72A1"},
            ],
            "radius": 1680,
            "extraTransforms": "rotateX(-18.020753390778317deg) rotateY(-44.10787854541918deg) rotateZ(0deg)"
        },
        {
            "yaw": -0.3968439069252909,
            "pitch": 0.29571926882339206,
            "category": "monitor",
            "size": {w: 969, h: 659},
            "images": [
                {url: "products/office_product/monitor/38wn95c.png", modelName: "38wn95c", h:659},
            ],
            "radius": 1300,
            "extraTransforms": "rotateX(-16.94346602427499deg) rotateY(-22.74577601118227deg) rotateZ(0deg)"
        },
        {
            "yaw": 0.42021558451465246,
            "pitch": 0.3901637101114481,
            "category": "portable_speakers",
            "size": {w: 576, h: 327},
            "images": [
                {url: "products/office_product/speaker/pn5.png", modelName: "pn5", h:327},
            ],
            "radius": 1350,
            "extraTransforms": "rotateX(-22.3547339085517deg) rotateY(24.076579478312535deg) rotateZ(0deg)"
        }
    ]
};
export default OFFICE_MONITOR_DATA;
