import {DEFAULT_PATH} from "../../menu";

const OFFICE_TV_DATA = {
    "id": "office_air",
    "category":"office",
    "name": "office_air",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1
    },
    "linkHotspots": [
        {
            "yaw": 2.6979128430542243,
            "pitch": 0.1321207060603431,
            "rotation": 0,
            "target": "office_monitor",
            "category": "laptop",
            "pathname": "/office/laptop/laptop/16Z90P-K.AA72A1",
            "targetCameraView": {
                "yaw": -0.3773571158212441,
                "pitch": 0.21475084933014976,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": -0.5773571158212441,
                "pitch": 0.21475084933014976,
                "fov": 1.45
            }
        },
        {
            "yaw": 2.9780673650496947,
            "pitch": 0.013068094373137384,
            "rotation": 0,
            "target": "office_monitor",
            "category": "monitor",
            "pathname": "/office/monitor/ultrawide/38WN95C-W",
            "targetCameraView": {
                "yaw": 0.3559575860957267,
                "pitch": 0.01352287731259949,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.11352287731259949,
                "fov": 1.45
            }
        },
        {
            "yaw": 0.0050191335463960485,
            "pitch": 0.0156007146774364,
            "rotation": 0,
            "target": "office_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.office.oled,
            "targetCameraView": {
                "yaw": 0.27684357558200645,
                "pitch": 0.06769971699925392,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.16769971699925392,
                "fov": 1.45
            }
        },
        {
            "yaw": 0.0050191335463960485,
            "pitch": 0.25809547694334256,
            "rotation": 0,
            "target": "office_tv",
            "category": "av",
            "pathname": "/office/av/soundbar/SP11RA",
            "targetCameraView": {
                "yaw": 0.27684357558200645,
                "pitch": 0.06769971699925392,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.16769971699925392,
                "fov": 1.45
            }
        },
        {
            "yaw": -0.9581685479451494,
            "pitch": 0.033233886785357214,
            "rotation": 0,
            "target": "office_tv",
            "category": "wine_celler",
            "pathname": "/office/wine_celler/wine_celler/LSR200W",
            "targetCameraView": {
                "yaw": -0.8866332945080764,
                "pitch": 0.056022109152904065,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": -0.9966332945080764,
                "pitch": 0.056022109152904065,
                "fov": 1.1
            }
        },
        {
            "yaw": -0.474370865683186,
            "pitch": -0.34480528160478485,
            "rotation": 0,
            "target": "office_air",
            "category": "air",
            "pathname": "/office/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": -0.1138640612947625,
                "pitch": 0.028953986207012505,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": -0.1138640612947625,
                "pitch": 0.028953986207012505,
                "fov": 1.1
            },
        }
    ],
    "infoHotspots": [
        {
            "yaw": -0.6285212498957051,
            "pitch": -0.2281498500230139,
            "category": "air",
            "size": {w: 571, h: 330},
            "images": [
                {url: "products/update_20210427/office_product/air/dc12.png", modelName: "DC12RH" },    //<-- 기본 이미지
            ],
            "radius": 1500,
            "extraTransforms": "rotateX(13deg) rotateY(-36deg)"
        }
    ],
};
export default OFFICE_TV_DATA;
