import React from 'react';
import styled from "styled-components";


const IconArSVG = ({hovered = false, ...rest}) => {
    return (
        <Wrapper id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.67 53.68" hover={hovered}>
            <defs>
                <style>

                </style>
            </defs>
            <g id="Layer_1-2">
                <circle className="cls-4" cx="26.84" cy="26.84" r="26.84"/>
                <g>
                    <path className="cls-2"
                          d="M39.4,21.84c0-7.72-6.96-13.81-14.94-12.34-4.89,.9-8.87,4.75-9.89,9.61-.78,3.69,.07,7.17,1.95,9.89,1.49,2.15,2.34,4.68,2.34,7.3,0,2.75,0,4.06,0,5.83,0,.81,.43,1.55,1.13,1.95,.55,.32,1.26,.72,2.01,1.15,3,1.72,6.69,1.72,9.7,0l2-1.14c.7-.4,1.14-1.15,1.14-1.96v-5.81c0-2.68,.93-5.23,2.43-7.44,1.36-2.01,2.15-4.42,2.15-7.03Z"/>
                    <line className="cls-1" x1="18.85" y1="38" x2="34.82" y2="38"/>
                    <path className="cls-5"
                          d="M26.63,31.53h.41c.45,0,.82,.37,.82,.82v5.64h-2.05v-5.64c0-.45,.37-.82,.82-.82Z"/>
                    <path className="cls-3" d="M29.08,12.95c3.78,.7,5.87,3.82,5.74,6.59"/>
                </g>
            </g>
        </Wrapper>
    );
};

const Wrapper = styled.svg`
  .cls-1, .cls-2, .cls-3 {
    stroke: ${props => props.hover ? '#fff' : '#1d1d1b'};
    stroke-miterlimit: 10;
    stroke-width: 1.5px;
  }

  .cls-1, .cls-3 {
    fill: none;
  }

  .cls-4 {
    fill: ${props => props.hover ? '#be0738' : '#b2b2b2'};
  }

  .cls-2 {
    fill: ${props => props.hover ? '#be0738' : '#fff'};
  }

  .cls-3 {
    stroke-linecap: round;
  }

  .cls-5 {
    fill: ${props => props.hover ? '#fff' : '#1d1d1b'};
  }
`;


export default IconArSVG;