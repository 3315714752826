import {createGlobalStyle} from "styled-components";

export const BG_COLOR = "#F0ECE4";
export const BG_COLOR_ALPHA = "rgba(240, 236, 228, 0.94)";

const GlobalStyle = createGlobalStyle`
  *{
    //background: #F0ECE4;
  }
`;

export default GlobalStyle;
