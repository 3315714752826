import React, {useState} from 'react';
import useHistoryStore from "../../stores/historyStore";
import IconUspSVG from "./svg/IconUspSVG";
import IconVrSVG from "./svg/IconVrSVG";
import IconArSVG from "./svg/IconArSVG";

const USPButtons = ({product}) => {
    const [hoveredAR, setHoverAR] = useState(false);
    const [hoveredVR, setHoverVR] = useState(false);
    const [hoveredUSP, setHoverUSP] = useState(false);

    if(!product){
        return <></>;
    }

    return (
        <div className="topUIGroup">
            {product.new ? <div className="newBadge">NEW</div> : <div></div>}
            <div className="btnGroup">
                {product.uspVideoUrl && (
                    <button id="usp-button"
                            className="arBtn uspBtn"
                            onMouseOver={() => setHoverUSP(true)}
                            onMouseOut={() => setHoverUSP(false)}
                            onClick={() => {
                                useHistoryStore.getState().showUSP('key_feature');
                            }}
                    >
                        <IconUspSVG hovered={hoveredUSP}/>
                    </button>
                )}
                {product.vrUrl && (
                    <button id="vr-button"
                            className="arBtn vrBtn"
                            onMouseOver={() => setHoverVR(true)}
                            onMouseOut={() => setHoverVR(false)}
                            onClick={() => {
                                useHistoryStore.getState().showUSP('vr');
                            }}
                    >
                        <IconVrSVG color={hoveredVR ? '#a50034' : '#333'}/>
                    </button>
                )}
                {(product.arUrl || product.arUrl2) && (
                    <button id="ar-button"
                            className="arBtn"
                            onMouseOver={() => setHoverAR(true)}
                            onMouseOut={() => setHoverAR(false)}
                            onClick={() => {
                                useHistoryStore.getState().showUSP('ar');
                            }}
                    >
                        <IconArSVG color={hoveredAR ? '#a50034' : '#333'}/>
                    </button>
                )}
            </div>
        </div>
    );
};

export default USPButtons;
