import create from "zustand";

const useMenuStore = create((set, get) => ({

    hoveredMenuCategory: undefined,
    prevHoveredMenuCategory: undefined,
    isTweening: false,
    setHover: (hovered) => {
        const temp = get().hoveredMenuCategory;
        set({prevHoveredMenuCategory: temp, hoveredMenuCategory: hovered});
    },


    isOpenMenu: false,
    isOpenFirst: false,

    sceneCategory: undefined,
    prevSceneCategory: undefined,
    setSceneCategory: (sceneCategory) => {
        const temp = get().sceneCategory;
        set({prevSceneCategory: temp, sceneCategory: sceneCategory});
    },

    isMobile: false,
}));

export default useMenuStore;
