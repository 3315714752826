import React from 'react';

const IconArSVG = ({color = '#333', ...rest}) => {
    return (
        <svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60"  {...rest}>

            <polygon className="st0" points="30.4,4.8 35,7.8 36.1,6 30.4,2.3 24.6,6 25.7,7.7 " fill={color}/>
            <polygon className="st0" points="15.3,14.3 14.2,12.6 8.5,16.2 8.5,23.3 10.6,23.3 10.6,18.6 14.2,20.9 15.3,19.2 11.5,16.7 " fill={color}/>
            <path className="st0" d="M27.8,35h2l-3.4-10h-2.5l-3.5,10h1.8l0.8-2.5h4L27.8,35z M23.4,31.1l1.5-4.6l1.5,4.6H23.4z" fill={color}/>
            <path className="st0" d="M38.7,27.9L38.7,27.9c0-2.1-1.6-3-3.9-3h-3.5v10h1.9v-4h1.2l2.7,4h2.1l-2.9-4.3C37.7,30.3,38.7,29.5,38.7,27.9z
M34.9,29.6h-1.6v-3.2h1.6c1.2,0,2,0.4,2,1.5V28C36.8,29,36.2,29.6,34.9,29.6z" fill={color}/>
            <polygon className="st0" points="10.6,36.5 8.5,36.5 8.5,43.7 14.2,47.3 15.3,45.5 10.6,42.6 " fill={color}/>
            <polygon className="st0" points="31.4,54.4 31.4,49.7 29.3,49.7 29.3,54.4 25.7,52.2 24.6,53.9 30.3,57.6 36.1,53.9 35,52.2 " fill={color}/>
            <polygon className="st0" points="50.1,42.6 45.4,45.6 46.5,47.4 52.2,43.7 52.2,36.6 50.1,36.6 " fill={color}/>
            <polygon className="st0" points="46.6,12.6 45.4,14.4 49.2,16.8 45.4,19.2 46.5,21 50.1,18.7 50.1,23.4 52.2,23.4 52.2,16.2 " fill={color}/>
        </svg>
    );
};



export default IconArSVG;