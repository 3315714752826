import React from 'react';
import styled from "styled-components";
import CategoryButton from "./CategoryButton";


const CategoryButtonGroup = React.memo(({categories, selectedCategory, selectCategory}) => {

    return (
        <StyledCategoryButtonGroup>
            {categories && categories.map(category => (
                <CategoryButton category={category}
                                selectedCategory={selectedCategory}
                                selectCategory={selectCategory}
                                totalCategory={categories.length}
                                key={category.id}/>
            ))}
        </StyledCategoryButtonGroup>
    );
});

const StyledCategoryButtonGroup = styled.div`
  //width: 36rem;
  width: 100%;
  height: auto;
  display: inline-flex;
  align-content: flex-end;
  //justify-content: flex-end;
  //flex-wrap: wrap-reverse;
  overflow: hidden;
  
  //display: grid;
  //grid-template-columns: 50% 50%;
  //align-content: end;
  z-index: 99;
  //left: 100px;
  position: relative;
  top: 1px;

  @media screen and (max-width: 736px), screen and (max-height: 414px) {
    width: 100%;
    //background: crimson;
  }
`;

export default CategoryButtonGroup;
