import React, {useEffect, useMemo, useRef} from 'react';
import styled from "styled-components";
import CategoryButtonGroup from "../CategoryButtonGroup";
import ProductDetailView from "../ProductDetailView";
import ProductListView from "../ProductListView";
import useProductsStore from "../../../stores/productsStore";
import CloseButton from "../CloseButton";
import {animated, useSpring} from "react-spring";
import {easeCubicOut} from "d3-ease";
import usePanoStore from "../../../stores/panoStore";
import useHistoryStore from "../../../stores/historyStore";


const ProductUI = React.memo(() => {
    const target = useRef();

    const products = useProductsStore(state => state.products);
    const selectedProduct = useProductsStore(state => state.selectedProduct);
    const categories = useProductsStore(state => state.categories);
    const selectedCategory = useProductsStore(state => state.selectedCategory);
    const isClosing = useProductsStore(state => state.isClosing);
    const currentDataType = useProductsStore(state => state.currentDataType);

    const isDragging = usePanoStore(state => state.isDragging);
    const isTweening = usePanoStore(state => state.isTweening);
    const yaw = usePanoStore(state => state.yaw);
    const targetCameraView = usePanoStore(state => state.targetCameraView);
    const selectedSceneCategory = usePanoStore(state => state.selectedSceneCategory);


    const props = useSpring({
        from: {opacity: 0},
        opacity: isDragging ? 0.2 : (isClosing ? 0 : 1),
        config: {easing: easeCubicOut, duration: isDragging || isClosing ? 490 : 1000}
    });

    const newProducts = useMemo(()=>{
        const sceneCategory = useHistoryStore.getState().sceneCategory;
        if(sceneCategory === 'family') {
            return products;
        }
        else
        {
            return products.filter(value =>
                (value.modelName !== 'OLED88Z19LA') &&
                (value.modelName !== '65ART90E6QA') &&
                (value.modelName !== '55LX1Q6LA') &&
                (value.modelName !== '48LX1Q6LA') &&
                (value.modelName !== '42LX1Q6LA')
            );
        }
    }, [products]);


    const resizeHandler = () => {
        const screenW = window.innerWidth;
        let screenH = window.innerHeight;

        if (target.current) {
            const containerW = target.current.offsetWidth;
            const containerH = target.current.offsetHeight;
            const ratioX = (screenW - containerW) / screenW;

            if (ratioX >= 0.68) {
                target.current.style.left = `${screenW * 0.68 - 80}px`;
            } else {
                target.current.style.left = `${screenW - containerW - 80}px`;
            }
            target.current.style.top = `${(screenH - containerH) * 0.5}px`;

            if (screenW <= 747 || screenH <= 414) {
                //가로모드
                if (screenW > screenH) {
                    target.current.style.left = `${(screenW - containerW) + 10}px`;
                    target.current.style.top = `${(screenH - containerH) * 0.5 + 15}px`;
                } else {
                    // screenH = Math.min(600, window.innerHeight);
                    target.current.style.left = `${(screenW - containerW) * 0.5}px`;
                    target.current.style.top = `${(screenH - containerH - 25)}px`;
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener('resize', resizeHandler);
        }
    }, []);

    useEffect(() => {
        if (!targetCameraView) return;
        if (isTweening) return;

        const diff = targetCameraView.yaw - yaw;
        // console.log(yaw, targetCameraView.yaw, ">>>", Math.abs(diff));
        if (Math.abs(diff) > 0.9) {
            const {sceneCategory, productGroupCategory} = useHistoryStore.getState();
            const navigate = useHistoryStore.getState().navigate;
            if(navigate) navigate(`?pathname=/${sceneCategory}/${productGroupCategory}`);
        }
    }, [yaw, isTweening]);

    function selectProductHandler(product) {
        useHistoryStore.getState().selectProduct(product.modelName);
    }
    function selectCategoryHandler(category) {
        useProductsStore.getState().getFirstModelName(category).then((modelName) => {
            const navigate = useHistoryStore.getState().navigate;

            let newModelName = modelName;
            if(modelName === '65ART90E6QA' && useHistoryStore.getState().sceneCategory !== 'family') newModelName = 'OLED97G29LA';

            if(navigate) navigate(`?pathname=/${selectedSceneCategory}/${currentDataType}/${category.name}/${newModelName}`);
        });
    }

    return (
        <Wrapper id="productPanel" ref={target} style={{opacity: props.opacity}}>
            <div id="category-menu">
                <CategoryButtonGroup categories={categories} selectedCategory={selectedCategory}
                                     selectCategory={selectCategoryHandler}/>
            </div>
            <div id="product-detail">
                <ProductDetailView selectedProduct={selectedProduct}/>
            </div>
            <div id="product-list">
                <ProductListView products={newProducts} selectProduct={selectProductHandler} selectedProduct={selectedProduct}/>
            </div>
            <div id="close-button-container">
                <CloseButton id="product-panel-close-button"/>
            </div>
        </Wrapper>
    );
});

//region Styles
const Wrapper = styled(animated.div)`
  display: grid;
  grid-template-columns: 0 30.4rem 21rem;
  position: absolute;
  opacity: 0;
  //background: rgba(255,0,0,0.3);

  div {
    box-sizing: border-box;
  }

  #category-menu {
    grid-column-start: 2;
    grid-column-end: 3;
    //background: aquamarine;
    //padding-bottom: 0.4rem;
  }

  #product-size {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    padding-right: 0.4rem;
    //background: blueviolet;
  }

  #product-detail {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    height: 51.6rem;
    //background: cornflowerblue;
  }

  #product-list {
    grid-column-start: 3;
    grid-row-start: 2;
    //background: coral;
    height: 51.6rem;
    padding-left: 0.8rem;
    pointer-events: none;
  }

  #close-button-container {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    width: 20rem;
    position: absolute;
    top: 0.6rem;
    filter: drop-shadow(1px 1px 2px rgba(0,0,0,0.4));
  }
  
  
  @media screen and (max-width: 747px) and (orientation: portrait), screen and (max-height: 600px) and (orientation: portrait) {
    grid-template-columns: 0 30rem 16rem;
    //background: rgba(255,255,0,0.3);

    #product-detail {
      height: 25rem;
    }

    #product-list {
      height: 25rem;
    }
  }

  @media screen and (max-width: 747px) and (orientation: landscape), screen and (max-height: 600px) and (orientation: landscape) {
    grid-template-columns: 0 30rem 21rem;
    //background: rgba(255,0,255,0.3);
    
    #product-detail {
      height: 21rem;
    }

    #product-list {
      height: 21rem;
    }
  }
`;
//endregion

export default ProductUI;
