import {DEFAULT_PATH} from "../../menu";

const STUDIO_MAIN_DATA = {
    "id": "studio_main",
    "category": "studio",
    "name": "Studio",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0.03667625834346033,
        "yaw": -0.4223956984513606,
        "fov": 1.112832132700145
    },
    "linkHotspots": [
        {
            "yaw": -0.9616117905896306,
            "pitch": 0.040302836689672716,
            "rotation": 0,
            "target": "studio_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.studio.oled,
            "targetCameraView": {
                "yaw": 0.1993592667127757,
                "pitch": 0.045656059969896745,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.21538524537931089,
                "fov": 1
            }
        },
        {
            "yaw": 0.1731759899839851,
            "pitch": 0.07127169832721236,
            "rotation": 0,
            "target": "studio_fr",
            "category": "fridge",
            "pathname": "/studio/fridge/instaview/GMX945MC9F",
            "targetCameraView": {
                "yaw": 0.24285583787532872,
                "pitch": 0.05620696066291231,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.25620696066291231,
                "fov": 1
            }
        },
        {
            "yaw": 3.127917319702163,
            "pitch": 0.6499510407986122,
            "rotation": 0,
            "target": "studio_projector",
            "category": "it",
            "pathname": "/studio/it/beam_projector/PH30N",
            "targetCameraView": {
                "yaw": -0.0071422242109075995,
                "pitch": 0.1553467231693677,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.30276122450648373,
                "pitch": 0.07620233814263244,
                "fov": 1
            }
        },
        {
            "yaw": -1.566555166444175,
            "pitch": 0.2794985078687944,
            "rotation": 0,
            "arrow":true,
            "target": "studio_toilet",
            "category": "wm",
            "pathname": "/studio/wm/turbowash360/F6V1010BTSE",
            "targetCameraView": {
                "yaw": 0.02704449235143791,
                "pitch": 0.06191195100668523,
                "fov": 1.45
            },
            "targetCameraView2": {
                "yaw": -0.5448200723351064,
                "pitch": 0.4816234939238875,
                "fov": 1.45
            }
        },
        {
            "yaw": -2.050064134590645,
            "pitch": -0.19080457735419643,
            "rotation": 0,
            "target": "studio_air",
            "category": "air",
            "pathname": "/studio/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": 0.07912567634454959,
                "pitch": -0.0027260613376149934,
                "fov": 1.2
            },
            "targetCameraView2": {
                "yaw": -0.21,
                "pitch": -0.11,
                "fov": 1
            },
        },
    ],
    "infoHotspots": [],
    // "defaultTV":{
    //     "sceneId": 'studio_main',
    //     "yaw": -1.1266717588184297,
    //     "pitch": 0.1478878465968414,
    //     "width": 382,
    //     "height": 227,
    //     "radius": 1000,
    //     "extraTransforms": "rotateX(-8.473349451277167deg) rotateY(22.3deg)",
    //     "url":"videos/studio_tv.mp4"
    // }
};
export default STUDIO_MAIN_DATA;
