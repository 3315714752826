import React, {useEffect, useMemo, useRef, useState, useCallback, useLayoutEffect} from 'react';
import styled from "styled-components";
import {useTransition, animated, useSpring} from "react-spring";
import useHistoryStore from "../../stores/historyStore";
import {getMapData, MAP_DATA} from "../../api/menu";
import {easeCubicOut} from "d3-ease";
import {ROOT_PATH_PREFIX} from "../../const";


//---------------------------------------------------------------------
const MinimapButton = React.memo(({spot, sceneCategory}) => {

    const productGroupCategory = useHistoryStore(state => state.productGroupCategory);
    const modelName = useHistoryStore(state => state.modelName);

    const [hovered, setHover] = useState(false);
    const selected = useMemo(() => {
        let check = false;
        if (spot.categories) {
            for (let i = 0; i < spot.categories.length; i++) {
                const category = spot.categories[i];
                if (category === productGroupCategory && modelName) {
                    check = true;
                    break;
                }
            }
        }
        return check;
    }, [productGroupCategory, modelName]);

    const circleLineRef = useRef();
    const dotRef = useRef();
    const balloonRef = useRef();

    const spring = useSpring({
        dotScale: selected ? 'scale(1.5) translateZ(0)' : (hovered ? 'scale(1.6) translateZ(0)' : 'scale(1) translateZ(0)'),
        lineScale: selected ? 'scale(2) translateZ(0)' : (hovered ? 'scale(1.1) translateZ(0)' : 'scale(1) translateZ(0)'),
        lineColor: selected ? '#a50034' : '#fff',
        lineWidth: selected ? 1 : 2,
        lineBG : selected ? 'radial-gradient(rgba(165,0,52,0), rgba(165,0,52,1))' : 'radial-gradient(rgba(165,0,52,0), rgba(165,0,52,0))',
        config: {duration: 200, easing: easeCubicOut},
    });

    const spring2 = useSpring({
        balloonOpacity: selected ? 1 : 0,
        left: `${spot.x * 10 + (selected ? 44 : 34)}px`,
        top: `${spot.y * 10 + 5}px`,
        config: {duration: 500, easing: easeCubicOut},
        onStart: () => {
            if(balloonRef.current){
                balloonRef.current.style.display = 'block';
            }
        },
        onRest: ds => {
            if(ds.balloonOpacity === 0) {
                if(balloonRef.current){
                    balloonRef.current.style.display = 'none';
                }
            }
        }
    })

    const onClickHandler = () => {
        if (spot.pathname) {
            useHistoryStore.getState().navigate(`?pathname=${spot.pathname}`);
        }
    };

    return (
        <>
            <div className='mapBtn'
                 id={`mapBtn-${sceneCategory}-${spot.categories[0]}`}
                 style={{
                     left: `${spot.x * 10}px`,
                     top: `${spot.y * 10}px`
                 }}
                 onPointerOver={() => setHover(true)}
                 onPointerOut={() => setHover(false)}
                 onClick={onClickHandler}
            >
                <animated.div className="dot" ref={dotRef} style={{
                    transform: spring.dotScale,
                    zIndex: selected ? 1000 : 1
                }}/>
                <animated.div className="circle-line" ref={circleLineRef} style={{
                    transform: spring.lineScale,
                    borderColor: spring.lineColor,
                    background: spring.lineBG,
                    borderWidth: spring.lineWidth,
                    zIndex: selected ? 999 : 0
                }}/>
            </div>
            <animated.div className="balloon" style={{
                opacity: spring2.balloonOpacity,
                left: spring2.left,
                top: spring2.top,
            }} ref={balloonRef}>
                {spot.label}
            </animated.div>
        </>
    );
});

//---------------------------------------------------------------------
const MinimapGroup = React.memo(({style, sceneCategory}) => {
    const currentMapData = useMemo(() => getMapData(sceneCategory), [sceneCategory]);

    return (
        <animated.div className='mapContainer' style={style}>
            {currentMapData && (
                <>

                    <img className='mapBG' src={`${ROOT_PATH_PREFIX}${currentMapData.bgUrl}`}/>
                    {currentMapData.spots.map((spot, idx) => {
                        return <MinimapButton key={idx} spot={spot} sceneCategory={currentMapData.category}/>
                    })}
                </>
            )}
        </animated.div>
    );
});


//---------------------------------------------------------------------
const pages = [
    ({style, sceneCategory}) => <MinimapGroup style={style} sceneCategory={sceneCategory}/>,
    ({style, sceneCategory}) => <MinimapGroup style={style} sceneCategory={sceneCategory}/>
];

//---------------------------------------------------------------------
const MinimapView = React.memo(() => {

    const [pageIndex, setPageIndex] = useState(0);
    const transitions = useTransition(pageIndex, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0}
    });

    const sceneCategory = useHistoryStore(state => state.sceneCategory);
    const prevSceneCategory = useHistoryStore(state => state.prevSceneCategory);

    useEffect(() => {
        setPageIndex(p => (p + 1) % 2);
    }, [sceneCategory]);

    return (
        <Wrapper id="miniMap">
            {sceneCategory && transitions((props, item) => {
                const Page = pages[item];
                if (item === pageIndex) {
                    return <Page style={props} sceneCategory={sceneCategory}/>
                } else {
                    return <Page style={props} sceneCategory={prevSceneCategory}/>
                }
            })}
        </Wrapper>
    );
});

const Wrapper = styled(animated.div)`

  position: relative;
  width: 220px;
  height: 168px;
  top: 4rem;
  left: 8rem;
  pointer-events: none;
  //z-index: 9999999 !important;
  //background: #61dafb;
  
  .title{
    background: #a50034;
    color: white;
    padding: 0.6rem 1rem;
    position: absolute;
    //left: 8rem;
    //top: 8rem;
    font-size: 1.2rem;
  }

  .mapContainer {
    position: absolute;
    width: 272px;
    height: 168px;
    left: -4.1rem;
    top: 1.6rem;
    transform: scale(0.7) translateZ(0);
    -webkit-transform: scale(0.7) translateZ(0);
  }

  .mapBG {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    //image-rendering: optimizeQuality;
  }

  .mapBtn {
    width: 25px;
    height: 25px;
    position: absolute;
    cursor: pointer;
    image-rendering: smooth;
    border: none;
    //background: #61dafb;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: visible;

    img {
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .circle-line {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      border: 2px solid white;
      position: absolute;
      pointer-events: none;
    }

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      position: absolute;
      background: white;
    }
  }
  
  .balloon{
    pointer-events: none;
    position: absolute;
    font-size: 1.8rem;
    color: #a50034;
    font-weight: 700;
    background: white;
    padding: 0.3rem 1rem 0.4rem;
    top: -6rem;
    z-index: 9999;
    transform: translateZ(0);
    border-radius: 0.4rem;
    white-space: nowrap;
    
    :after{
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: -0.8rem;
      top: 0;
      border-left: 0 solid transparent;
      border-bottom: 1rem solid transparent;
      border-top: 1px solid #fff;
      border-right: 1rem solid #fff;
    }


  }

  @media screen and (max-width: 1280px), screen and (max-height: 768px){
    .title{
      //left: 8rem;
      //top: 8rem;
    }
    .mapContainer {
      transform: scale(0.5);
      left: -8.5rem;
      top: -1rem;
      //background: red;
    }
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {
    left: 2rem;
    top: 2rem;
    
    .title{
      //left: 2rem;
      //top: 8rem;
    }
    .mapContainer {
      transform: scale(0.5);
      //left: -6.5rem;
      //top: 7rem;
      //background: blue;
    }
    .balloon{
      font-size: 3rem;
    }
  }

  
`;

export default MinimapView;
