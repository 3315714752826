import React from 'react';
import styled from "styled-components";
import useMenuStore from "../../stores/menuStore";
import {ROOT_PATH_PREFIX} from "../../const";


const EPromoters = () => {
    const isMobile = useMenuStore(state => state.isMobile);

    return (
        <>
            {!isMobile && (
                <Wrapper>
                    <span>Press</span>
                    <img src={`${ROOT_PATH_PREFIX}/icons/epromotor_balloon.png`} alt="" />
                    <span>to live chat with our UK e-promoters</span>
                </Wrapper>
            )}
        </>
    );
};

const Wrapper = styled.div`
  border: 0.2rem solid #f5901c;
  display: inline-flex;
  border-radius: 2.5rem;
  height: 5rem;
  position: absolute;
  right: 10rem;
  bottom: 2rem;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem;
  font-size: 2rem;
  color: black;
  font-weight: 700;
  background: rgba(255,255,255,0.7);
  img{
    height: 80%;
    max-height: 30px;
    margin: 0 0.6rem;
  }
`;

export default EPromoters;
