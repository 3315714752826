import React from 'react';

const IconVrSVG = ({color = '#333', ...rest}) => {
    return (
        <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.25 22.25">
            <g id="Layer_1-2">
                <g>
                    <path className="cls-1"
                          d="M10.46,4.59c.3,0,.55-.24,.56-.54,0-.37,.31-.66,.67-.65h1.33c.36,0,.65,.29,.66,.65v1.28c0,.37-.31,.66-.67,.65h-1.34c-.3,0-.54,.24-.54,.54s.24,.54,.54,.54h1.35c.36,0,.66,.29,.66,.66v1.28c0,.37-.31,.66-.67,.65h-1.32c-.36,0-.67-.29-.67-.65-.01-.3-.26-.54-.56-.54s-.55,.24-.56,.54c.02,.98,.82,1.76,1.79,1.74h1.32c.97,.02,1.77-.76,1.79-1.74v-1.28c0-.45-.18-.88-.5-1.2,.32-.32,.5-.75,.5-1.2v-1.28c-.02-.98-.82-1.76-1.79-1.74h-1.32c-.97-.02-1.77,.76-1.79,1.73,.01,.3,.26,.54,.56,.54Z" fill={color}/>
                    <path className="cls-1"
                          d="M17.6,3.4h1.32c.36,0,.67,.29,.67,.65,.01,.3,.26,.54,.56,.54s.55-.24,.56-.54c-.02-.98-.82-1.76-1.79-1.74h-1.32c-.97-.02-1.77,.76-1.79,1.74v4.81c.02,1.06,.89,1.9,1.94,1.88h1.02c1.05,.02,1.91-.82,1.94-1.88v-1c-.02-1.06-.89-1.9-1.94-1.88h-1.02c-.28,0-.56,.06-.82,.18v-2.12c0-.37,.31-.66,.67-.65Zm.15,3.67h1.02c.44,0,.81,.35,.82,.8h0v1c0,.45-.38,.81-.82,.8h-1.02c-.44,0-.81-.35-.82-.8v-1c0-.45,.38-.81,.82-.8Z" fill={color}/>
                    <path className="cls-1"
                          d="M23.65,10.75h1.02c1.05,.02,1.92-.82,1.94-1.88V4.2c-.02-1.06-.89-1.9-1.94-1.88h-1.02c-1.05-.02-1.92,.82-1.94,1.88v4.67c.02,1.06,.89,1.9,1.94,1.88Zm-.82-6.55c0-.45,.38-.81,.82-.8h1.02c.45,0,.81,.35,.82,.8h0v4.67c0,.45-.38,.81-.82,.8h-1.02c-.44,0-.81-.35-.82-.8V4.2Z" fill={color}/>
                    <path className="cls-1"
                          d="M29.95,4.62h.93c1,0,1.81-.83,1.81-1.84v-.94c0-1.01-.81-1.84-1.81-1.84h-.93c-1,0-1.81,.83-1.81,1.84v.94c0,1.01,.81,1.84,1.81,1.84Zm-.69-2.78c0-.39,.31-.7,.69-.7h.93c.38,0,.69,.32,.69,.7h0v.94c0,.39-.31,.7-.69,.7h-.93c-.38,0-.69-.32-.69-.7v-.94Z" fill={color}/>
                    <path className="cls-1"
                          d="M29.51,6.93c-.31-.09-.63,.09-.72,.4-.01,.05-.02,.1-.02,.15,0,.26,.18,.49,.43,.55,4.25,1.15,6.89,2.96,6.89,4.74,0,2.83-6.13,5.37-14.26,5.9-.31,.01-.55,.26-.55,.57s.25,.57,.57,.57h.06c9.04-.6,15.36-3.5,15.36-7.05,0-2.38-2.82-4.51-7.75-5.84Z" fill={color}/>
                    <path className="cls-1"
                          d="M16.31,16.59c-.25-.2-.61-.18-.83,.06-.09,.1-.14,.23-.14,.37,0,.17,.08,.33,.21,.44l1.55,1.29c-9.59-.29-15.93-3.22-15.93-5.98,0-1.77,2.64-3.59,6.89-4.74,.25-.06,.43-.29,.43-.55,0-.05,0-.1-.02-.15-.09-.31-.41-.49-.72-.4C2.82,8.26,0,10.39,0,12.77c0,3.82,7.43,6.85,17.16,7.13l-1.62,1.34c-.13,.11-.21,.27-.21,.44,0,.14,.05,.27,.14,.37,.21,.24,.58,.27,.83,.06l2.8-2.32c.13-.11,.21-.27,.21-.44s-.08-.33-.21-.44l-2.8-2.33Z" fill={color}/>
                </g>
            </g>
        </svg>
    );
};

export default IconVrSVG;