import {DEFAULT_PATH} from "../../menu";

const OFFICE_TV_DATA = {
    "id": "office_tv",
    "category":"office",
    "name": "office_tv",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.5707963267948966
    },
    "linkHotspots": [
        {
            "yaw": 2.6979128430542243,
            "pitch": 0.1321207060603431,
            "rotation": 0,
            "target": "office_monitor",
            "category": "laptop",
            "pathname": "/office/laptop/laptop/16Z90P-K.AA72A1",
            "targetCameraView": {
                "yaw": -0.3773571158212441,
                "pitch": 0.21475084933014976,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": -0.5773571158212441,
                "pitch": 0.21475084933014976,
                "fov": 1.45
            }
        },
        {
            "yaw": 2.9780673650496947,
            "pitch": 0.013068094373137384,
            "rotation": 0,
            "target": "office_monitor",
            "category": "monitor",
            "pathname": "/office/monitor/ultrawide/38WN95C-W",
            "targetCameraView": {
                "yaw": 0.3559575860957267,
                "pitch": 0.01352287731259949,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.11352287731259949,
                "fov": 1.45
            }
        },
        {
            "yaw": 0.0050191335463960485,
            "pitch": 0.0156007146774364,
            "rotation": 0,
            "target": "office_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.office.oled,
            "targetCameraView": {
                "yaw": 0.27684357558200645,
                "pitch": 0.06769971699925392,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.16769971699925392,
                "fov": 1.45
            }
        },
        {
            "yaw": 0.0050191335463960485,
            "pitch": 0.25809547694334256,
            "rotation": 0,
            "target": "office_tv",
            "category": "av",
            "pathname": "/office/av/soundbar/SP11RA",
            "targetCameraView": {
                "yaw": 0.27684357558200645,
                "pitch": 0.06769971699925392,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.16769971699925392,
                "fov": 1.45
            }
        },
        {
            "yaw": -0.9581685479451494,
            "pitch": 0.033233886785357214,
            "rotation": 0,
            "target": "office_tv",
            "category": "wine_celler",
            "pathname": "/office/wine_celler/wine_celler/LSR200W",
            "targetCameraView": {
                "yaw": -0.8866332945080764,
                "pitch": 0.056022109152904065,
                "fov": 1.1
            },
            "targetCameraView2": {
                "yaw": -0.9966332945080764,
                "pitch": 0.056022109152904065,
                "fov": 1.1
            }
        },
        {
            "yaw": -0.46742022688804497,
            "pitch": -0.2975577013735844,
            "rotation": 0,
            "target": "office_air",
            "category": "air",
            "pathname": "/office/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": -0.1138640612947625,
                "pitch": 0.028953986207012505,
                "fov": 1.1
            },
        }
    ],
    "infoHotspots": [
        {
            "yaw": -0.38846777075882954,
            "pitch": 0.22828298819631176,
            "category": "tvs",
            "size": {w: 947, h: 586},
            "images": [
                {url: "products/update_20230406/office_product/TV/OLED/OLED77Z39LA.png", modelName: "OLED77Z39LA"},
            ],
            "radius": 1270,
            "extraTransforms": "rotateX(-12.96506019925729deg) rotateY(-22.257563741336508deg) rotateZ(0deg)"
        },
        {
            "yaw": -0.450661468063565,
            "pitch": 0.36925849022006574,
            "category": "av",
            "size": {w: 1119, h: 329},
            "defaultModelName":"SP11RA",
            "images": [
                {url: "products/update_20210427/office_product/soundbar/sp11ra.png", modelName: "SP11RA"},
            ],
            "radius": 1370,
            "radiusApple":1352,
            "extraTransforms": "rotateX(-21.15695303898255deg) rotateY(-25.82100010921201deg) rotateZ(0deg)"
        }
    ],
    "defaultTV":{
        "modelName": "none",
        "width": 820,
        "height": 462,
        "offsetX": 60,
        "offsetY": 70,
        "url":"videos/office_tv.mp4"
    }
};
export default OFFICE_TV_DATA;
