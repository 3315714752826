import React from 'react';

const IconPlaySVG = ({color = '#fff', state = 'play', ...rest}) => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 349 349" {...rest}>
            {/*<defs>*/}
            {/*    <style>.cls-1{fill:none;stroke:#9b9b9b;stroke-miterlimit:10;stroke-width:20px;}.cls-2{fill:#9b9b9b;}</style>*/}
            {/*</defs>*/}
            <circle className="cls-1" cx="174.5" cy="174.5" r="164.5" strokeWidth={'20px'} fill={'none'} stroke={color}/>
            <path className="cls-2" d="M159.78,121l63.3,45.23a10.19,10.19,0,0,1,0,16.6L159.78,228a10.2,10.2,0,0,1-16.13-8.3V129.27A10.2,10.2,0,0,1,159.78,121Z" fill={state !== 'play' ? 'none' : color}/>
            <rect className="cls-2" x="136.82" y="125" width="24" height="110" rx="12" fill={state === 'play' ? 'none' : color}/>
            <rect className="cls-2" x="191.82" y="125" width="24" height="110" rx="12" fill={state === 'play' ? 'none' : color}/>
        </svg>
    );
};

export default IconPlaySVG;
