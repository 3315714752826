import {DEFAULT_PATH} from "../../menu";

const STUDIO_FR_DATA = {
    "id": "studio_air",
    "category": "studio",
    "name": "studio_air",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.2
    },
    "linkHotspots": [
        {
            "yaw": -0.16394566900611274,
            "pitch": -0.2370575395478074,
            "rotation": 0,
            "target": "studio_air",
            "category": "air",
            "pathname": "/studio/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": 0.07912567634454959,
                "pitch": -0.0027260613376149934,
                "fov": 1.2
            },
            "targetCameraView2": {
                "yaw": -0.21,
                "pitch": -0.11,
                "fov": 1
            },
        },
        {
            "yaw": 1.0715983178705386,
            "pitch": 0.08991954688279336,
            "rotation": 0,
            "target": "studio_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.studio.oled,
            "targetCameraView": {
                "yaw": 0.3562050104448204,
                "pitch": 0.01538524537931089,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.21538524537931089,
                "fov": 1
            }
        },
        {
            "yaw": 2.1978921107151574,
            "pitch": 0.07857307258445267,
            "rotation": 0,
            "target": "studio_fr",
            "category": "fridge",
            "pathname": "/studio/fridge/instaview/GMX945MC9F",
            "targetCameraView": {
                "yaw": 0.24285583787532872,
                "pitch": 0.05620696066291231,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.25620696066291231,
                "fov": 1
            }
        },
        {
            "yaw": -1.6823191861515063,
            "pitch": 0.6786212715445217,
            "rotation": 0,
            "target": "studio_projector",
            "category": "it",
            "pathname": "/studio/it/beam_projector/PH30N",
            "targetCameraView": {
                "yaw": -0.0071422242109075995,
                "pitch": 0.1553467231693677,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.30276122450648373,
                "pitch": 0.07620233814263244,
                "fov": 1
            }
        },
        {
            "yaw": 0.37324530243829557,
            "pitch": 0.36262597090698634,
            "rotation": 0,
            "arrow":true,
            "target": "studio_toilet",
            "category": "wm",
            "pathname": "/studio/wm/turbowash360/F6V1010BTSE",
            "targetCameraView": {
                "yaw": 0.02704449235143791,
                "pitch": 0.06191195100668523,
                "fov": 1.45
            },
            "targetCameraView2": {
                "yaw": -0.5448200723351064,
                "pitch": 0.4816234939238875,
                "fov": 1.45
            }
        }
    ],
    "infoHotspots": [
        {
            "yaw": -0.39696306232956545,
            "pitch": -0.11245470800656499,
            "category": "air",
            "size": {w: 547, h: 333},
            "images": [
                {url: "products/update_20210427/studio_product/air/dc12.png", modelName: "DC12RH" },    //<-- 기본 이미지
            ],
            "radius": 1100,
            "extraTransforms": "rotateX(10deg) rotateY(-20deg)"
        }
    ]
};
export default STUDIO_FR_DATA;
