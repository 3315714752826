import React, {useState, useMemo} from 'react';
import styled from "styled-components";
import {animated, useSpring} from "react-spring";
import {easeCubicOut} from "d3-ease";
import {ROOT_PATH_PREFIX} from "../../const";
import {LazyLoadImage} from "react-lazy-load-image-component";


const ProductButton = React.memo(({product, selectProduct, selectedProduct}) => {
    const {thumbUrl, sThumbUrl, title, modelName} = product;
    const [hovered, setHover] = useState(false);
    const selected = useMemo(() => (product.modelName === selectedProduct.modelName), [product, selectedProduct]);
    const props = useSpring({
        borderColor: selected ? '#a50034' : (hovered ? 'rgba(0,0,0,0.05)' : 'rgba(255,255,255,0)'),
        config: {easing: easeCubicOut, duration: 200}
    });
    const [isLoaded, setIsLoaded] = useState(false);
    const spring = useSpring({
       opacity: isLoaded ? 1 : 0,
        config: {duration: 200, easing: easeCubicOut}
    });

    const onClickHandler = () => {
        selectProduct(product);
    };
    const onPointerOverHandler = () => {
        setHover(true);
    };
    const onPointerOutHandler = () => {
        setHover(false);
    };

    const newThumbUrl = useMemo(() => {
        let url = sThumbUrl ?? thumbUrl;
        if(url.startsWith('/', 0)){
            return `${ROOT_PATH_PREFIX}${url}`;
        }else{
            return url;
        }
    }, [thumbUrl, sThumbUrl]);


    return (
        <StyledProductButton
            style={{
                backgroundColor: props.backgroundColor,
                borderColor: props.borderColor,
                cursor: selected ? 'auto' : 'pointer'
            }}
            onClick={onClickHandler}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
        >
            <animated.div id="photoContainer" style={{
                opacity: spring.opacity,
                // background: (isLoaded ? 'white' : '#ff0000') + '!important'
            }}>
                <LazyLoadImage id="photo" src={newThumbUrl} alt='' style={{
                    transform: `scale(${product.sThumbScale ?? 1}) translate(${product.sThumbOffset ?? '0,0'})`,
                    // opacity: isLoaded ? 1 : 0
                }} afterLoad={()=>setIsLoaded(true)} />
            </animated.div>
            <div id="txtContainer">
                <span id="modelName">{modelName}</span>
                <span id="title">{title}</span>
            </div>
            {/*{isNew && (<div className="indicator" />)}*/}
        </StyledProductButton>
    );
});

const StyledProductButton = styled(animated.button)`
  width: 100%;
  height: 10rem;
  //border-radius: 0.8rem;
  overflow: hidden;
  outline: none;
  padding: 1rem 0.5rem 1rem;
  margin: 0;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: #a50034;
  background: #fff;
  position: relative;

  display: flex;
  flex-direction: row;

  .indicator {
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    //background: red;
    border-top: 3px solid #a50034;
    border-left: 3px solid #a50034;
    border-bottom: 3px solid transparent;
    border-right: 3px solid transparent;
  }

  #photoContainer {
    min-width: 8rem;
    width: 100%;
    height: 100%;
    //border-radius: 1rem;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }

  #photo {
    height: 80%;
  }

  #modelName {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  #title {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 600;
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  #txtContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: black;
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {

    height: 9rem;
    padding: 0.5rem;
    border-radius: 0;

    #photoContainer {
      border-radius: 0;
      width: 100%;
      min-width: 100%;
      height: 100%;
    }

    #photo {
      width: 100%;
      height: auto;
      border-radius: 0;
    }

    #txtContainer {
      visibility: hidden;
      margin-left: 0;
    }
  }
  //&:last-child
  //{
  //  background: #61dafb;
  //}
  //&:nth-child(odd)
  //{
  //  background: #666666;
  //}
  //&:first-child{
  //  background: palevioletred;
  //}
`;

export default ProductButton;
