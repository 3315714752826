import React, {useCallback, useEffect, useState, useMemo, useRef} from 'react';
import {useSpring, animated} from "react-spring";
import {easeCubicOut, easeBackOut, easeCubicInOut} from "d3-ease";
import styled from "styled-components";
import useProductsStore from "../../../stores/productsStore";
import useHistoryStore from "../../../stores/historyStore";
import usePanoStore from "../../../stores/panoStore";
import useSelectedProductStore from "../../../stores/selectedProductStore";
import {ROOT_PATH_PREFIX} from "../../../const";
import selectedProductStore from "../../../stores/selectedProductStore";


function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 60 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}


const ProductHaloHotspot = React.memo(({hotspot, sceneCategory, sceneId}) => {
    const ref = useRef();
    const isActive = useRef(false);

    const isClosing = useProductsStore(state => state.isClosing);
    const isDragging = usePanoStore(state => state.isDragging);
    const sceneChanged = usePanoStore(state => state.sceneChanged);

    const sceneId2 = useHistoryStore(state => state.sceneId);
    const productGroupCategory = useHistoryStore(state => state.productGroupCategory);
    const modelName = useHistoryStore(state => state.modelName);
    const isPanelOpened = useMemo(() => (productGroupCategory === hotspot.category) && !isClosing && (modelName !== undefined), [productGroupCategory, isClosing, modelName]);
    const hotspotId = useMemo(() => {
        if(hotspot.category){
            return `hotspot-${sceneCategory}-${hotspot.category}`;
        }else{
            return `hotspot-${sceneCategory}`;
        }
    }, [hotspot]);

    const [spring, animate] = useSpring(() => ({
        cancel: true,
        color: hotspot.arrow ? 'rgba(255,255,255,0)' : '#fff',
        scale: 'scale(1)'
    }));

    const [spring2, animate2] = useSpring(() => ({
        from: {
            opacity: 1,
            scale: 'scale(0.3)'
        }
    }));

    const [spring3, animate3] = useSpring(() => ({
        from: {opacity: 1}
    }));

    useEffect(() => {
        if (isDragging && !sceneChanged) return;
        if (sceneId2 === sceneId) {
            const isIn = isInViewport(ref.current);
            if (isPanelOpened) {
                deactivate();
            } else {
                if (isIn) {
                    activate();
                } else {
                    deactivate();
                }
            }
        } else {
            deactivate();
        }
    }, [isDragging, sceneId2, sceneChanged, isPanelOpened]);

    useEffect(() => {
        animate3({cancel: true});
        animate3({cancel: false, opacity: isPanelOpened ? 0 : 1, scale: isPanelOpened ? 'scale(0)' : 'scale(1)'});
    }, [isPanelOpened]);

    useEffect(()=>{
        if(sceneCategory === 'family' && hotspot.category === 'av' && productGroupCategory === 'tvs')
        {
            let _modelName = modelName;
            if (!modelName){
                const selectedTvs = selectedProductStore.getState().getProduct('family', 'tvs');
                if (selectedTvs) {
                    const comp = selectedTvs?.pathname.split('/');
                    if (comp.length > 4){
                        _modelName = comp[comp.length-1].trim();
                    }
                }
            }

            if(_modelName === '65ART90E6QA' ||
                _modelName === '55LX1Q6LA' ||
                _modelName === '48LX1Q6LA' ||
                _modelName === '42LX1Q6LA' ||
                _modelName === 'OLED88Z19LA')
            {
                if(ref.current?.style.opacity > 0){
                    animate3({cancel: true});
                    animate3({cancel: false, opacity: 0, scale: 'scale(0)'});
                    deactivate();
                }
            }else{
                if(ref.current?.style.opacity < 1)
                {
                    animate3({cancel: true});
                    animate3({cancel: false, opacity: 1, scale: 'scale(1)'});
                    activate();
                }
            }
            //console.log(sceneCategory, hotspot.category, modelName, productGroupCategory);
        }
    }, [modelName]);

    function activate() {
        if (isActive.current) return;
        isActive.current = true;

        animate2({cancel: true});
        animate2({
            cancel: false,
            from: {
                opacity: 1,
                scale: 'scale(0.3)'
            },
            opacity: 0,
            scale: 'scale(1)',
            loop: true,
            config: {duration: 1600, easing: easeCubicOut}
        });
    }

    function deactivate() {
        if (!isActive.current) return;
        isActive.current = false;

        animate2({cancel: true});
        animate2({
            cancel: false,
            opacity: 1,
            scale: 'scale(0.3)',
            loop: true,
            config: {duration: 300, easing: easeCubicOut}
        });
    }

    const onClickHandler2 = () => {
        if (isClosing) return;

        if (!hotspot.pathname) {
            console.warn('hotspot.pathname === undefined');
            return;
        }
        const selectedInfo = useSelectedProductStore.getState().getProduct(sceneCategory, hotspot.category);
        const isMonitor = hotspot.pathname.startsWith("/office/monitor");

        const navigate = useHistoryStore.getState().navigate;
        if (selectedInfo && !isMonitor) {
            if (navigate) navigate(`?pathname=${selectedInfo.pathname}`);
        } else {
            if (navigate) navigate(`?pathname=${hotspot.pathname}`);
        }
    }

    const onPointerOverHandler = () => {
        animate({cancel: true});
        animate({cancel: false, color: hotspot.arrow ? 'rgba(255,255,255,0)' : '#a50034', scale: 'scale(1.5)'});
    };

    const onPointerOutHandler = () => {
        animate({cancel: true});
        animate({cancel: false, color: hotspot.arrow ? 'rgba(255,255,255,0)' : '#fff', scale: 'scale(1)'});
    };

    return (
        <StyledProductHaloHotspot
            arrow={hotspot.arrow ? hotspot.arrow.toString() : 'false'}
            active={(!isPanelOpened).toString()}
            onClick={onClickHandler2}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
            style={{
                opacity: spring3.opacity,
                transform: spring3.scale
            }}
            ref={ref}
            id={hotspotId}
        >
            {!hotspot.arrow && (
                <animated.div className="effect" style={{
                    transform: spring2.scale,
                    opacity: spring2.opacity
                }}/>
            )}

            <animated.div className='circle'
                          style={{
                              transform: spring.scale,
                              backgroundColor: spring.color
                          }}
            >
                {hotspot.arrow && <animated.img src={`${ROOT_PATH_PREFIX}/img/arrow_icon.png`} alt='' style={{
                    // color: props.color
                    color: spring.color
                }}/>}
            </animated.div>
        </StyledProductHaloHotspot>
    );
});

const StyledProductHaloHotspot = styled(animated.button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border: none;
  padding: 0;
  pointer-events: ${props => props.active === 'true' ? 'auto' : 'none'};

  .circle {
    position: absolute;
    width: ${props => props.arrow === 'true' ? '6rem' : '2rem'};
    height: ${props => props.arrow === 'true' ? '6rem' : '2rem'};
    border-radius: 50%;
    border: ${props => props.arrow === 'true' ? '0.4rem solid #fff' : 'none'};
    background: ${props => props.arrow === 'true' ? 'rgba(255,255,255,0)' : '#fff'};
    filter: drop-shadow(0px 0px 0px white);
    display: flex;
    justify-content: center;
    align-items: center;

    //:hover{
    //  border: 0.4rem solid #c6215d;  
    //}

    img {
      width: 50%;
      height: 30%;
      position: absolute;
      top: 1.8rem;
    }
  }

  .effect {
    position: absolute;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background: rgba(165, 0, 52, 1);
    //background: radial-gradient(rgba(220, 2, 71, 0), rgba(220, 2, 71, 1));
  }
`;

export default ProductHaloHotspot;
