import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styled from "styled-components";
import IntroMenu from "./IntroMenu";
import IconPlaySVG from "./svg/IconPlaySVG";
import {animated, useSpring} from "react-spring";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../const";
import useHistoryStore from "../../stores/historyStore";
import IconSkipSVG from "./svg/IconSkipSVG";
import {GA_Event_Click} from "../../api/ga";
import {BG_COLOR} from "../../styles/GlobalStyle";


const Intro = () => {

    const contentAreaRef = useRef();
    const videoRef = useRef();
    const dimRef = useRef();

    const [isPlay, setIsPlay] = useState(false);
    const [hovered, setHover] = useState(false);
    const [hoveredSkip, setHoverSkip] = useState(false);

    const navigate = useHistoryStore(state => state.navigate);
    let timeout;

    useLayoutEffect(() => {
        tryPlay();
    }, []);


    const [spring, animate] = useSpring(() => ({
        opacity: 0
    }));

    function showUI() {
        animate({cancel: true});
        animate({cancel: false, opacity: 1});
    }

    function hideUI() {
        animate({cancel: true});
        animate({cancel: false, opacity: 0});
    }

    function tryPlay() {
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                //재생성공
                setIsPlay(true);
                if (!hovered || IS_MOBILE) hideUI();
            }).catch(err => {
                setIsPlay(false);
                showUI();
            })
        }
    }

    function mouseOverHandler() {
        if (timeout) clearTimeout(timeout);
        setHover(true);
        showUI();
    }

    function mouseOutHandler() {
        setHover(false);
        if (isPlay) hideUI();
    }

    return (
        <Wrapper>
            <div className="content-area" ref={contentAreaRef}>
                <div className="container">
                    <img className="icon" src={`${ROOT_PATH_PREFIX}/icons/icon_cube.svg`} alt=""/>
                    <h1><span className="txt-0">LG</span><span className="txt-1">VIRTUAL SHOWROOM</span></h1>
                    <h2>Discover great products from the comfort of your own home</h2>
                </div>
                <video
                    className="introVideo"
                    src={`${ROOT_PATH_PREFIX}/videos/LG_V8.mp4`}
                    crossOrigin="anonymous"
                    // autoPlay
                    playsInline
                    loop
                    // muted
                    // controls
                    ref={videoRef}
                />
                <animated.div
                    className="dim"
                    style={spring}
                    ref={dimRef}
                    onMouseOver={mouseOverHandler}
                    onMouseOut={mouseOutHandler}
                >
                    <button className="playBtn"
                            onClick={(e) => {
                                if (dimRef.current.style.opacity < 0.5) {
                                    return;
                                }

                                if (isPlay) {
                                    videoRef.current.pause();
                                    setIsPlay(false);
                                } else {
                                    tryPlay();
                                }
                            }}>
                        <IconPlaySVG className='playIcon' state={isPlay ? 'pause' : 'play'}/>
                    </button>
                </animated.div>

                <button id="intro-video-skip-button"
                        className="closeBtn"
                        onMouseOver={() => setHoverSkip(true)}
                        onMouseOut={() => setHoverSkip(false)}
                        onTouchStart={() => setHoverSkip(true)}
                        onTouchEnd={() => setHoverSkip(false)}
                        onClick={() => {
                            GA_Event_Click('click_intro_video_skip', '/');
                            navigate(`${ROOT_PATH_PREFIX}/?pathname=/family`);
                        }}
                >
                    <span style={{
                        color: hoveredSkip ? '#fff' : '#333'
                    }}>Skip</span>
                    <IconSkipSVG color={hoveredSkip ? '#fff' : '#333'}/>
                </button>
            </div>
            <IntroMenu/>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 2rem;
  background: ${BG_COLOR};
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .content-area {
    max-width: 1200px;
    min-height: 675px;
    width: 1200px;
    height: 675px;
    position: relative;
    background: white;
    border: 1px solid #d0d0d0;
    color: #282c34;
    filter: drop-shadow(0 0 0.75rem rgba(100, 100, 100, 0.4));
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .introVideo {
      width: 100%;
      height: 100%;
      position: absolute;
      background: ${BG_COLOR};
    }

    .dim {
      opacity: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      .playBtn {
        cursor: pointer;
        width: 30rem;
        height: 30rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0);
        border: none;
      }
    }

    .playIcon {
      position: absolute;
      z-index: 999999;
      height: 20%;
      max-height: 12rem;
      filter: drop-shadow(0 0 0.75rem rgba(100, 100, 100, 0.4));
    }

    .container {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3rem;
    }

    .icon {
      margin: 3rem;
      width: 11rem;
      height: 11rem;
    }

    h1 {
      font-size: 5rem;
      letter-spacing: 1rem;
      margin: 1rem;
      color: black;
      text-align: center;
      //white-space: nowrap;

      .txt-0 {
        font-weight: 700;
        margin-right: 1.5rem;
      }

      .txt-1 {
        font-weight: 600;
      }
    }

    h2 {
      font-size: 2rem;
      font-weight: normal;
      margin: 1rem;
      letter-spacing: 0.1rem;
      color: black;
    }
  }

  .closeBtn {
    background: #fff;
    border: none;
    height: 3rem;
    border-radius: 1.5rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 1.3rem;
    filter: drop-shadow(0 0 0.5rem rgba(100, 100, 100, 0.4));

    span {
      font-size: 1.5rem;
      font-weight: 700;
      color: #333;
      margin-bottom: 0.4rem;
    }

    :hover {
      background: #a50034;

      span {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .content-area {
      width: 100%;
      height: 56vw;
      min-height: 56vw;
    }
  }

  @media screen and (max-width: 747px), screen and (max-height: 414px) {
    padding: 1rem;

    .content-area {
      .icon {
        width: 6rem;
        height: 6rem;
        margin: 1rem;
      }

      h1 {
        font-size: 2.8rem;
        letter-spacing: 0.6rem;
      }

      h2 {
        font-size: 1.4rem;
        letter-spacing: 0;
      }
    }
  }
`;

export default Intro;
