import create from "zustand";
import sleep from "../utils/sleep";

let delayVal;

const usePanoStore = create((set, get) => ({
    scenes: null,
    prevScene: null,
    selectedScene: null,
    selectedSceneCategory: null,
    isDragging: true,
    isTweening: false,
    yaw: 0,
    viewer: null,
    sceneChanged: false,
    targetCameraView: null,

    setViewer: (viewer) => set({viewer: viewer}),
    setYaw: (yaw) => set({yaw: yaw}),
    setDragging: (dragging) => set({isDragging: dragging}),
    setScenes: (scenes) => {
        set({scenes: scenes});
        get().changeScene(scenes[0].data.id, scenes[0].data.category);
    },
    changeScene: async (targetId, sceneCategory, targetCameraView, useLook = true) => {

        // console.log("###", targetId, sceneCategory, targetCameraView);
        const prevSelectedScene = get().selectedScene;
        const viewer = get().viewer;
        if (viewer) viewer.breakIdleMovement();

        set({
            targetCameraView: targetCameraView,
            prevScene: prevSelectedScene,
            isTweening: true,
            sceneChanged: false
        });

        // 이동하려는 공간이 같은 공간이면
        if (prevSelectedScene && prevSelectedScene.data.id === targetId) {
            const {scene, data} = prevSelectedScene;

            if(delayVal) clearTimeout(delayVal);

            if(useLook){
                scene.lookTo((targetCameraView) ? targetCameraView : data.initialViewParameters, {
                    transitionDuration: 1500
                });
            }
        }
        // 공간이 완전히 바뀌는 경우이면
        else {
            const scenes = get().scenes;

            let idx = -1;
            for (let i = 0; i < scenes.length; i++) {
                const {scene, data} = scenes[i];
                if (data.id === targetId) {
                    scene.view().setParameters(data.initialViewParameters);
                    scene.switchTo({
                        transitionDuration: 600
                    });
                    idx = i;
                    // await sleep(1);
                    if (idx >= 0) set({selectedScene: scenes[idx], selectedSceneCategory: sceneCategory});

                    await sleep(600);

                    if(delayVal) clearTimeout(delayVal);
                    // set({isTweening: true});
                    if (targetCameraView) scene.lookTo(targetCameraView, {transitionDuration: 1500});
                    break;
                }
            }
            if (idx >= 0) set({sceneChanged: true});
        }

        if(get().isTweening){
            delayVal = setTimeout(()=>{
                set({isTweening: false});
            }, 1500);
        }
    }
}));

export default usePanoStore;
