const IDLE_DATA = {
    "id": "idle",
    "name": "idle",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        }
    ],
    "faceSize": 256,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.5707963267948966
    },
    "linkHotspots": [],
    "infoHotspots": []
};

export default IDLE_DATA;
