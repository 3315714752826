const HOME_WASH_DATA = {
    "id": "family_wash",
    "category":"family",
    "name": "family_wash",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0.06,
        "yaw": 0,
        "fov": 1.2007963267948966
    },
    "linkHotspots": [
        {
            "yaw": 0.5612376061942985,
            "pitch": 0.34151779449604547,
            "rotation": 0,
            "arrow":true,
            "target": "family_main",
            "pathname": "/family"
        },
        {
            "yaw": -1.1287093829755133,
            "pitch": 0.20036687273492952,
            "rotation": 0,
            "arrow":true,
            "target": "family_main",
            "pathname": "/family"
        },
        {
            "yaw": 0.33794662411541054,
            "pitch": 0.09956962799422442,
            "rotation": 0,
            "target": "family_wash",
            "category": "styler",
            "pathname": "/family/styler/styler/S3WF",
            "targetCameraView": {
                "yaw": 0.5002609029022338,
                "pitch": 0.08179578941256516,
                "fov": 1.18
            },
            "targetCameraView2": {
                "yaw": 0.25834445641554993,
                "pitch": 0.24460756510998166,
                "fov": 1.18
            }
        },
        {
            "yaw": -0.435608426988086,
            "pitch": 0.30324763288603584,
            "rotation": 0,
            "target": "family_wash",
            "category": "wm",
            "pathname": "/family/wm/turbowash360/F6V1010BTSE",
            "targetCameraView": {
                "yaw": -0.03368443785206132,
                "pitch": 0.19537867711309964,
                "fov": 1.18
            },
            "targetCameraView2": {
                "yaw": -0.3643937903471137,
                "pitch": 0.33765161859865458,
                "fov": 1.18
            }
        },
        {
            "yaw": -0.1547157025559187,
            "pitch": 0.33324763288603584,
            "rotation": 0,
            "target": "family_wash",
            "category": "washer_dryers",
            "pathname": "/family/washer_dryers/washer_dryer/FWV917BTSE",
            "targetCameraView": {
                "yaw": -0.03368443785206132,
                "pitch": 0.19537867711309964,
                "fov": 1.18
            },
            "targetCameraView2": {
                "yaw": -0.17530898060989486,
                "pitch": 0.46168011813179255,
                "fov": 1.18
            }
        }
    ],
    "infoHotspots": [
        {
            "yaw": -0.5606933243422491,
            "pitch": 0.49037237048018216,
            "category": "wm",
            "size": {w: 391, h: 530},
            "defaultModelName":"F6V1010BTSE",
            "images": [
                {url: "products/update_20210427/home_product/wash/wm/F6V1010BTSE.png", modelName: "F6V1010BTSE"},
            ],
            "radius": 1535,
            "extraTransforms": "rotateX(-28.12680798347611deg) rotateY(-32.18265686548374deg)"
        },
        {
            "yaw": -0.3059024212802033,
            "pitch": 0.5410689760352128,
            "category": "washer_dryers",
            "size": {w: 359, h: 534},
            "defaultModelName":"FWV917BTSE",
            "images": [
                {url: "products/update_20210427/home_product/wash/dry/FWV917BTSE.png", modelName: "FWV917BTSE", offsetY:1},
            ],
            "radius": 1400,
            "extraTransforms": "rotateX(-31.000968752282773deg) rotateY(-17.52691768218855deg)"
        },
        {
            "yaw": 0.2282415564546625,
            "pitch": 0.5501285434803531,
            "category": "styler",
            "size": {w: 391, h: 1115},
            "defaultModelName":"wf",
            "images": [
                {url: "products/home_product/wash/styler/s3wf.png", modelName: "wf"},
            ],
            "radius": 1385,
            "extraTransforms": "rotateX(-31.244156347818883deg) rotateY(13.220059808981917deg)"
        }
    ]
};

export default HOME_WASH_DATA;
