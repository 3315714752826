import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import MarzipanoView from "./components/v2/MarzipanoView";
import useProductsStore from "./stores/productsStore";
import ProductUI from "./components/v1/containers/ProductUI";
import MinimapView from "./components/v1/MinimapView";
import MenuUI from "./components/v2/nav/MenuUI";
import Intro from "./components/v2/Intro";
import WarningIE from "./components/v1/WarningIE";
import {useNavigate, useLocation} from "react-router-dom";
import qs from "qs";
import useHistoryStore from "./stores/historyStore";
import {MENU_DATA} from "./api/menu";
import usePanoStore from "./stores/panoStore";
import UspVideoView from "./components/v2/USPVideoView";
import EPromoters from "./components/v2/EPromoters";
import useMenuStore from "./stores/menuStore";
import useUspStore from "./stores/uspStore";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "./const";
import GlobalStyle from "./styles/GlobalStyle";


const App = () => {

    const location = useLocation();
    const query = qs.parse(location.search, {ignoreQueryPrefix: true});
    const {pathname, iframe} = query;
    const navigate = useNavigate();
    const ref = useRef();
    const ref2 = useRef();

    const selectedProduct = useProductsStore(state => state.selectedProduct);
    const sceneCategory = useHistoryStore(state => state.sceneCategory);
    const productGroupCategory = useHistoryStore(state => state.productGroupCategory);
    const productCategory = useHistoryStore(state => state.productCategory);
    const pushHistory = useHistoryStore(useCallback(state => state.pushHistory, []));

    const isIEBrowser = useMemo(() => {
        const agent = navigator.userAgent.toLowerCase();
        return (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || (agent.indexOf("msie") !== -1);
    }, []);

    const headerString = useMemo(() => {
        let result = [];
        const data = MENU_DATA.filter(value => value.category === sceneCategory);
        if (data.length > 0) {
            result.push(data[0].name);

            const data2 = data[0].subMenu.filter(value => value.category === productGroupCategory);
            if (data2.length > 0) {
                result.push(data2[0].name);
            }
        }
        return result.join(" - ").toUpperCase();
    }, [sceneCategory, productGroupCategory, productCategory]);

    const isIntro = useMemo(() => pathname === '' || pathname === undefined, [pathname]);




    useEffect(() => {
        useHistoryStore.setState({_navigate: navigate, query: query});
        setTimeout(() => {
            usePanoStore.setState({isDragging: false});
        }, 100);
    }, []);

    useEffect(() => {
        pushHistory(pathname ?? '/');
        useUspStore.getState().hide();
    }, [query]);

    function resizeHandler() {
        if (window.innerWidth <= 747 || window.innerHeight <= 414) {
            useMenuStore.setState({isMobile: true});
        } else {
            useMenuStore.setState({isMobile: false});
        }
    }

    function onMessageHandler(e) {
        if(e.data.event === 'init_model')
        {
            navigate(`?pathname=${e.data.pathname}`);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        dispatchEvent(new Event('resize'));

        window.addEventListener('message', onMessageHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
            window.removeEventListener('message', onMessageHandler);
        }
    }, []);

    useEffect(() => {
        if (!(pathname === '/' || pathname === undefined) && (iframe === 'true')) {
            const screenW = window.innerWidth;
            const screenH = window.innerHeight;
            if (screenW <= 747 || screenH <= 414) {
                ref.current.style.padding = '1rem';
            } else {
                ref.current.style.padding = '2rem';
            }

            ref2.current.style.border = '1px solid #d0d0d0';
        } else {
            ref.current.style.padding = '0';
            ref2.current.style.border = 'none';
        }

        if (iframe === 'true') {
        } else {
            dispatchEvent(new Event('resize'));
        }
    }, [pathname]);


    return (
        <>
            <GlobalStyle />
            <div id="root-container" ref={ref}>
                <div id="root-container-inner" ref={ref2}>
                    {isIEBrowser ?
                        <WarningIE/> :
                        <>
                            <MarzipanoView/>

                            {isIntro ?
                                (<main role="main">
                                    <Intro/>
                                </main>) :
                                (<>
                                    <header>
                                        <MenuUI/>
                                    </header>

                                    <main role="main">
                                        <section>
                                            <h1 className="hidden">{headerString}</h1>
                                            <MinimapView/>
                                            {selectedProduct && <ProductUI/>}
                                            <UspVideoView/>
                                        </section>
                                    </main>
                                </>)
                            }

                            <footer>
                                {!isIntro && !IS_MOBILE && (
                                    <>
                                        <div className='copyright'>
                                            Copyright © 2009-{new Date().getFullYear()} LG Electronics. All Rights
                                            Reserved
                                        </div>
                                        <EPromoters/>
                                    </>
                                )}
                            </footer>
                        </>
                    }
                </div>
            </div>
            <img src={`${ROOT_PATH_PREFIX}/img/loading.gif`} style={{visibility:'hidden'}} />
        </>
    );
};

export default App;
