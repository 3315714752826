import React from 'react';

const IconStudioSVG = ({color, showBorder = false, ...rest}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.99 107.98" {...rest}>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path className="cls-1" fill={color}
                          d="M83,83.14Q83,63.57,83,44a1.73,1.73,0,0,0-1.94-2H26.94a1.74,1.74,0,0,0-2,2V83.12a1.75,1.75,0,0,0,2,2H46.62V70.18c0-1.45.63-2.08,2.06-2.08h10.6c1.44,0,2.08.63,2.08,2.06V85.1h19.7A1.74,1.74,0,0,0,83,83.14ZM42.38,64.7h-9.8a1.75,1.75,0,0,1-1.94-1.92v-5a1.69,1.69,0,0,1,1.81-1.87q5,0,10,0a1.66,1.66,0,0,1,1.81,1.8c0,1.71,0,3.43,0,5.15A1.7,1.7,0,0,1,42.38,64.7Zm1.91-13.22a1.72,1.72,0,0,1-1.9,1.89h-9.8a1.75,1.75,0,0,1-2-1.92V47.32a1.74,1.74,0,0,1,1.92-1.89h9.8a1.72,1.72,0,0,1,1.93,1.92C44.31,48.72,44.31,50.1,44.29,51.48Zm5.31-6h8.78a1.71,1.71,0,0,1,1.89,1.84c0,1.42,0,2.83,0,4.25a1.7,1.7,0,0,1-1.88,1.85H49.61a1.71,1.71,0,0,1-1.9-1.84c0-1.42,0-2.83,0-4.25A1.71,1.71,0,0,1,49.6,45.43ZM60.27,62.72a1.74,1.74,0,0,1-2,2h-8.6a1.73,1.73,0,0,1-2-1.93c0-1.64,0-3.28,0-4.93a1.7,1.7,0,0,1,1.92-1.91c1.46,0,2.91,0,4.36,0s2.87,0,4.31,0a1.73,1.73,0,0,1,2,2C60.28,59.51,60.28,61.11,60.27,62.72Zm3.42-15.39a1.73,1.73,0,0,1,2-1.9h9.74a1.76,1.76,0,0,1,2,2v4.08a1.74,1.74,0,0,1-1.9,1.9h-9.8a1.73,1.73,0,0,1-2-1.9Q63.68,49.4,63.69,47.33ZM77.34,62.77a1.74,1.74,0,0,1-1.93,1.93h-9.8a1.71,1.71,0,0,1-1.92-1.87c0-1.68,0-3.36,0-5a1.68,1.68,0,0,1,1.86-1.86c1.67,0,3.33,0,5,0H75.3c1.41,0,2,.64,2,2Z"/>
                    <path className="cls-1" fill={color}
                          d="M78.35,38.6V36.19l.19,0H81.2A1.7,1.7,0,0,0,83,34.32c0-1.79,0-3.59,0-5.38a1.71,1.71,0,0,0-1.87-1.86H26.85A1.71,1.71,0,0,0,25,28.94c0,1.74,0,3.47,0,5.21a1.74,1.74,0,0,0,2,2h2.7V38.6Z"/>

                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M30.18,103.3c-5.77,0-11.54,0-17.32,0-5,0-8.16-3.16-8.17-8.16,0-5.77,0-11.55,0-17.32,0-1.62-.91-2.66-2.29-2.7A2.35,2.35,0,0,0,0,77.71C0,83.87,0,90,.06,96.18a12,12,0,0,0,11.72,11.75c3,.07,6,0,9,0v0c3.17,0,6.33,0,9.5,0a2.33,2.33,0,0,0,2.38-1.37C33.41,104.91,32.21,103.3,30.18,103.3Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M1.26,32.58c1.67.89,3.42-.31,3.42-2.38,0-5.78,0-11.55,0-17.32,0-5,3.15-8.19,8.14-8.19H30.28a2.39,2.39,0,0,0,2.6-2.4A2.35,2.35,0,0,0,30.29,0c-6.2,0-12.4-.08-18.6,0A12,12,0,0,0,.06,11.63c-.08,3,0,6.07,0,9.11H0c0,3.16,0,6.33,0,9.49A2.41,2.41,0,0,0,1.26,32.58Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M77.88,4.68H95.06c5.1,0,8.23,3.13,8.24,8.23,0,5.77,0,11.54,0,17.31a2.4,2.4,0,0,0,2.36,2.64,2.35,2.35,0,0,0,2.3-2.55c0-6.2.07-12.4,0-18.6A12,12,0,0,0,96.26.05c-3-.08-6,0-9,0V0C84,0,80.78,0,77.53,0A2.3,2.3,0,0,0,75.15,2.7C75.39,4,76.31,4.68,77.88,4.68Z"/>
                    <path className="cls-1" fill={color} opacity={showBorder ? 1 : 0}
                          d="M108,77.53a2.29,2.29,0,0,0-2.67-2.38c-1.29.23-2,1.16-2,2.72,0,5.73,0,11.46,0,17.19,0,5.08-3.15,8.23-8.23,8.23H77.75a2.39,2.39,0,0,0-2.64,2.35,2.34,2.34,0,0,0,2.55,2.3c6.2,0,12.4.07,18.6,0a12,12,0,0,0,11.67-11.66c.08-3,0-6,0-9h0C108,84,108,80.78,108,77.53Z"/>
                </g>
            </g>
        </svg>
    );
};

export default IconStudioSVG;
