import {DEFAULT_PATH} from "../../menu";

const STUDIO_FR_DATA = {
    "id": "studio_fr",
    "category": "studio",
    "name": "studio_fr",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.2
    },
    "linkHotspots": [
        {
            "yaw": 0.06427395526646862,
            "pitch": 0.09275167278983787,
            "rotation": 0,
            "target": "studio_fr",
            "category": "fridge",
            "pathname": "/studio/fridge/instaview/GMX945MC9F",
            "targetCameraView": {
                "yaw": 0.24285583787532872,
                "pitch": 0.05620696066291231,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.25620696066291231,
                "fov": 1
            }
        },
        {
            "yaw": -1.422839682434386,
            "pitch": 0.050721746331380047,
            "rotation": 0,
            "target": "studio_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.studio.oled,
            "targetCameraView": {
                "yaw": 0.3562050104448204,
                "pitch": 0.01538524537931089,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.21538524537931089,
                "fov": 1
            }
        },
        {
            "yaw": -2.9578627012723153,
            "pitch": 0.2640771204198131,
            "rotation": 0,
            "target": "studio_projector",
            "category": "it",
            "pathname": "/studio/it/beam_projector/PH30N",
            "targetCameraView": {
                "yaw": -0.0071422242109075995,
                "pitch": 0.1553467231693677,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.30276122450648373,
                "pitch": 0.07620233814263244,
                "fov": 1
            }
        },
        {
            "yaw": -1.9523892860428376,
            "pitch": 0.24465967194464433,
            "rotation": 0,
            "arrow":true,
            "target": "studio_toilet",
            "category": "wm",
            "pathname": "/studio/wm/turbowash360/F6V1010BTSE",
            "targetCameraView": {
                "yaw": 0.02704449235143791,
                "pitch": 0.06191195100668523,
                "fov": 1.45
            },
            "targetCameraView2": {
                "yaw": -0.5448200723351064,
                "pitch": 0.4816234939238875,
                "fov": 1.45
            }
        },
        {
            "yaw": -2.30059627454704,
            "pitch": -0.17225092062058955,
            "rotation": 0,
            "target": "studio_air",
            "category": "air",
            "pathname": "/studio/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": 0.07912567634454959,
                "pitch": -0.0027260613376149934,
                "fov": 1.2
            },
            "targetCameraView2": {
                "yaw": -0.21,
                "pitch": -0.11,
                "fov": 1
            },
        },
    ],
    "infoHotspots": [
        {
            "yaw": -0.14491216118885575,
            "pitch": 0.490029511856088,
            "category": "fridge",
            "size": {w: 502, h: 1203},
            "defaultModelName" : "GMX945MC9F",
            "images": [
                {url: "products/update_20210427/studio_product/freezer/insta/GMX945MC9F.png", modelName: "GMX945MC9F" },
            ],
            "radius": 1313,
            "extraTransforms": "rotateX(-27.584475474803508deg) rotateY(-8.302855236240925deg)"
        }
    ]
};
export default STUDIO_FR_DATA;
