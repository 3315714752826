import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {MENU_DATA} from "../../../../api/menu";
import useHistoryStore from "../../../../stores/historyStore";
import MenuButton_M from "./MenuButton_M";
import Title from "../Title";
import {Link} from "react-router-dom";
import {useSpring, animated} from "react-spring";
import useMenuStore from "../../../../stores/menuStore";
import {BG_COLOR, BG_COLOR_ALPHA} from "../../../../styles/GlobalStyle";


const Navigation_M = () => {

    const pathname = useHistoryStore(state => state.pathname);
    const isOpenMenu = useMenuStore(state => state.isOpenMenu);
    const sceneCategory = useHistoryStore(state => state.sceneCategory);
    const bgRef = useRef();

    const spring = useSpring({
        left: isOpenMenu ? 0 : 100,
        onStart: () => {
          if(isOpenMenu) {
              bgRef.current.style.display = 'block';
              useMenuStore.setState({isOpenFirst: true});
          }
        },
        onRest: () => {
            if(!isOpenMenu) bgRef.current.style.display = 'none';
            else useMenuStore.setState({isOpenFirst: false});
        }
    });

    useEffect(() => {
        useMenuStore.setState({isOpenMenu: false});
    }, []);

    const [newPath, setNewPath] = useState(``);
    useEffect(() => {
        setNewPath(useHistoryStore.getState().getNewPath(``));
    }, [pathname]);


    return (
        <NavigationStyle role="navigation">
            <div className="navbar">
                <Link to={newPath}><Title className="logo"/></Link>
                <button id="menuToggleBtn" onClick={(e) => {
                    e.stopPropagation();
                    if(!isOpenMenu){
                        useMenuStore.getState().setSceneCategory(sceneCategory);
                    }
                    useMenuStore.setState({isOpenMenu: !isOpenMenu});
                }}>
                    <div className="border" style={{borderColor : isOpenMenu ? '#a50034' : '#333'}}>
                        <div className="dot" style={{backgroundColor : isOpenMenu ? '#a50034' : '#333'}}/>
                        <div className="dot" style={{backgroundColor : isOpenMenu ? '#a50034' : '#333'}}/>
                        <div className="dot" style={{backgroundColor : isOpenMenu ? '#a50034' : '#333'}}/>
                    </div>
                </button>
            </div>

            <div className="bg" ref={bgRef}>
                <animated.div className="inner" style={{
                    left: spring.left.to(p => `${p}%`)
                }}>
                    <ul className="main-menu">
                        {MENU_DATA.map((value, index) => {
                            let selected = false;
                            if (pathname && pathname.indexOf(value.pathname) > -1) selected = true;

                            return (<MenuButton_M data={value} selected={selected} key={index}/>)
                        })}
                    </ul>
                </animated.div>
            </div>
        </NavigationStyle>
    );
};

const NavigationStyle = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .navbar {
    width: 100%;
    height: 6rem;
    left: 0;
    top: 0;
    display: inline-flex;
    justify-content: space-between;
    //align-items: center;
    background: ${BG_COLOR};
    pointer-events: visible;
    padding: 0 0 0 2rem;
    filter: drop-shadow(0 0 0.25rem rgba(100, 100, 100, 0.4));
  }

  .bg {
    min-width: 40rem;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    pointer-events: visible;
    position: relative;
    overflow: hidden;
    display: none;
    
    .inner{
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      background: ${BG_COLOR_ALPHA};
      //background: #61dafb;
      padding: 0 2rem;
      left: 0%;
    }
  }

  .main-menu {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
  }

  .logo {
    z-index: 99999999;
    height: 100%;
  }

  #menuToggleBtn {
    font-size: 5rem;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #cdcdcd;
    background: rgba(255, 255, 255, 0);
    width: 8.5rem;
    height: 100%;
    padding: 1.7rem 2rem;
    position: relative;
    cursor: pointer;

    .border {
      border: 2px solid #cdcdcd;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .dot {
        background: #cdcdcd;
        width: 4px;
        height: 4px;
        border-radius: 2px;
      }

      .dot + .dot {
        margin-left: 3px;
      }
    }
  }
`;

export default Navigation_M;
