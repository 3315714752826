import {DEFAULT_PATH} from "../../menu";

const STUDIO_TV_DATA = {
    "id": "studio_tv",
    "category": "studio",
    "name": "studio_tv",
    "levels": [
        {
            "tileSize": 256,
            "size": 256,
            "fallbackOnly": true
        },
        {
            "tileSize": 512,
            "size": 512
        },
        {
            "tileSize": 512,
            "size": 1024
        },
        {
            "tileSize": 512,
            "size": 2048
        }
    ],
    "faceSize": 1920,
    "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.2
    },
    "linkHotspots": [
        {
            "yaw": 0.008986280795770796,
            "pitch": -0.004735932739558635,
            "rotation": 0,
            "target": "studio_tv",
            "category": "tvs",
            "pathname": DEFAULT_PATH.tv.studio.oled,
            "targetCameraView": {
                "yaw": 0.1993592667127757,
                "pitch": 0.045656059969896745,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.21538524537931089,
                "fov": 1
            }
        },
        {
            "yaw": 0.008986280795770796,
            "pitch": 0.28058680564224403,
            "rotation": 0,
            "target": "studio_tv",
            "category": "av",
            "pathname": "/studio/av/soundbar/SP11RA",
            "targetCameraView": {
                "yaw": 0.1993592667127757,
                "pitch": 0.045656059969896745,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.21538524537931089,
                "fov": 1
            }
        },
        {
            "yaw": 2.219508525150273,
            "pitch": 0.07194196113777096,
            "rotation": 0,
            "target": "studio_fr",
            "category": "fridge",
            "pathname": "/studio/fridge/instaview/GMX945MC9F",
            "targetCameraView": {
                "yaw": 0.24285583787532872,
                "pitch": 0.05620696066291231,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": 0,
                "pitch": 0.25620696066291231,
                "fov": 1
            }
        },
        {
            "yaw": -1.7526434970247848,
            "pitch": 0.21940195118926908,
            "rotation": 0,
            "target": "studio_projector",
            "category": "it",
            "pathname": "/studio/it/beam_projector/PH30N",
            "targetCameraView": {
                "yaw": -0.0071422242109075995,
                "pitch": 0.1553467231693677,
                "fov": 1
            },
            "targetCameraView2": {
                "yaw": -0.30276122450648373,
                "pitch": 0.07620233814263244,
                "fov": 1
            }
        },
        {
            "yaw": -0.6264796415313612,
            "pitch": 0.2974052934319147,
            "rotation": 0,
            "arrow":true,
            "target": "studio_toilet",
            "category": "wm",
            "pathname": "/studio/wm/turbowash360/F6V1010BTSE",
            "targetCameraView": {
                "yaw": 0.02704449235143791,
                "pitch": 0.06191195100668523,
                "fov": 1.45
            },
            "targetCameraView2": {
                "yaw": -0.5448200723351064,
                "pitch": 0.4816234939238875,
                "fov": 1.45
            }
        },
        {
            "yaw": -0.9912992312686626,
            "pitch": -0.17952163357075257,
            "rotation": 0,
            "target": "studio_air",
            "category": "air",
            "pathname": "/studio/air/air_conditioner/DC12RH",
            "targetCameraView": {
                "yaw": 0.07912567634454959,
                "pitch": -0.0027260613376149934,
                "fov": 1.2
            },
            "targetCameraView2": {
                "yaw": -0.21,
                "pitch": -0.11,
                "fov": 1
            },
        },
    ],
    "infoHotspots2": [],
    "infoHotspots": [
        {
            "yaw": -0.423038070414524,
            "pitch": 0.25878928769661513,
            "category": "tvs",
            "size": {w: 1063, h: 658},
            "images": [
                {url: "products/update_20220531/studio_product/TV/QNED/86QNED816QA.png", modelName: "86QNED816QA"},
            ],
            "radius": 1313,
            "extraTransforms": "rotateX(-14.827533968212888deg) rotateY(-24.238296008110364deg)"
        },
        {
            "yaw": -0.5002992543685539,
            "pitch": 0.45606396951839423,
            "category": "av",
            "size": {w: 1462, h: 492},
            "defaultModelName":"SP11RA",
            "images": [
                {url: "products/update_20210427/studio_product/soundbar/sp11ra.png", modelName: "SP11RA"},
            ],
            "radius": 1463,
            "extraTransforms": "rotateX(-26.187836420900094deg) rotateY(-28.98162171208472deg)"
        }
    ],
    "defaultTV":{
        "modelName": "none",
        "width": 660,
        "height": 376,
        "offsetX": 198,
        "offsetY": 148,
        "url":"videos/studio_tv.mp4"
    }
};
export default STUDIO_TV_DATA;
