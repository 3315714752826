import React, {useEffect, useLayoutEffect, useState} from 'react';
import styled from "styled-components";
import useMenuStore from "../../../stores/menuStore";
import {MENU_DATA} from "../../../api/menu";
import MenuButton from "./MenuButton";
import {animated, useSpring} from "react-spring";
import useHistoryStore from "../../../stores/historyStore";
import {easeCubicInOut} from "d3-ease";
import Title from "./Title";
import {Link} from "react-router-dom";
import {BG_COLOR, BG_COLOR_ALPHA} from "../../../styles/GlobalStyle";

const Navigation = () => {

    const pathname = useHistoryStore(state => state.pathname);
    const hoveredMenuCategory = useMenuStore(state => state.hoveredMenuCategory);

    const spring = useSpring({
        height: hoveredMenuCategory ? '36rem' : '6rem',
        config: {duration: 400, easing: easeCubicInOut},
        onRest: () => {
            useMenuStore.setState({isTweening: false});
        }
    });

    const [newPath, setNewPath] = useState(``);
    useEffect(() => {
        useMenuStore.setState({hoveredMenuCategory: undefined});
        setNewPath(useHistoryStore.getState().getNewPath(``));
    }, [pathname]);

    useLayoutEffect(() => {
        const prevHoveredMenuCategory = useMenuStore.getState().prevHoveredMenuCategory;
        if ((!prevHoveredMenuCategory && hoveredMenuCategory) || (prevHoveredMenuCategory && !hoveredMenuCategory)) {
            useMenuStore.setState({isTweening: true});
        }
    }, [hoveredMenuCategory]);

    return (
        <NavigationStyle role="navigation">
            <Link to={newPath}><Title className="logo"/></Link>

            <animated.div
                className="bg"
                style={{
                    height: spring.height
                }}
                onMouseOut={(e) => {
                    e.stopPropagation();
                    useMenuStore.getState().setHover(undefined);
                }}
            >
                <ul className="main-menu">
                    {MENU_DATA.map((value, index) => {
                        let selected = false;
                        if (pathname && pathname.indexOf(value.pathname) > -1) selected = true;

                        return (<MenuButton data={value} selected={selected} key={index}/>)
                    })}
                </ul>
            </animated.div>
        </NavigationStyle>
    );
};

const NavigationStyle = styled.nav`
  width: 100%;
  height: 6rem;
  display: inline-flex;
  justify-content: space-between;
  padding: 0 8rem;
  background: ${BG_COLOR};
  pointer-events: visible;

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 6rem;
    background: ${BG_COLOR_ALPHA};
    display: inline-flex;
    justify-content: flex-end;
    padding-right: 8rem;
    overflow: hidden;
    //background: red;
  }

  .main-menu {
    display: inline-flex;
    height: 6rem;
    margin: 0;
  }

  .logo {
    z-index: 99999999;
  }
`;

export default Navigation;
